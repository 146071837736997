import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import { createEtc3001CynageTicker, updateEtc3001CynageTicker, deleteEtc3001CynageTicker } from "../graphql/mutations";
import { createEtc3001CynageSequence, updateEtc3001CynageSequence, deleteEtc3001CynageSequence } from "../graphql/mutations";
import { updateExEtc3001CynageSequence } from "../graphql/mutations";
import { getEtc3001CynageSequence, listEtc3001CynageSequences } from "../graphql/queries";

import * as common from "./common";
import * as database from "./database";

import TopNav from "./TopNav";

import {
	Alert,
	Breadcrumb,
	Button,
	Container
} from "react-bootstrap";


// Component ===================================================================

function TickerPageEditConfirm ()
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "TickerPageEditConfirm > TickerPageEditConfirm () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "TickerPageEditConfirm > TickerPageEditConfirm () location : " + JSON.stringify( location ) );
	//console.log( "TickerPageEditConfirm > TickerPageEditConfirm () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ editTickerPageDisabled, setEditTickerPageDisabled ] = useState( false );

	// 日時(Date型)データの取得
	// 本日
	const today = new Date( common.getJSTDateTime( null, "YYYY-MM-DD" ) );
	// 昨日
	const yesterday = common.getShiftDateTime( new Date( common.getJSTDateTime( null, "YYYY-MM-DD" ) ), -1 );
	// 表示開始日
	const displayStartDate = new Date( location.state.displayStartDate );
	// (編集前)表示終了日
	const originalEndDate = new Date( location.state.originalEndDate );

	//console.log( "TickerPageEditConfirm > TickerPageEditConfirm () today : " + JSON.stringify( today ) + " : " + JSON.stringify( today.getTime() ) );
	//console.log( "TickerPageEditConfirm > TickerPageEditConfirm () yesterday : " + JSON.stringify( yesterday ) + " : " + JSON.stringify( yesterday.getTime() ) );
	//console.log( "TickerPageEditConfirm > TickerPageEditConfirm () displayStartDate : " + JSON.stringify( displayStartDate ) + " : " + JSON.stringify( displayStartDate.getTime() ) );
	//console.log( "TickerPageEditConfirm > TickerPageEditConfirm () originalEndDate : " + JSON.stringify( originalEndDate ) + " : " + JSON.stringify( originalEndDate.getTime() ) );

	// タスク締切日
	const limitDate = common.getReplaceDate( location.state.limitDate, "-", "YYYY/MM/DD" );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [トピックスページ(編集確認)]";
	}, [] );

	// 編集ページへ戻る処理
	const goBackPrevious = function ( event )
	{
		// クエリパラメータ作成
		const param = {
			pathname: "/component/TickerPageEdit/" + location.state.id,
			//search: "",
			//hash: "",
			state: {
				id: location.state.id,
				number: location.state.number,
				page: location.state.page,
				name: location.state.name,
				mainContents: location.state.mainContents,
				subContents: location.state.subContents,
				domainID: location.state.domainID,
				displayStartDate: location.state.displayStartDate,
				displayEndDate: location.state.displayEndDate,
				limitDate: location.state.limitDate,
				originalStartDate: location.state.originalStartDate,
				originalEndDate: location.state.originalEndDate
			}
		};
		// 遷移
		history.push( param );
	};

	// 更新して一覧へ戻る処理
	const goToList = async function ( event )
	{
		// データを保存中でない場合
		if ( !editTickerPageDisabled )
		{
			// 保存ボタンの無効化
			setEditTickerPageDisabled( true );

			// 『(終了日翌々日) ※ 更新ではなく複製扱い』条件判定
			// 表示開始日が本日以降の場合
			if ( ( today.getTime() <= displayStartDate.getTime() )
				// (編集前)表示終了日が格納されていた場合
				&& ( location.state.originalEndDate )
				// (編集前)表示終了日が昨日よりも過去だった場合
				&& ( originalEndDate.getTime() < yesterday.getTime() ) )
			{
				// シーケンスデータ数値取得(非同期)
				const count = await common.getSequenceValue(
					getEtc3001CynageSequence,
					createEtc3001CynageSequence,
					updateExEtc3001CynageSequence,
					location.state.domainID,
					"Ticker",
					"number",
					1
				);

				//console.log( "TickerPageEditConfirm > goToList () count : " + JSON.stringify( count ) );

				// シーケンスデータ数値の取得に成功した場合
				if ( 0 <= count )
				{
					// ティッカーデータ生成
					const input = {
						number: count,
						name: location.state.name,
						mainContents: location.state.mainContents,
						subContents: location.state.subContents,
						domainID: location.state.domainID,
						displayStartDate: location.state.displayStartDate,
						displayEndDate: location.state.displayEndDate,
						limitDate: location.state.limitDate || undefined
					};
					const response = await database.mutationCreate( createEtc3001CynageTicker, input );

					//console.log( "TickerPageEditConfirm > goToList () response : " + JSON.stringify( response ) );

					// ティッカーデータの生成に成功した場合
					if ( response )
					{
						// 遷移
						history.push( "/component/TickerPageList/" + response.id );
					}
					// ティッカーデータの生成に失敗した場合
					else
					{
						console.error( "TickerPageEditConfirm > goToList () ticker data create error." );
					}
				}
				// シーケンスデータ数値の取得に失敗した場合
				else
				{
					console.error( "TickerPageEditConfirm > goToList () sequence value (Ticker.number) get error." );
				}
			}
			// その他の場合
			else
			{
				// ティッカーデータ更新
				const input = {
					id: location.state.id,
					name: location.state.name,
					mainContents: location.state.mainContents,
					subContents: location.state.subContents,
					domainID: location.state.domainID,
					displayStartDate: location.state.displayStartDate,
					displayEndDate: location.state.displayEndDate,
					limitDate: location.state.limitDate || undefined
				};
				const response = await database.mutationUpdate( updateEtc3001CynageTicker, input );

				//console.log( "TickerPageEditConfirm > goToList () response : " + JSON.stringify( response ) );

				// ティッカーデータの更新に成功した場合
				if ( response )
				{
					// 遷移
					history.push( "/component/TickerPageList/" + location.state.id );
				}
				// ティッカーデータの更新に失敗した場合
				else
				{
					console.error( "TickerPageEditConfirm > goToList () ticker page update error." );
				}
			}
		}
	};

	// ティッカーページ更新処理
	// ※ 非同期の重い処理が含まれているので、<form onSubmit={ updateTickerPage }>では処理不可(ページ遷移が先行しエラーとなる)
	const updateTickerPage = async function ( event )
	{
		// データを保存中でない場合
		if ( !editTickerPageDisabled )
		{
			// 保存ボタンの無効化
			setEditTickerPageDisabled( true );

			// 『(終了日翌々日) ※ 更新ではなく複製扱い』条件判定
			// 表示開始日が本日以降の場合
			if ( ( today.getTime() <= displayStartDate.getTime() )
				// (編集前)表示終了日が格納されていた場合
				&& ( location.state.originalEndDate )
				// (編集前)表示終了日が昨日よりも過去だった場合
				&& ( originalEndDate.getTime() < yesterday.getTime() ) )
			{
				// シーケンスデータ数値取得(非同期)
				const count = await common.getSequenceValue(
					getEtc3001CynageSequence,
					createEtc3001CynageSequence,
					updateExEtc3001CynageSequence,
					location.state.domainID,
					"Ticker",
					"number",
					1
				);

				//console.log( "TickerPageEditConfirm > updateTickerPage () count : " + JSON.stringify( count ) );

				// シーケンスデータ数値の取得に成功した場合
				if ( 0 <= count )
				{
					// ティッカーデータ生成
					const input = {
						number: count,
						name: location.state.name,
						mainContents: location.state.mainContents,
						subContents: location.state.subContents,
						domainID: location.state.domainID,
						displayStartDate: location.state.displayStartDate,
						displayEndDate: location.state.displayEndDate,
						limitDate: location.state.limitDate || undefined
					};
					const response = await database.mutationCreate( createEtc3001CynageTicker, input );

					//console.log( "TickerPageEditConfirm > updateTickerPage () response : " + JSON.stringify( response ) );

					// ティッカーデータの生成に成功した場合
					if ( response )
					{
						// クエリパラメータ作成
						const param = {
							pathname: "/component/TickerPageEditComplete/" + response.id,
							//search: "",
							//hash: "",
							state: {
								page: location.state.page,
								number: count
							}
						};
						// 遷移
						history.push( param );
					}
					// ティッカーデータの生成に失敗した場合
					else
					{
						console.error( "TickerPageEditConfirm > updateTickerPage () ticker data create error." );
					}
				}
				// シーケンスデータ数値の取得に失敗した場合
				else
				{
					console.error( "TickerPageEditConfirm > updateTickerPage () sequence value (Ticker.number) get error." );
				}
			}
			// その他の場合
			else
			{
				// ティッカーデータ更新
				const input = {
					id: location.state.id,
					name: location.state.name,
					mainContents: location.state.mainContents,
					subContents: location.state.subContents,
					domainID: location.state.domainID,
					displayStartDate: location.state.displayStartDate,
					displayEndDate: location.state.displayEndDate,
					limitDate: location.state.limitDate || undefined
				};
				const response = await database.mutationUpdate( updateEtc3001CynageTicker, input );

				//console.log( "TickerPageEditConfirm > updateTickerPage () response : " + JSON.stringify( response ) );

				// ティッカーデータの更新に成功した場合
				if ( response )
				{
					// クエリパラメータ作成
					const param = {
						pathname: "/component/TickerPageEditComplete/" + location.state.id,
						//search: "",
						//hash: "",
						state: {
							page: location.state.page,
							number: location.state.number
						}
					};
					// 遷移
					history.push( param );
				}
				// ティッカーデータの更新に失敗した場合
				else
				{
					console.error( "TickerPageEditConfirm > updateTickerPage () ticker page update error." );
				}
			}
		}
	};

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/TickerList" ) }>トピックス</Breadcrumb.Item>
					<Breadcrumb.Item onClick={ () => history.push( "/component/TickerPageList/" + location.state.id ) }>トピックスページ</Breadcrumb.Item>
					<Breadcrumb.Item active>編集-{ ( location.state.page + 1 ) } (確認)</Breadcrumb.Item>
				</Breadcrumb>

				<TopNav />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<h4 className="mb-4">
					トピックスページ編集 (確認)
				</h4>

				{/* プログレストラッカー */}
				<Alert className="progress_tracker mb-4">
					<div key={ location.state.page }>No. { location.state.number }-{ ( location.state.page + 1 ) }</div>
					<div className="active">確認</div>
				</Alert>

				<div>
					(1) トピックスの名前 (全角36文字以内)<br className="wide_br" />
					<p className="ms-3">{ location.state.name }</p>
				</div>

				<div>
					(2) トピックスの内容 (全角100文字以内)<br className="wide_br" />
					<p className="ms-3">{ location.state.mainContents[ location.state.page ] }</p>
				</div>

				<div>
					(3) リンク先URL<br className="wide_br" />
					<p className="ms-3">{ location.state.subContents[ location.state.page ] || "無し" }</p>
				</div>

				<div>
					(4) トピックスの表示開始日と表示終了日、およびタスク締切日<br className="wide_br" />
					<p className="ms-3">
						表示開始日 : { common.getReplaceDate( location.state.displayStartDate, "-", "YYYY/MM/DD" ) }<br />
						表示終了日 : { common.getReplaceDate( location.state.displayEndDate, "-", "YYYY/MM/DD" ) }<br />
						タスク締切日 : { limitDate || "無し" }
					</p>
					<p className="ms-3" style={{ color: "#f00" }}>{ ( limitDate ) ? "※ このトピックスはタスク化されます。" : "" }</p>
				</div>

				<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
					<Button variant="secondary" onClick={ goBackPrevious }>修正</Button>
					<Button onClick={ goToList } disabled={ editTickerPageDisabled }>保存して一覧へ</Button>
					<Button onClick={ updateTickerPage } disabled={ editTickerPageDisabled }>保存</Button>
				</div>

			</Container>

		</>
	);
}

export default TickerPageEditConfirm;
