import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import { createEtc3001CynageInvite, updateEtc3001CynageInvite, deleteEtc3001CynageInvite } from "../graphql/mutations";

import * as common from "./common";
import * as database from "./database";

import TopNav from "./TopNav";

import {
	Alert,
	Breadcrumb,
	Button,
	Container,
	Modal
} from "react-bootstrap";


// Component ===================================================================

function MemberInviteConfirm ( props )
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "MemberInviteConfirm > MemberInviteConfirm () props : " + JSON.stringify( props ) );
	//console.log( "MemberInviteConfirm > MemberInviteConfirm () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "MemberInviteConfirm > MemberInviteConfirm () location : " + JSON.stringify( location ) );
	//console.log( "MemberInviteConfirm > MemberInviteConfirm () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ showInfoModal, setShowInfoModal ] = useState( false );
	const [ inviteMemberDisabled, setInviteMemberDisabled ] = useState( false );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [メンバー招待(確認)]";
	}, [] );

	// 入力ページへ戻る処理
	const goBackPrevious = function ( event )
	{
		// クエリパラメータ作成
		const param = {
			pathname: "/component/MemberInvite",
			//search: "",
			//hash: "",
			state: {
				emailList: location.state.emailList
			}
		};
		// 遷移
		history.push( param );
	};

	// メンバー招待処理
	// ※ 非同期の重い処理が含まれているので、<form onSubmit={ inviteMember }>では処理不可(ページ遷移が先行しエラーとなる)
	const inviteMember = async function ( event )
	{
		// データを更新中でない場合
		if ( !inviteMemberDisabled )
		{
			// 招待ボタンの無効化
			setInviteMemberDisabled( true );

			// 招待メールアドレスリスト解析
			const list = common.parseEmailList( location.state.emailList );

			// 現在日時の取得
			const now = common.getUTCDateTime();

			//console.log( "MemberInviteConfirm > inviteMember () now : " + JSON.stringify( now ) );

			// 招待メールアドレスリストを基に招待キューデータを追加
			for ( const value of list )
			{
				//console.log( "MemberInviteConfirm > inviteMember () value : " + JSON.stringify( value ) );

				// 招待キューデータ生成
				// 「承認」状態の招待キューデータを生成
				// 認証状態:
				// 	承認:
				// 		isApproved = true;
				// 		isProcessed = false;
				// 	否認:
				// 		isApproved = false;
				// 		isProcessed = true;
				// 	保留:
				// 		isApproved = false;
				// 		isProcessed = false;
				const input = {
					invitedDate: now,
					domainID: props.userData.domain.id,
					mailAddress: value.mailAddress,
					permission: value.permission,
					isApproved: true,
					isProcessed: false,
					inviter: props.userData.user.attributes.sub
				};
				const response = await database.mutationCreate( createEtc3001CynageInvite, input );

				//console.log( "MemberInviteConfirm > inviteMember () response : " + JSON.stringify( response ) );

				// 招待キューデータの生成に成功した場合
				if ( response )
				{
					;
				}
				// 招待キューデータの生成に失敗した場合
				else
				{
					console.error( "MemberInviteConfirm > inviteMember () member invite error. mailAddress : " + value.mailAddress );

					// 関数から抜け、以降の処理を避ける
					return;
				}
			}

			// メンバー異動履歴(招待)追加
			// ※ Cognito のユーザー追加、および、MemberTransferHistory(招待)データの追加(要sub)は、AWS-SDK(要AWSアカウント情報)が必要となってしまうので、バッチ側にお任せ

			// 遷移
			history.push( "/component/MemberInviteComplete" );
		}
	};

	// 情報モーダルを閉じるイベントハンドラ
	const handleCloseInfoModal = () => setShowInfoModal( false );

	// 情報モーダルを表示するイベントハンドラ
	const handleShowInfoModal = () => setShowInfoModal( true );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/MemberList" ) }>メンバー</Breadcrumb.Item>
					<Breadcrumb.Item active>招待 (確認)</Breadcrumb.Item>
				</Breadcrumb>

				{/* インフォメーションボタン */}
				<button onClick={ handleShowInfoModal } className="no_border_button info_link text-primary">
					<span className="material-icons-outlined info_icon">info</span>
				</button>

				{/* インフォメーションダイアログ */}
				<Modal show={ showInfoModal } onHide={ handleCloseInfoModal }>
					<Modal.Header className="text-white bg-primary">
						<Modal.Title>
							<span className="material-icons-outlined modal_info_icon">info</span>
							TIPS
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span>
							このページでは、メンバーの招待ができます。
						</span>

						<div className="modal_action_btn_group">
							<Button variant="secondary" onClick={ handleCloseInfoModal }>閉じる</Button>
						</div>
					</Modal.Body>
				</Modal>

				<TopNav />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<h4 className="mb-4">
					招待送信先 (確認)
				</h4>

				{/* プログレストラッカー */}
				<Alert className="progress_tracker mb-4">
					<div>招待(入力)</div>
					<div className="active">確認</div>
				</Alert>

				<p>
					招待したいメンバーのメールアドレスと権限を確認してください。(1件1行)<br />
					※管理者: <span className="text_highlight">A</span>　編集者: <span className="text_highlight">D</span>　管理ツール利用不可: 指定なし<br />
				</p>

				{/* 招待リスト */}
				<div className="ms-3">
					{ common.stringToJSX( location.state.emailList ) }
				</div>

				<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
					<Button variant="secondary" onClick={ goBackPrevious }>修正</Button>
					<Button onClick={ inviteMember } disabled={ inviteMemberDisabled }>招待</Button>
				</div>

			</Container>

		</>
	);
}

export default MemberInviteConfirm;
