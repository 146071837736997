import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import * as common from "./common";

import TopNav from "./TopNav";

import {
	Alert,
	Breadcrumb,
	Button,
	Col,
	Container,
	Form,
	Modal,
	Row
} from "react-bootstrap";


// Component ===================================================================

function MemberEdit ( props )
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "MemberEdit > MemberEdit () props : " + JSON.stringify( props ) );
	//console.log( "MemberEdit > MemberEdit () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "MemberEdit > MemberEdit () location : " + JSON.stringify( location ) );
	//console.log( "MemberEdit > MemberEdit () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const inputTextFamilyName = useRef();
	const inputTextFirstName = useRef();
	const inputTextDepartment = useRef();
	const inputTextJobTitle = useRef();
	const inputTextPosition = useRef();
	const inputSelectPermission = useRef();
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ showInfoModal, setShowInfoModal ] = useState( false );
	const [ validated, setValidated ] = useState( false );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [メンバープロフィール編集]";
	}, [] );

	// メンバー一覧へ戻る処理
	const goBackPrevious = function ( event )
	{
		// 遷移
		history.push( "/component/MemberList" );
	};

	// 送信処理
	const handleForm = function ( event )
	{
		// ページ遷移(リロード)動作をキャンセル
		event.preventDefault();

		// バリデーションチェック
		setValidated( true );

		// バリデーションを通過時の処理
		if ( event.currentTarget.checkValidity() )
		{
			// クエリパラメータ作成
			const param = {
				pathname: "/component/MemberEditConfirm",
				//search: "",
				//hash: "",
				state: {
					id: location.state.id,
					domainID: location.state.domainID,
					mailAddress: location.state.mailAddress,
					familyName: inputTextFamilyName.current.value,
					firstName: inputTextFirstName.current.value,
					department: inputTextDepartment.current.value,
					jobTitle: inputTextJobTitle.current.value,
					position: inputTextPosition.current.value,
					permission: inputSelectPermission.current.value
				}
			};
			// 遷移
			history.push( param );
		}
	};

	// 情報モーダルを閉じるイベントハンドラ
	const handleCloseInfoModal = () => setShowInfoModal( false );

	// 情報モーダルを表示するイベントハンドラ
	const handleShowInfoModal = () => setShowInfoModal( true );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/MemberList" ) }>メンバー</Breadcrumb.Item>
					<Breadcrumb.Item active>プロフィール編集</Breadcrumb.Item>
				</Breadcrumb>

				{/* インフォメーションボタン */}
				<button onClick={ handleShowInfoModal } className="no_border_button info_link text-primary">
					<span className="material-icons-outlined info_icon">info</span>
				</button>

				{/* インフォメーションダイアログ */}
				<Modal show={ showInfoModal } onHide={ handleCloseInfoModal }>
					<Modal.Header className="text-white bg-primary">
						<Modal.Title>
							<span className="material-icons-outlined modal_info_icon">info</span>
							TIPS
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span>
							このページでは、メンバーのプロフィール編集ができます。
						</span>

						<div className="modal_action_btn_group">
							<Button variant="secondary" onClick={ handleCloseInfoModal }>閉じる</Button>
						</div>
					</Modal.Body>
				</Modal>

				<TopNav userData={ props.userData } />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container pl-3">

				<h4 className="mb-4">
					プロフィール編集
				</h4>

				<Form noValidate validated={ validated } onSubmit={ handleForm }>

					{/* プログレストラッカー */}
					<Alert className="progress_tracker mb-4">
						<div className="active">編集(入力)</div>
					</Alert>

					<Form.Group className="mb-3" controlId="plaintextMailAddress">
						<Form.Label>メールアドレス</Form.Label>
						<Form.Control
							plaintext
							defaultValue={ location.state.mailAddress }
							readOnly
						/>
					</Form.Group>

					<Row>
						<Col>
							<Form.Group className="mb-3" controlId="validationFamilyName">
								<Form.Label>姓 (全角20文字以内)</Form.Label>
								<Form.Control type="text" ref={ inputTextFamilyName } maxLength="20" placeholder="familyName" defaultValue={ location.state.familyName } />
								<Form.Control.Feedback type="invalid">
									姓を入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group className="mb-3" controlId="validationFirstName">
								<Form.Label>名 (全角20文字以内)</Form.Label>
								<Form.Control type="text" ref={ inputTextFirstName } maxLength="20" placeholder="firstName" defaultValue={ location.state.firstName } />
								<Form.Control.Feedback type="invalid">
									名を入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>

					<Form.Group className="mb-3" controlId="validationDepartment">
						<Form.Label>所属部門 (全角100文字以内)</Form.Label>
						<Form.Control type="text" ref={ inputTextDepartment } maxLength="100" placeholder="department" defaultValue={ location.state.department } />
						<Form.Control.Feedback type="invalid">
							所属部門を入力してください。
						</Form.Control.Feedback>
					</Form.Group>

					<Row>
						<Col>
							<Form.Group className="mb-3" controlId="validationJobTitle">
								<Form.Label>職種 (全角20文字以内)</Form.Label>
								<Form.Control type="text" ref={ inputTextJobTitle } maxLength="20" placeholder="jobTitle" defaultValue={ location.state.jobTitle } />
								<Form.Control.Feedback type="invalid">
									職種を入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group className="mb-3" controlId="validationPosition">
								<Form.Label>役職 (全角20文字以内)</Form.Label>
								<Form.Control type="text" ref={ inputTextPosition } maxLength="20" placeholder="position" defaultValue={ location.state.position } />
								<Form.Control.Feedback type="invalid">
									役職を入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>

					<Form.Group className="mb-3" controlId="validationPermission">
						<Form.Label>利用権限</Form.Label>
						<Form.Select ref={ inputSelectPermission } defaultValue={ location.state.permission } disabled={ ( props.userData.user.attributes.sub === location.state.id ) }>
							<option value="N">一般</option>
							<option value="D">編集者</option>
							<option value="A">管理者</option>
						</Form.Select>
					</Form.Group>

					<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
						{/* 戻るボタン */}
						<Button variant="secondary" onClick={ goBackPrevious }>戻る</Button>
						{/* 確認ボタン */}
						<Button type="submit">確認</Button>
					</div>

				</Form>

			</Container>

		</>
	);
}

export default MemberEdit;
