import {
	Container
} from "react-bootstrap";

import * as common from "./common";


// Style Sheet =================================================================

import "./css/Maintenance.css";


// Component ===================================================================

function Maintenance ( props )
{
	//console.log( "Maintenance > Maintenance () props : " + JSON.stringify( props ) );

	let message = "現在メンテナンス中です。<br />しばらく時間を置いてアクセスしてください。";
	// メッセージ(日本語)が指定されている場合
	if ( props.message && props.message.ja )
	{
		message = props.message.ja;
	}

	// 文字列の JSX 出力形式変換
	message = common.stringToJSX( message );

	return (
		<>

			{/* メイン部 */}
			<Container fluid className="maintenance_container d-flex justify-content-center align-items-center">

				<div className="text-center">

					<div className="maintenance_logo pb-5">
						<figure
							className="maintenance_logo_figure"
							style={{ backgroundImage: "url(/logo_cynage.png)" }}
						></figure>
					</div>

					<p className="mt-5">
						{ message }
					</p>

				</div>

			</Container>

		</>
	);
}

export default Maintenance;
