import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import { createEtc3001CynageLinkList, updateEtc3001CynageLinkList, deleteEtc3001CynageLinkList } from "../graphql/mutations";

import * as common from "./common";
import * as database from "./database";

import TopNav from "./TopNav";

import {
	Breadcrumb,
	Button,
	Container
} from "react-bootstrap";


// Component ===================================================================

function LinkListEditConfirm ( props )
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "LinkListEditConfirm > LinkListEditConfirm () props : " + JSON.stringify( props ) );
	//console.log( "LinkListEditConfirm > LinkListEditConfirm () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "LinkListEditConfirm > LinkListEditConfirm () location : " + JSON.stringify( location ) );
	//console.log( "LinkListEditConfirm > LinkListEditConfirm () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ editLinkListDisabled, setEditLinkListDisabled ] = useState( false );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [リンクリスト(編集確認)]";
	}, [] );

	// 編集ページへ戻る処理
	const goBackPrevious = function ( event )
	{
		// クエリパラメータ作成
		const param = {
			pathname: "/component/LinkListEdit/" + location.state.id,
			//search: "",
			//hash: "",
			state: {
				id: location.state.id,
				order: location.state.order,
				name: location.state.name,
				link: location.state.link,
				icon: location.state.icon
			}
		};
		// 遷移
		history.push( param );
	};

	// リンクリスト更新処理
	// ※ 非同期の重い処理が含まれているので、<form onSubmit={ editLinkList }>では処理不可(ページ遷移が先行しエラーとなる)
	const editLinkList = async function ( event )
	{
		// データを保存中でない場合
		if ( !editLinkListDisabled )
		{
			// 保存ボタンの無効化
			setEditLinkListDisabled( true );

			// リンクリストデータ更新
			const input = {
				id: location.state.id,
				order: location.state.order,
				name: location.state.name,
				link: location.state.link,
				icon: location.state.icon || null,
				domainID: props.userData.domain.id
			};
			const response = await database.mutationUpdate( updateEtc3001CynageLinkList, input );

			//console.log( "LinkListEditConfirm > editLinkList () response : " + JSON.stringify( response ) );

			// リンクリストデータの更新に成功した場合
			if ( response )
			{
				// 遷移
				history.push( "/component/LinkListEditComplete" );
			}
			// リンクリストデータの更新に失敗した場合
			else
			{
				console.error( "LinkListEditConfirm > editLinkList () link list edit error." );
			}
		}
	};

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/LinkList" ) }>リンクリスト</Breadcrumb.Item>
					<Breadcrumb.Item active>編集 (確認)</Breadcrumb.Item>
				</Breadcrumb>

				<TopNav />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<h4 className="mb-4">
					リンク編集 (確認)
				</h4>

				<div>
					(1) リンクの名前 (全角36文字以内)<br className="wide_br" />
					<p className="ms-3">{ location.state.name }</p>
				</div>

				<div>
					(2) リンク先URL<br className="wide_br" />
					<p className="ms-3"><a href={ location.state.link } target="_blank">{ location.state.link }</a></p>
				</div>

				<div>
					(3) アイコン画像URL<br className="wide_br" />
					<p className="ms-3">
					{
						( location.state.icon ) ?
						<>
							<a href={ location.state.icon } target="_blank">
								<img src={ location.state.icon } alt="" width="64" height="64" /><br />
								{ location.state.icon }
							</a>
						</> :
						"無し"
					}
					</p>
				</div>

				<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
					<Button variant="secondary" onClick={ goBackPrevious }>修正</Button>
					<Button onClick={ editLinkList } disabled={ editLinkListDisabled }>保存</Button>
				</div>

			</Container>

		</>
	);
}

export default LinkListEditConfirm;
