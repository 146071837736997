import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import * as common from "./common";

import TopNav from "./TopNav";

import {
	Breadcrumb,
	Button,
	Container,
	Form,
	Modal
} from "react-bootstrap";


// Component ===================================================================

function LinkListEdit ()
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "LinkListEdit > LinkListEdit () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "LinkListEdit > LinkListEdit () location : " + JSON.stringify( location ) );
	//console.log( "LinkListEdit > LinkListEdit () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const inputTextName = useRef();
	const inputTextLink = useRef();
	const inputTextIcon = useRef();
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ showInfoModal, setShowInfoModal ] = useState( false );
	const [ validated, setValidated ] = useState( false );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [リンクリスト(編集)]";
	}, [] );

	// リンクリスト一覧へ戻る処理
	const goBackPrevious = function ( event )
	{
		// 遷移
		history.push( "/component/LinkList/" );
	};

	// 送信処理
	const handleForm = function ( event )
	{
		// バリデーションチェック
		const form = event.currentTarget;
		setValidated( true );

		// ページ遷移(リロード)動作をキャンセル
		event.preventDefault();

		// バリデーションを通過時の処理
		if ( form.checkValidity() )
		{
			// クエリパラメータ作成
			const param = {
				pathname: "/component/LinkListEditConfirm",
				//search: "",
				//hash: "",
				state: {
					id: location.state.id,
					order: location.state.order,
					name: inputTextName.current.value,
					link: inputTextLink.current.value,
					icon: inputTextIcon.current.value
				}
			};
			// 遷移
			history.push( param );
		}
	};

	// 情報モーダルを閉じるイベントハンドラ
	const handleCloseInfoModal = () => setShowInfoModal( false );

	// 情報モーダルを表示するイベントハンドラ
	const handleShowInfoModal = () => setShowInfoModal( true );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/LinkList" ) }>リンクリスト</Breadcrumb.Item>
					<Breadcrumb.Item active>編集</Breadcrumb.Item>
				</Breadcrumb>

				{/* インフォメーションボタン */}
				<button onClick={ handleShowInfoModal } className="no_border_button info_link text-primary">
					<span className="material-icons-outlined info_icon">info</span>
				</button>

				{/* インフォメーションダイアログ */}
				<Modal show={ showInfoModal } onHide={ handleCloseInfoModal }>
					<Modal.Header className="text-white bg-primary">
						<Modal.Title>
							<span className="material-icons-outlined modal_info_icon">info</span>
							TIPS
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span>
							このページでは、リンクの編集ができます。
						</span>

						<div className="modal_action_btn_group">
							<Button variant="secondary" onClick={ handleCloseInfoModal }>閉じる</Button>
						</div>
					</Modal.Body>
				</Modal>

				<TopNav />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container pl-3">

				<h4 className="mb-4">
					リンク編集
				</h4>

				<Form noValidate validated={ validated } onSubmit={ handleForm }>

					<Form.Group className="mb-3" controlId="validationTextName">
						<Form.Label>(1) このリンクの名前を入力してください。(全角36文字以内) *</Form.Label>
						<Form.Control type="text" ref={ inputTextName } maxLength="36" placeholder="name" defaultValue={ location.state.name } required />
						<Form.Control.Feedback type="invalid">
							リンク名を入力してください。
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group className="mb-3" controlId="validationTextLink">
						<Form.Label>(2) 下記にリンク先URLを入力してください。 *</Form.Label>
						<Form.Control type="url" ref={ inputTextLink } placeholder="link" defaultValue={ location.state.link } required />
						<Form.Control.Feedback type="invalid">
							有効なURLを入力してください。
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group className="mb-3" controlId="validationTextIcon">
						<Form.Label>(3) アイコン画像URLを入力してください。</Form.Label>
						<Form.Control type="url" ref={ inputTextIcon } placeholder="icon" defaultValue={ location.state.icon } />
						<Form.Control.Feedback type="invalid">
							有効なURLを入力してください。
						</Form.Control.Feedback>
						<Form.Text className="text-muted">
							未設定の場合は、クライアント側でファビコンを自動取得して表示します。
						</Form.Text>
					</Form.Group>

					<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
						{/* 戻るボタン */}
						<Button variant="secondary" onClick={ goBackPrevious }>戻る</Button>
						{/* 確認ボタン */}
						<Button type="submit">確認</Button>
					</div>

				</Form>

			</Container>

		</>
	);
}

export default LinkListEdit;
