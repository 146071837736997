import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import { createEtc3001CynageTicker, updateEtc3001CynageTicker, deleteEtc3001CynageTicker } from "../graphql/mutations";

import * as common from "./common";
import * as database from "./database";

import TopNav from "./TopNav";

import {
	Alert,
	Breadcrumb,
	Button,
	Container
} from "react-bootstrap";


// Component ===================================================================

function TickerPageAddConfirm ()
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "TickerPageAddConfirm > TickerPageAddConfirm () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "TickerPageAddConfirm > TickerPageAddConfirm () location : " + JSON.stringify( location ) );
	//console.log( "TickerPageAddConfirm > TickerPageAddConfirm () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ addTickerPageDisabled, setAddTickerPageDisabled ] = useState( false );

	// タスク締切日
	const limitDate = common.getReplaceDate( location.state.limitDate, "-", "YYYY/MM/DD" );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [トピックスページ(新規追加確認)]";
	}, [] );

	// プログレストラッカー設定
	let progressTracker = [];
	for ( let i = 0; i <= location.state.page; i++ )
	{
		progressTracker.push( <div key={ i }>No.{ location.state.number }-{ ( i + 1 ) }</div> );
	}

	// 新規作成ページへ戻る処理
	const goBackPrevious = function ( event )
	{
		// クエリパラメータ作成
		const param = {
			pathname: "/component/TickerPageAdd",
			//search: "",
			//hash: "",
			state: {
				id: location.state.id,
				number: location.state.number,
				page: location.state.page,
				name: location.state.name,
				mainContents: location.state.mainContents,
				subContents: location.state.subContents,
				domainID: location.state.domainID,
				displayStartDate: location.state.displayStartDate,
				displayEndDate: location.state.displayEndDate,
				limitDate: location.state.limitDate
			}
		};
		// 遷移
		history.push( param );
	};

	// 次へ進む処理
	const goToNext = function ( event )
	{
		// クエリパラメータ作成
		const param = {
			pathname: "/component/TickerPageAdd",
			//search: "",
			//hash: "",
			state: {
				id: location.state.id,
				number: location.state.number,
				page: location.state.page + 1,
				name: location.state.name,
				mainContents: location.state.mainContents,
				subContents: location.state.subContents,
				domainID: location.state.domainID,
				displayStartDate: location.state.displayStartDate,
				displayEndDate: location.state.displayEndDate,
				limitDate: location.state.limitDate
			}
		};
		// 遷移
		history.push( param );
	};

	// ティッカーページ追加登録処理
	// ※ 非同期の重い処理が含まれているので、<form onSubmit={ addTickerPage }>では処理不可(ページ遷移が先行しエラーとなる)
	const addTickerPage = async function ( event )
	{
		// データを保存中でない場合
		if ( !addTickerPageDisabled )
		{
			// 保存ボタンの無効化
			setAddTickerPageDisabled( true );

			// ティッカーデータ更新(ページデータ追加)
			const input = {
				id: location.state.id,
				//name: location.state.name,
				mainContents: location.state.mainContents,
				subContents: location.state.subContents,
				domainID: location.state.domainID
			};
			const response = await database.mutationUpdate( updateEtc3001CynageTicker, input );

			//console.log( "TickerPageAddConfirm > addTickerPage () response : " + JSON.stringify( response ) );

			// ティッカーデータの更新(ページデータの追加)に成功した場合
			if ( response )
			{
				// クエリパラメータ作成
				const param = {
					pathname: "/component/TickerPageAddComplete/" + location.state.id,
					//search: "",
					//hash: "",
					state: {
						page: location.state.page,
						number: location.state.number
					}
				};
				// 遷移
				history.push( param );
			}
			// ティッカーデータの更新(ページデータの追加)に失敗した場合
			else
			{
				console.error( "TickerPageAddConfirm > addTickerPage () ticker page data create error." );
			}
		}
	};

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/TickerList" ) }>トピックス</Breadcrumb.Item>
					<Breadcrumb.Item onClick={ () => history.push( "/component/TickerPageList/" + location.state.id ) }>トピックスページ</Breadcrumb.Item>
					{ ( 0 < location.state.page ) ? <Breadcrumb.Item active>...</Breadcrumb.Item> : "" }
					<Breadcrumb.Item active>新規追加-{ ( location.state.page + 1 ) } (確認)</Breadcrumb.Item>
				</Breadcrumb>

				<TopNav />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<h4 className="mb-4">
					トピックスページ新規追加 (確認)
				</h4>

				{/* プログレストラッカー */}
				<Alert className="progress_tracker mb-4">
					{ progressTracker }
					<div className="active">確認</div>
				</Alert>

				<div>
					(1) トピックスの名前 (全角36文字以内)<br className="wide_br" />
					<p className="ms-3">{ location.state.name }</p>
				</div>

				<div>
					(2) トピックスの内容 (全角100文字以内)<br className="wide_br" />
					<p className="ms-3">{ location.state.mainContents[ location.state.page ] }</p>
				</div>

				<div>
					(3) リンク先URL<br className="wide_br" />
					<p className="ms-3">{ location.state.subContents[ location.state.page ] || "無し" }</p>
				</div>

				<div>
					(4) トピックスの表示開始日と表示終了日、およびタスク締切日<br className="wide_br" />
					<p className="ms-3">
						表示開始日 : { common.getReplaceDate( location.state.displayStartDate, "-", "YYYY/MM/DD" ) }<br />
						表示終了日 : { common.getReplaceDate( location.state.displayEndDate, "-", "YYYY/MM/DD" ) }<br />
						タスク締切日 : { limitDate || "無し" }
					</p>
					<p className="ms-3" style={{ color: "#f00" }}>{ ( limitDate ) ? "※ このトピックスはタスク化されます。" : "" }</p>
				</div>

				<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
					<Button variant="secondary" onClick={ goBackPrevious }>修正</Button>
					<Button onClick={ goToNext }>次ページ作成</Button>
					<Button onClick={ addTickerPage } disabled={ addTickerPageDisabled }>保存</Button>
				</div>

			</Container>

		</>
	);
}

export default TickerPageAddConfirm;
