import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import { createEtc3001CynageTicker, updateEtc3001CynageTicker, deleteEtc3001CynageTicker } from "../graphql/mutations";
import { createEtc3001CynageSequence, updateEtc3001CynageSequence, deleteEtc3001CynageSequence } from "../graphql/mutations";
import { updateExEtc3001CynageSequence } from "../graphql/mutations";
import { getEtc3001CynageSequence, listEtc3001CynageSequences } from "../graphql/queries";

import * as common from "./common";
import * as database from "./database";

import TopNav from "./TopNav";

import {
	Alert,
	Breadcrumb,
	Button,
	Container
} from "react-bootstrap";


// Component ===================================================================

function TickerAddConfirm ()
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "TickerAddConfirm > TickerAddConfirm () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "TickerAddConfirm > TickerAddConfirm () location : " + JSON.stringify( location ) );
	//console.log( "TickerAddConfirm > TickerAddConfirm () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ addTickerDisabled, setAddTickerDisabled ] = useState( false );

	// タスク締切日
	const limitDate = common.getReplaceDate( location.state.limitDate, "-", "YYYY/MM/DD" );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [トピックス(新規追加確認)]";
	}, [] );

	// 新規作成ページへ戻る処理
	const goBackPrevious = function ( event )
	{
		// クエリパラメータ作成
		const param = {
			pathname: "/component/TickerAdd",
			//search: "",
			//hash: "",
			state: {
				page: location.state.page,
				name: location.state.name,
				mainContents: location.state.mainContents,
				subContents: location.state.subContents,
				domainID: location.state.domainID,
				displayStartDate: location.state.displayStartDate,
				displayEndDate: location.state.displayEndDate,
				limitDate: location.state.limitDate
			}
		};
		// 遷移
		history.push( param );
	};

	// 次へ進む処理
	const goToNext = function ( event )
	{
		// クエリパラメータ作成
		const param = {
			pathname: "/component/TickerAdd",
			//search: "",
			//hash: "",
			state: {
				page: location.state.page + 1,
				name: location.state.name,
				mainContents: location.state.mainContents,
				subContents: location.state.subContents,
				domainID: location.state.domainID,
				displayStartDate: location.state.displayStartDate,
				displayEndDate: location.state.displayEndDate,
				limitDate: location.state.limitDate
			}
		};
		// 遷移
		history.push( param );
	};

	// ティッカー追加登録処理
	// ※ 非同期の重い処理が含まれているので、<form onSubmit={ addTicker }>では処理不可(ページ遷移が先行しエラーとなる)
	const addTicker = async function ( event )
	{
		// データを保存中でない場合
		if ( !addTickerDisabled )
		{
			// 保存ボタンの無効化
			setAddTickerDisabled( true );

			// シーケンスデータ数値取得(非同期)
			const count = await common.getSequenceValue(
				getEtc3001CynageSequence,
				createEtc3001CynageSequence,
				updateExEtc3001CynageSequence,
				location.state.domainID,
				"Ticker",
				"number",
				1
			);

			//console.log( "TickerAddConfirm > addTicker () count : " + JSON.stringify( count ) );

			// シーケンスデータ数値の取得に成功した場合
			if ( 0 <= count )
			{
				// ティッカーデータ生成
				const input = {
					number: count,
					name: location.state.name,
					mainContents: location.state.mainContents,
					subContents: location.state.subContents,
					domainID: location.state.domainID,
					displayStartDate: location.state.displayStartDate,
					displayEndDate: location.state.displayEndDate,
					limitDate: location.state.limitDate || undefined
				};
				const response = await database.mutationCreate( createEtc3001CynageTicker, input );

				//console.log( "TickerAddConfirm > addTicker () response : " + JSON.stringify( response ) );

				// ティッカーデータの生成に成功した場合
				if ( response )
				{
					// クエリパラメータ作成
					const param = {
						pathname: "/component/TickerAddComplete",
						//search: "",
						//hash: "",
						state: {
							page: location.state.page
						}
					};
					// 遷移
					history.push( param );
				}
				// ティッカーデータの生成に失敗した場合
				else
				{
					console.error( "TickerAddConfirm > addTicker () ticker data create error." );
				}
			}
			// シーケンスデータ数値の取得に失敗した場合
			else
			{
				console.error( "TickerAddConfirm > addTicker () sequence value (Ticker.number) get error." );
			}
		}
	};

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/TickerList" ) }>トピックス</Breadcrumb.Item>
					{ ( 0 < location.state.page ) ? <Breadcrumb.Item active>...</Breadcrumb.Item> : "" }
					<Breadcrumb.Item active>新規作成-{ ( location.state.page + 1 ) } (確認)</Breadcrumb.Item>
				</Breadcrumb>

				<TopNav />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<h4 className="mb-4">
					トピックス新規作成 (確認)
				</h4>

				{/* プログレストラッカー */}
				<Alert className="progress_tracker mb-4">
					<div>No. (新規)-1</div>
					<div className="active">確認</div>
				</Alert>

				<div>
					(1) トピックスの名前 (全角36文字以内)<br className="wide_br" />
					<p className="ms-3">{ location.state.name }</p>
				</div>

				<div>
					(2) トピックスの内容 (全角100文字以内)<br className="wide_br" />
					<p className="ms-3">{ location.state.mainContents[ location.state.page ] }</p>
				</div>

				<div>
					(3) リンク先URL<br className="wide_br" />
					<p className="ms-3">{ location.state.subContents[ location.state.page ] || "無し" }</p>
				</div>

				<div>
					(4) トピックスの表示開始日と表示終了日、およびタスク締切日<br className="wide_br" />
					<p className="ms-3">
						表示開始日 : { common.getReplaceDate( location.state.displayStartDate, "-", "YYYY/MM/DD" ) }<br />
						表示終了日 : { common.getReplaceDate( location.state.displayEndDate, "-", "YYYY/MM/DD" ) }<br />
						タスク締切日 : { limitDate || "無し" }
					</p>
					<p className="ms-3" style={{ color: "#f00" }}>{ ( limitDate ) ? "※ このトピックスはタスク化されます。" : "" }</p>
				</div>

				<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
					<Button variant="secondary" onClick={ goBackPrevious }>修正</Button>
					<Button onClick={ goToNext }>次ページ作成</Button>
					<Button onClick={ addTicker } disabled={ addTickerDisabled }>保存</Button>
				</div>

			</Container>

		</>
	);
}

export default TickerAddConfirm;
