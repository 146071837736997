import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import * as common from "./common";

import TopNav from "./TopNav";

import {
	Alert,
	Breadcrumb,
	Button,
	Container,
	Form,
	Modal
} from "react-bootstrap";


// Component ===================================================================

function TickerPageAdd ()
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "TickerPageAdd > TickerPageAdd () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "TickerPageAdd > TickerPageAdd () location : " + JSON.stringify( location ) );
	//console.log( "TickerPageAdd > TickerPageAdd () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const inputTextName = useRef();
	const inputTextMainContents = useRef();
	const inputTextSubContents = useRef();
	const inputTextDisplayStartDate = useRef();
	const inputTextDisplayEndDate = useRef();
	const inputTextLimitDate = useRef();
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ showInfoModal, setShowInfoModal ] = useState( false );
	const [ helpLimitDate, setHelpLimitDate ] = useState( "" );
	const [ validated, setValidated ] = useState( false );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [トピックスページ(新規追加)]";

		// タスク締切日のヘルプテキスト表示更新
		onChangeLimitDate( location.state.limitDate );
	}, [] );

	// プログレストラッカー設定
	let progressTracker = [];
	for ( let i = 0; i <= location.state.page; i++ )
	{
		if ( i === location.state.page )
		{
			progressTracker.push( <div key={ i } className="active">No. { location.state.number }-{ ( i + 1 ) }</div> );
		}
		else
		{
			progressTracker.push( <div key={ i }>No. { location.state.number }-{ ( i + 1 ) }</div> );
		}
	}

	// タスク締切日のヘルプテキスト表示更新処理
	const onChangeLimitDate = function ( date )
	{
		//console.log( "TickerPageAdd > onChangeLimitDate () date : " + JSON.stringify( date ) );

		// タスク締切日のヘルプテキスト表示更新
		setHelpLimitDate(
			( date ) ? "タスク化されます。" : ""
		);
	};

	// ページ一覧へ戻る処理
	const goBackPrevious = function ( event )
	{
		// 遷移
		history.push( "/component/TickerPageList/" + location.state.id );
	};

	// 送信処理
	const handleForm = function ( event )
	{
		// バリデーションチェック
		const form = event.currentTarget;
		setValidated( true );

		// ページ遷移(リロード)動作をキャンセル
		event.preventDefault();

		// バリデーションを通過時の処理
		if ( form.checkValidity() )
		{
			// 入力された内容で上書き
			location.state.mainContents[ location.state.page ] = inputTextMainContents.current.value;
			location.state.subContents[ location.state.page ] = inputTextSubContents.current.value;

			// クエリパラメータ作成
			const param = {
				pathname: "/component/TickerPageAddConfirm",
				//search: "",
				//hash: "",
				state: {
					id: location.state.id,
					number: location.state.number,
					page: location.state.page,
					name: inputTextName.current.value,
					mainContents: location.state.mainContents,
					subContents: location.state.subContents,
					domainID: location.state.domainID,
					displayStartDate: location.state.displayStartDate,
					displayEndDate: location.state.displayEndDate,
					limitDate: location.state.limitDate
				}
			};
			// 遷移
			history.push( param );
		}
	};

	// 情報モーダルを閉じるイベントハンドラ
	const handleCloseInfoModal = () => setShowInfoModal( false );

	// 情報モーダルを表示するイベントハンドラ
	const handleShowInfoModal = () => setShowInfoModal( true );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/TickerList" ) }>トピックス</Breadcrumb.Item>
					<Breadcrumb.Item onClick={ () => history.push( "/component/TickerPageList/" + location.state.id ) }>トピックスページ</Breadcrumb.Item>
					{ ( 0 < location.state.page ) ? <Breadcrumb.Item active>...</Breadcrumb.Item> : "" }
					<Breadcrumb.Item active>新規追加-{ ( location.state.page + 1 ) }</Breadcrumb.Item>
				</Breadcrumb>

				{/* インフォメーションボタン */}
				<button onClick={ handleShowInfoModal } className="no_border_button info_link text-primary">
					<span className="material-icons-outlined info_icon">info</span>
				</button>

				{/* インフォメーションダイアログ */}
				<Modal show={ showInfoModal } onHide={ handleCloseInfoModal }>
					<Modal.Header className="text-white bg-primary">
						<Modal.Title>
							<span className="material-icons-outlined modal_info_icon">info</span>
							TIPS
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span>
							このページでは、トピックス内のページ新規追加ができます。
						</span>

						<div className="modal_action_btn_group">
							<Button variant="secondary" onClick={ handleCloseInfoModal }>閉じる</Button>
						</div>
					</Modal.Body>
				</Modal>

				<TopNav />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container pl-3">

				<h4 className="mb-4">
					トピックスページ新規追加
				</h4>

				<Form noValidate validated={ validated } onSubmit={ handleForm }>

					{/* プログレストラッカー */}
					<Alert className="progress_tracker mb-4">
						{ progressTracker }
					</Alert>

					<Form.Group className="mb-3" controlId="validationTextName">
						<Form.Label>(1) このトピックスの名前を入力してください。(全角36文字以内) *</Form.Label>
						<Form.Control
							type="text"
							ref={ inputTextName }
							maxLength="36"
							placeholder="name"
							defaultValue={ location.state.name }
							readOnly={ true }
							required
						/>
						<Form.Control.Feedback type="invalid">
							トピックス名を入力してください。
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group className="mb-3" controlId="validationTextMainContents">
						<Form.Label>(2) 下記にトピックスに表示する文章を入力してください。(全角100文字以内) *</Form.Label>
						<Form.Control
							type="text"
							ref={ inputTextMainContents }
							maxLength="100"
							placeholder="mainContents"
							defaultValue={ location.state.mainContents[ location.state.page ] }
							required
						/>
						<Form.Control.Feedback type="invalid">
							トピックスの内容を入力してください。
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group className="mb-3" controlId="validationTextSubContents">
						<Form.Label>(3) リンク先URLを入力してください。</Form.Label>
						<Form.Control
							type="url"
							ref={ inputTextSubContents }
							placeholder="subContents"
							defaultValue={ location.state.subContents[ location.state.page ] }
						/>
						<Form.Control.Feedback type="invalid">
							有効なURLを入力してください。
						</Form.Control.Feedback>
					</Form.Group>

					<div>
						(4) このトピックスの表示開始日と表示終了日を選択してください。<br />
						また、このトピックスをタスク化する場合は、タスク締切日を選択してください。
					</div>
					<table style={{ width: "100%" }}>
						<tbody>
							<tr style={{ verticalAlign: "top" }}>
								<td>
									<Form.Group className="mb-3" controlId="validationDisplayStartDate">
										<Form.Label>表示開始日 *</Form.Label>
										<Form.Control
											type="date"
											ref={ inputTextDisplayStartDate }
											defaultValue={ location.state.displayStartDate }
											readOnly={ true }
											required
										/>
										<Form.Control.Feedback type="invalid">
											トピックスの表示を開始する<br />日付を指定してください。
										</Form.Control.Feedback>
									</Form.Group>
								</td>
								<td>
									<Form.Group className="mb-3" controlId="validationDisplayEndDate">
										<Form.Label>表示終了日 *</Form.Label>
										<Form.Control
											type="date"
											ref={ inputTextDisplayEndDate }
											defaultValue={ location.state.displayEndDate }
											readOnly={ true }
											required
										/>
										<Form.Control.Feedback type="invalid">
											トピックスの表示を終了する<br />日付を指定してください。
										</Form.Control.Feedback>
									</Form.Group>
								</td>
								<td>
									<Form.Group className="mb-3" controlId="validationLimitDate">
										<Form.Label>タスク締切日</Form.Label>
										<Form.Control
											type="date"
											ref={ inputTextLimitDate }
											onChange={ ( event ) => onChangeLimitDate( event.target.value ) }
											defaultValue={ location.state.limitDate }
											readOnly={ true }
										/>
										<Form.Text style={{ color: "#f00" }}>
											{ helpLimitDate }
										</Form.Text>
									</Form.Group>
								</td>
							</tr>
						</tbody>
					</table>

					<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
						{/* 戻るボタン */}
						<Button variant="secondary" onClick={ goBackPrevious }>戻る</Button>
						{/* 確認ボタン */}
						<Button type="submit">確認</Button>
					</div>

				</Form>

			</Container>

		</>
	);
}

export default TickerPageAdd;
