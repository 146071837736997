import Amplify, { Auth, API, graphqlOperation } from "aws-amplify";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import * as database from "./database";


// define const ================================================================

// タイムゾーン
const TIME_ZONE = "Asia/Tokyo";

// localStorage のキー名
// 既読お知らせIDリスト
const READED_INFORMATION_LIST = "readedInformationList";

// ページタイトル (ベース)
export const PAGE_TITLE = "CYnAGE 管理ツール";


// function ====================================================================

dayjs.extend( utc );
dayjs.extend( timezone );
dayjs.tz.setDefault( TIME_ZONE );

//console.log( "common > common () Amplify : " + JSON.stringify( Amplify ) );
//console.log( "common > common () API : " + JSON.stringify( API ) );
//console.log( "common > common () process.env : " + JSON.stringify( process.env ) );

/**
 * UTC 日時の取得処理
 *
 * @param {*}      utc		UTC 日時指定
 * @param {string} format	フォーマット
 *
 * @return {*}	UTC 日時
 */
export const getUTCDateTime = function ( utc = null, format = null )
{
	const dateTime = ( utc ) ? new Date( utc ) : new Date();

	//console.log( "common > getUTCDateTime () dateTime : " + JSON.stringify( dateTime ) );

	// フォーマットしない場合、date型のまま返す
	if ( !format ) return dateTime;

	// 日時のフォーマット
	return formatDateTime( dateTime, format );
};

/**
 * 日本時間(JST 日時)の取得処理
 *
 * @param {*}      utc		UTC 日時指定
 * @param {string} format	(Day.js)フォーマット
 *
 * @return {date}	日本時間(JST 日時)
 */
export const getJSTDateTime = function ( utc = null, format = null )
{
	let jst = ( utc ) ? dayjs.utc( utc ).tz() : dayjs.tz();
	jst = ( format ) ? jst.format( format ) : jst.format();

	//console.log( "common > getJSTDateTime () jst : " + JSON.stringify( jst ) );

	return jst;
};

/**
 * 外部(JSON)データ取得処理
 *
 * @param {string} url	取得先 URL
 *
 * @return {string}	外部(JSON)データ
 */
export const getExternalJSON = async function ( url )
{
	//console.log( "common > getExternalJSON () url : " + JSON.stringify( url ) );

	let result = null;

	await fetch(
		url,
		{
			method: "GET",
			cache: "no-cache"
		}
	)
	.then ( function ( response )
	{
		//console.log( "common > getExternalJSON () response : " + JSON.stringify( response ) );
		//console.dir( response );

		if ( response.ok )
		{
			return response.json();
		}
		else
		{
			throw new Error( response.status + ":" + response.statusText );
		}
	} )
	.then ( function ( json )
	{
		//console.log( "common > getExternalJSON () json : " + JSON.stringify( json ) );

		result = json;
	} )
	.catch ( function ( error )
	{
		console.error( "common > getExternalJSON () error : " + error );
	} );

	return result;
};

/**
 * 外部(text)データ取得処理
 *
 * @param {string} url	取得先 URL
 *
 * @return {string}	外部(text)データ
 */
export const getExternalText = async function ( url )
{
	//console.log( "common > getExternalText () url : " + JSON.stringify( url ) );

	let result = null;

	await fetch(
		url,
		{
			method: "GET",
			cache: "no-cache"
		}
	)
	.then ( function ( response )
	{
		//console.log( "common > getExternalText () response : " + JSON.stringify( response ) );
		//console.dir( response );

		if ( response.ok )
		{
			result = response.text();

			//console.log( "common > getExternalText () result : " + JSON.stringify( result ) );
			//console.dir( result );
		}
		else
		{
			throw new Error( response.status + ":" + response.statusText );
		}
	} )
	.catch ( function ( error )
	{
		console.error( "common > getExternalText () error : " + error );
	} );

	return result;
};

/**
 * ユーザーデータ取得(非同期)処理
 *
 * @param {object} object	組織テーブルのクエリ取得オブジェクト
 *
 * @return {object}	{ user: ユーザー情報, domain: 組織データ }
 */
export const getUserData = async function ( object )
{
	// 【Cognito】ユーザー情報取得
	const user = await Auth.currentAuthenticatedUser();

	//console.log( "common > getUserData () user : " + JSON.stringify( user ) );
	//console.dir( user );

	// Cognito所属グループ名取得
	// ※ Cognitoで複数のグル－プに所属させたユーザーの場合、複数のグループ名が得られる
	const payload = user.signInUserSession.accessToken.payload;
	//const payload = user.signInUserSession.idToken.payload;
	// ※ 管理ツール側は複数グループ所属を非対応にしておきます。ので[ 0 ]とする
	const group = ( payload[ "cognito:groups" ] ) ? payload[ "cognito:groups" ][ 0 ] : null;

	//console.log( "common > getUserData () cognito groups : " + JSON.stringify( payload[ "cognito:groups" ] ) );
	//console.log( "common > getUserData () group : " + JSON.stringify( group ) );

	let domain = null;

	// Cognito所属グループ(配列)の取得に成功した場合 … ユーザーがCognitoのグループに所属している場合
	if ( group )
	{
		// 組織データ取得
		const variables = {
			id: group
		};
		const item = await database.queryGet( object, variables );

		//console.log( "common > getUserData () item : " + JSON.stringify( item ) );
		//console.dir( item );

		// 組織データの取得に成功した場合
		if ( item )
		{
			// 組織データ確保
			domain = item;
		}
		// 組織データの取得に失敗した場合
		else
		{
			console.error( "common > getUserData () domain data get error. group : " + JSON.stringify( group ) );
		}
	}
	// Cognito所属グループ(配列)の取得に失敗した場合 … ユーザーがCognitoのどのグループにも所属しない場合
	else
	{
		console.error( "common > getUserData () not belong cognito:group error. : " + JSON.stringify( user ) );
	}

	//console.log( "common > getUserData () domain : " + JSON.stringify( domain ) );

	return {
		user: user,
		domain: domain
	};
};

/**
 * フルネーム取得処理
 *
 * @param {string} familyName	名字
 * @param {string} firstName	名前
 *
 * @return {string}	フルネーム
 */
export const getFullName = function ( familyName, firstName )
{
	// 名字と名前の両方が入力されている場合
	if ( ( familyName ) && ( firstName ) )
	{
		return familyName + " " + firstName;
	}
	// 名字しか入力されていない場合
	else if ( familyName )
	{
		return familyName;
	}
	// 名前しか入力されていない場合
	else if ( firstName )
	{
		return firstName;
	}

	return "名無し";
};

/**
 * ティッカーデータ(配列)取得(非同期)処理
 * ※ (表示用)ティッカー番号(number)で降順ソート済み
 *
 * @param {object} object	ティッカーテーブルのクエリ取得オブジェクト
 * @param {string} domainID	組織ID
 *
 * @return {object}	データレコード
 */
export const getTickerArray = async function ( object, domainID )
{
	//console.log( "common > getTickerArray () domainID : " + JSON.stringify( domainID ) );

	// ティッカーデータ取得
	const filter = {
		domainID: {
			eq: domainID
		},
		isDeleted: {
			ne: true
		}
	};
	const items = await database.queryList( object, filter );

	//console.log( "common > getTickerArray () items : " + JSON.stringify( items ) );
	//console.dir( items );

	// ティッカーデータ(配列)を(表示用)ティッカー番号(number)で降順ソート(破壊的変更)
	items.sort( function ( a, b )
	{
		return b.number - a.number;
	} );

	return items;
};

/**
 * リンクリストデータ(配列)取得(非同期)処理
 * ※ リンクリスト表示順(order)で昇順ソート済み
 *
 * @param {object} object	リンクリストテーブルのクエリ取得オブジェクト
 * @param {string} domainID	組織ID
 *
 * @return {object}	データレコード
 */
export const getLinkListArray = async function ( object, domainID )
{
	//console.log( "common > getLinkListArray () domainID : " + JSON.stringify( domainID ) );

	// リンクリストデータ取得
	const filter = {
		domainID: {
			eq: domainID
		}
		// 1つの項目に対して複数の値を指定する場合は、or条件の配列で包むらしい
		//or: [
		//	{ domainID: { eq: 1 } }
		//]
	};
	const items = await database.queryList( object, filter );

	//console.log( "common > getLinkListArray () items : " + JSON.stringify( items ) );
	//console.dir( items );

	// リンクリストデータ(配列)をリンクリスト表示順(order)で昇順ソート(破壊的変更)
	items.sort( function ( a, b )
	{
		return a.order - b.order;
	} );

	return items;
};

/**
 * メンバーデータ取得(非同期)処理
 *
 * @param {object} object	メンバーテーブルのクエリ取得オブジェクト
 * @param {string} id		メンバーID (Cognito属性:sub)
 * @param {string} domainID	組織ID
 *
 * @return {object}	データレコード
 */
export const getMember = async function ( object, id, domainID )
{
	//console.log( "common > getMember () domainID : " + JSON.stringify( domainID ) );

	// メンバーデータ取得
	const variables = {
		id: id,
		domainID: domainID
	};
	const item = await database.queryGet( object, variables );

	//console.log( "common > getMember () item : " + JSON.stringify( item ) );
	//console.dir( item );

	return item;
};

/**
 * メンバーデータ(配列)取得(非同期)処理
 * ※ 招待された日時(invitedDate)で昇順ソート済み
 *
 * @param {object} object	メンバーテーブルのクエリ取得オブジェクト
 * @param {string} domainID	組織ID
 *
 * @return {object}	データレコード
 */
export const getMemberArray = async function ( object, domainID )
{
	//console.log( "common > getMemberArray () domainID : " + JSON.stringify( domainID ) );

	// メンバーデータ取得
	const filter = {
		domainID: {
			eq: domainID
		}
		// 1つの項目に対して複数の値を指定する場合は、or条件の配列で包むらしい
		//or: [
		//	{ domainID: { eq: 1 } }
		//]
	};
	const items = await database.queryList( object, filter );

	//console.log( "common > getMemberArray () items : " + JSON.stringify( items ) );
	//console.dir( items );

	// メンバーデータ(配列)を招待された日時(invitedDate)で降順ソート(破壊的変更)
	items.sort( function ( a, b )
	{
		return new Date( b.invitedDate ) - new Date( a.invitedDate );
	} );

	return items;
};

/**
 * メンバー異動履歴追加処理
 *
 * @param {object} object		メンバー異動履歴テーブルの追加オブジェクト
 * @param {string} domainID		組織ID
 * @param {array}  inviteList	招待メンバーID(Cognito属性:sub)リスト(string配列)
 * @param {array}  retireList	退職メンバーID(Cognito属性:sub)リスト(string配列)
 * @param {array}  onLeaveList	休職メンバーID(Cognito属性:sub)リスト(string配列)
 * @param {array}  returnList	復職メンバーID(Cognito属性:sub)リスト(string配列)
 *
 * @return {object}	処理結果
 */
export const createMemberTransferHistory = async function (
	object,
	domainID,
	inviteList = undefined,
	retireList = undefined,
	onLeaveList = undefined,
	returnList = undefined
)
{
	//console.log( "common > createMemberTransferHistory () inviteList : " + JSON.stringify( inviteList ) );
	//console.log( "common > createMemberTransferHistory () retireList : " + JSON.stringify( retireList ) );
	//console.log( "common > createMemberTransferHistory () onLeaveList : " + JSON.stringify( onLeaveList ) );
	//console.log( "common > createMemberTransferHistory () returnList : " + JSON.stringify( returnList ) );

	// 現在日時の取得
	const now = getUTCDateTime();

	//console.log( "common > createMemberTransferHistory () now : " + JSON.stringify( now ) );

	// ティッカーデータ生成
	const input = {
		domainID: domainID,
		transferDate: now,
		inviteList: inviteList,
		retireList: retireList,
		onLeaveList: onLeaveList,
		returnList: returnList
	};

	//console.log( "common > createMemberTransferHistory () input : " + JSON.stringify( input ) );

	const response = await database.mutationCreate( object, input );

	//console.log( "common > createMemberTransferHistory () response : " + JSON.stringify( response ) );
	//console.dir( response );

	return response;
};

/**
 * 招待キューデータ(配列)取得(非同期)処理
 * ※ 招待された日時(invitedDate)で昇順ソート済み
 *
 * @param {object}  object		招待キューテーブルのクエリ取得オブジェクト
 * @param {string}  domainID	組織ID
 * @param {boolean} isApproved	認証(承認/否認)フラグ
 * @param {boolean} isProcessed	認証済みフラグ
 *
 * @return {object}	データレコード
 */
export const getInviteArray = async function ( object, domainID, isApproved, isProcessed )
{
	//console.log( "common > getInviteArray () domainID : " + JSON.stringify( domainID ) );
	//console.log( "common > getInviteArray () isApproved : " + JSON.stringify( isApproved ) );
	//console.log( "common > getInviteArray () isProcessed : " + JSON.stringify( isProcessed ) );

	// 招待キューデータ取得
	const filter = {
		domainID: {
			eq: domainID
		},
		isApproved: {
			eq: isApproved
		},
		isProcessed: {
			eq: isProcessed
		}
	};
	const items = await database.queryList( object, filter );

	//console.log( "common > getInviteArray () items : " + JSON.stringify( items ) );
	//console.dir( items );

	// 招待キューデータ(配列)を招待された日時(invitedDate)で降順ソート(破壊的変更)
	items.sort( function ( a, b )
	{
		return new Date( b.invitedDate ) - new Date( a.invitedDate );
	} );

	return items;
};

/**
 * お知らせデータ(配列)取得(非同期)処理
 * ※ お知らせ日時(infoDate)で昇順ソート済み
 *
 * @param {object} object	お知らせテーブルのクエリ取得オブジェクト
 *
 * @return {object}	データレコード
 */
export const getInformationArray = async function ( object )
{
	// メンバーデータ取得
	const items = await database.queryList( object );

	//console.log( "common > getInformationArray () items : " + JSON.stringify( items ) );
	//console.dir( items );

	// お知らせデータ(配列)をお知らせ日時(infoDate)で降順ソート(破壊的変更)
	items.sort( function ( a, b )
	{
		return new Date( b.infoDate ) - new Date( a.infoDate );
	} );

	return items;
};

/**
 * シーケンスデータ数値取得(非同期)処理
 *
 * @attention Amplify(AppSync) 経由 DynamoDB では UPSERT が、ピュア DynamoDB のように使用することができない為、処理を get ～ create ～ update フローとしている
 *
 * @param {object} getObject	クエリ取得オブジェクト
 * @param {object} createObject	ミューテーション取得オブジェクト
 * @param {object} updateObject	ミューテーション取得オブジェクト
 * @param {string} domainID		組織ID
 * @param {string} table		テーブル名
 * @param {string} column		カラム名
 * @param {int}    value		増減値
 *
 * @return {int}	カウント数値
 */
export const getSequenceValue = async function ( getObject, createObject, updateObject, domainID, table, column, value )
{
	let sequence = null;

	// ティッカーデータ取得
	const variables = {
		domainID: domainID,
		name: table + "." + column
	};
	const ticker = await database.queryGet( getObject, variables );

	const input = {
		domainID: domainID,
		name: table + "." + column,
		value: value
	};

	// ティッカーデータの取得に失敗した場合
	if ( !ticker )
	{
		// シーケンスデータ生成
		sequence = await database.mutationCreate( createObject, input );
		// シーケンスデータの追加に成功した場合
		if ( sequence )
		{
			//console.log( "common > updateSequenceValue () sequence : " + JSON.stringify( sequence ) );

			return sequence.value;
		}
		// シーケンスデータの追加に失敗した場合
		else
		{
			//console.error( "common > updateSequenceValue () sequence create error. table.column : " + table + "." + column );
		}
	}

	// シーケンスデータの追加に成功していない場合
	if ( !sequence )
	{
		// シーケンスデータ更新
		sequence = await database.mutationUpdate( updateObject, input, undefined, "value" );
		// シーケンスデータの更新に成功した場合
		if ( sequence )
		{
			//console.log( "common > updateSequenceValue () sequence : " + JSON.stringify( sequence ) );

			return sequence.value;
		}
		// シーケンスデータの更新に失敗した場合
		else
		{
			console.error( "common > updateSequenceValue () sequence update error. table.column : " + table + "." + column );
		}
	}

	return -1;
};

/**
 * 文字列の JSX 出力形式変換処理
 *
 * @param {string} document	HTML ドキュメント
 *
 * @return {JSX}	JSX 出力
 */
export const stringToJSX = function ( document )
{
	//console.log( "common > stringToJSX () document : " + JSON.stringify( document ) );

	// 改行コードや改行タグで区切り、<div>タグで括る
	document = document.split( /\r\n|\r|\n|<br \/>|<br\/>|<br>/ ).map( function ( value, index )
	{
		return <span key={ index }>{ value }<br /></span>;
	} );

	//console.log( "common > stringToJSX () document : " + JSON.stringify( document ) );
	//console.dir( document );

	return (
		<>
			{ document }
		</>
	);
};

/**
 * サブドメイン取得処理
 *
 * @return {string}	サブドメイン
 */
export const getSubDomain = function ()
{
	//console.log( "common > getSubDomain () window.location : " + JSON.stringify( window.location ) );

	// サブドメイン取得
	const subDomain = window.location.hostname.split( "." ).shift();
	//const subDomain = "prod";
	//const subDomain = "dev";
	//const subDomain = "localhost";

	//console.log( "common > getSubDomain () subDomain : " + JSON.stringify( subDomain ) );

	return subDomain;
};

/**
 * 経過日数算出処理
 *
 * @param {*} dateTime	日時
 *
 * @return {number}	経過日数
 */
export const calcDiffDay = function ( dateTime )
{
	// 現在日時の取得
	const now = new Date();

	//console.log( "common > calcDiffDay () now : " + JSON.stringify( now ) );

	// 対象日時の取得
	const date = new Date( dateTime );

	//console.log( "common > calcDiffDay () dateTime : " + JSON.stringify( dateTime ) + " : " + JSON.stringify( date ) );

	// データレコード作成日時からの経過日数を算出
	const diffDay = ( now - date ) / 86400000;		// 86400000 = 1000ミリ秒 / 60秒 / 60分 / 24時間

	//console.log( "common > calcDiffDay () diffDay : " + JSON.stringify( diffDay ) );

	return diffDay;
};

/**
 * サインアウト処理
 *
 * @param {object} history	useHistoryフック
 */
export const signOut = async function ( history )
{
	// 【Cognito】サインアウト
	await Auth.signOut()
		.catch( ( error ) =>
		{
			console.error( "common > signOut () : sign out error. : " + JSON.stringify( error ) );
		} )
		.then( () =>
		{
			//window.location.reload();

			history.push( "/" );
		} );
};

/**
 * 日付のフォーマット処理
 *
 * @param {int}    year		年
 * @param {int}    month	月
 * @param {int}    date		日
 * @param {string} format	フォーマット
 *
 * @return {string}	日時
 */
export const formatDate = function ( year, month, date, format )
{
	// 置き換え
	format = format.replace( /YYYY|yyyy/g, year );
	format = format.replace( /MM/g, String( month ).padStart( 2, "0" ) );
	format = format.replace( /M/g, month );
	format = format.replace( /DD|dd/g, String( date ).padStart( 2, "0" ) );
	format = format.replace( /D|d/g, date );

	return format;
};
/**
 * 時間のフォーマット処理
 *
 * @param {int}    hours		時
 * @param {int}    minutes		分
 * @param {int}    seconds		秒
 * @param {int}    milliseconds	ミリ秒
 * @param {string} format		フォーマット
 *
 * @return {string}	日時
 */
export const formatTime = function ( hours, minutes, seconds, milliseconds, format )
{
	// 置き換え
	format = format.replace( /HH|hh/g, String( hours ).padStart( 2, "0" ) );
	format = format.replace( /H|h/g, hours );
	format = format.replace( /mm/g, String( minutes ).padStart( 2, "0" ) );
	format = format.replace( /m/g, minutes );
	format = format.replace( /ss/g, String( seconds ).padStart( 2, "0" ) );
	format = format.replace( /s/g, seconds );
	format = format.replace( /SSS/g, String( milliseconds ).padStart( 3, "0" ) );
	format = format.replace( /S/g, milliseconds );

	return format;
};
/**
 * 日時のフォーマット処理
 *
 * @param {date}   dateTime	日時
 * @param {string} format	フォーマット
 *
 * @return {string}	日時
 */
export const formatDateTime = function ( dateTime, format )
{
	const year = dateTime.getFullYear();
	const month = dateTime.getMonth() + 1;		// ※ 0～11 が返されるので +1 加算
	const date = dateTime.getDate();
	const hours = dateTime.getHours();
	const minutes = dateTime.getMinutes();
	const seconds = dateTime.getSeconds();
	const milliseconds = dateTime.getMilliseconds();

	format = formatDate( year, month, date, format );
	format = formatTime( hours, minutes, seconds, milliseconds, format );

	return format
};

/**
 * 加減日数ずらした日時を算出する処理
 *
 * @param {date}   dateTime	日時
 * @param {int}    shift	加減日数
 * @param {string} format	フォーマット
 *
 * @return {*}	日時
 */
export const getShiftDateTime = function ( dateTime, shift, format = null )
{
	// 加減日数ずらした日時を算出
	const shiftDateTime = new Date( dateTime.setDate( dateTime.getDate() + shift ) );

	// フォーマットしない場合、date型のまま返す
	if ( !format ) return shiftDateTime;

	// 日時のフォーマット
	return formatDateTime( shiftDateTime, format );
};

/**
 * "YYYY-MM-DD" 等を "YYYYMMDD" や "YYYY年MM月DD日" 等に変換する処理
 *
 * @param {string} str			日付文字列
 * @param {string} separater	セパレータ
 * @param {string} format		フォーマット
 *
 * @return {string}	日付文字列 (年月日)
 */
export const getReplaceDate = function ( str, separater, format )
{
	if ( !str ) return null;

	// 分割
	const arr = str.split( separater );

	if ( arr.length !== 3 ) return null;

	// 日時のフォーマット
	return formatDate( arr[ 0 ], arr[ 1 ], arr[ 2 ], format );
};

/**
 * "YYYYMMDD" を "YYYY-MM-DD" や "YYYY年MM月DD日" 等に分割する処理
 *
 * @param {string} str		日付文字列
 * @param {string} format	フォーマット
 *
 * @return {string}	日付文字列 (年月日)
 */
export const getSplitDate = function ( str, format )
{
	if ( ( !str ) || ( str.length !== 8 ) ) return null;

	// 日時のフォーマット
	return formatDate( str.substring( 0, 4 ), str.substring( 4, 6 ), str.substring( 6, 8 ), format );
};

/**
 * 連想配列(オブジェクト)の空判定処理
 *
 * @param {object} obj	連想配列(オブジェクト)
 *
 * @return {boolean}	空か否か
 */
export const isEmpty = function ( obj )
{
	return ( Object.keys( obj ).length === 0 );
};

/**
 * 招待メールアドレスリスト解析処理
 *
 * @param {string} emailList	招待メールアドレスリスト
 *
 * @return {array}	招待メールアドレスリスト配列
 */
export const parseEmailList = function ( emailList )
{
	const list = emailList
		// まずは改行コードで区切り
		.split( /\r\n|\r|\n/ )
		// リストのデータを加工
		.map( function ( value )
		{
			//console.log( "common > parseEmailList () value : " + JSON.stringify( value ) );

			// 更に","カンマで区切り
			const element = value.split( "," );

			// 念の為にトリム
			element[ 0 ] = element[ 0 ].trim();
			if ( element[ 1 ] ) element[ 1 ] = element[ 1 ].trim();

			// 連想配列にして返す
			return {
				mailAddress: element[ 0 ],
				permission: ( element[ 1 ] ) ? element[ 1 ].toUpperCase() : "N"
			};
		} )
		// リストのデータを絞り込み
		.filter( function ( element, index, array )
		{
			// 空行を削除
			if ( !element.mailAddress ) return false;

			// 重複メールアドレス行を削除
			return ( array.findIndex( function ( value )
			{
				return ( element.mailAddress === value.mailAddress )
			} ) === index );
		} );

	//console.log( "common > parseEmailList () list : " + JSON.stringify( list ) );

	return list;
};

/**
 * メールアドレスバリデーション処理
 *
 * @param {string} mailAddress	メールアドレス
 *
 * @return {boolean}	メールアドレスか否か
 */
export const isMailAddress = function ( mailAddress )
{
	const regExp = new RegExp( /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/ );

	return regExp.test( mailAddress );
};

/**
 * 既読お知らせIDリスト(localStorage)保存処理
 *
 * @param {array} readedIdList	既読お知らせIDリスト(配列)
 *
 * @return {boolean}	処理成否
 */
export const setReadedInformationList = function ( readedIdList )
{
	// 念の為、配列であることを確認
	if ( Array.isArray( readedIdList ) )
	{
		//console.log( "common > setReadedInformationList () readedIdList : " + JSON.stringify( readedIdList ) );

		// 既読お知らせIDリスト(localStorage)に保存
		localStorage.setItem( READED_INFORMATION_LIST, JSON.stringify( readedIdList ) );

		return true;
	}
	// 配列でない場合
	else
	{
		console.error( "common > setReadedInformationList () data type error. readedIdList <" + typeof readedIdList + "> : " + JSON.stringify( readedIdList ) );
	}

	return false;
};

/**
 * 既読お知らせIDリスト(localStorage)取得処理
 *
 * @param {array} dataList	お知らせデータリスト(配列)
 *
 * @return {array}	既読お知らせIDリスト(配列)
 */
export const getReadedInformationList = function ( dataList )
{
	//console.log( "common > getReadedInformationList () dataList : " + JSON.stringify( dataList ) );

	// 既読お知らせIDリスト(配列)の取得
	const readedIdList = JSON.parse( localStorage.getItem( READED_INFORMATION_LIST ) );

	//console.log( "common > getReadedInformationList () readedInformationList : " + JSON.stringify( readedIdList ) );

	let list = [];

	// 既読お知らせIDリスト(配列)の取得に成功した場合
	if ( readedIdList )
	{
		// 既読お知らせIDリスト(配列)を基にループ
		for ( const value1 of readedIdList )
		{
			// お知らせデータリスト(配列)内を検索
			const information = dataList.find( function ( value2 )
			{
				return value1 === value2.id;
			} );

			// 検索がヒットした場合
			// ※ 検索がヒットしなかった(神ツールで消された)お知らせIDは、次回保存時に消え失せることとなる
			if ( information )
			{
				// 検索ヒットしたお知らせIDを格納
				list.push( information.id );
			}
		}
	}

	//console.log( "common > getReadedInformationList () list : " + JSON.stringify( list ) );

	return list;
};

/**
 * 入力キー判定処理
 *
 * @param {object}   event		キー入力時イベント
 * @param {string}   key		キー
 * @param {function} callBack	実行コールバック関数
 */
export const checkKey = function ( event, key, callBack )
{
	//console.dir( event );
	//console.log( "common > checkKey () key : " + JSON.stringify( key ) );
	//console.log( "common > checkKey () event.key : " + JSON.stringify( event.key ) );
	//console.log( "common > checkKey () event.keyCode : " + JSON.stringify( event.keyCode ) );

	// @note: キー入力イベント色々
	//	onKeyPress
	//		漢字入力確定時 Enter キー入力
	//			イベント発生無し
	//		単独 Enter キー入力
	//			event.key : "Enter"
	//			event.keyCode : 0
	//	onKeyDown
	//		漢字入力確定時 Enter キー入力
	//			event.key : "Process"
	//			event.keyCode : 229
	//		単独 Enter キー入力
	//			event.key : "Enter"
	//			event.keyCode : 13
	//	onKeyUp
	//		漢字入力確定時 Enter キー入力
	//			event.key : "Enter"
	//			event.keyCode : 13
	//		単独 Enter キー入力
	//			event.key : "Enter"
	//			event.keyCode : 13

	// Enter キーが入力された場合
	if ( event.key === key )
	{
		// コールバック実行
		if ( callBack ) callBack( event );
	}
};

/**
 * IP アドレスを数値(int型)に変換する処理
 *
 * @param {string} IP	IPアドレス
 *
 * @return {number}	変換された数値(int型)
 */
export const IPv4ToLong = function ( IP )
{
	return parseInt( IP.split( "." ).map( ( value ) => Number( value ).toString( 2 ).padStart( 8, "0" ) ).join( "" ), 2 );
};

/**
 * 対象IPがサブネットIPの範囲内か判定する処理
 *
 * @cite: IPアドレスがサブネットマスクで指定した範囲内にあるか判定
 * https://tech-blog.s-yoshiki.com/entry/228
 *
 * @param {string} subnetIP	サブネットIP
 * @param {string} targetIP	対象IP
 *
 * @return {boolean}	範囲内か否か
 */
export const isIPv4InRange = function ( subnetIP, targetIP )
{
	// IP アドレスを数値(int型)に変換
	const targetIPLong = IPv4ToLong( targetIP );

	//console.log( "common > isIPv4InRange () targetIPLong : " + JSON.stringify( targetIPLong ) );

	// IP アドレス部とマスク部に分割
	const subnetIPArray = subnetIP.split( "/" );
	// IP アドレスを数値(int型)に変換
	const subnetIPLong = IPv4ToLong( subnetIPArray[ 0 ] );
	// マスクの取得
	const mask = Number( subnetIPArray[ 1 ] );

	//console.log( "common > isIPv4InRange () subnetIPArray : " + JSON.stringify( subnetIPArray ) );
	//console.log( "common > isIPv4InRange () subnetIPLong : " + JSON.stringify( subnetIPLong ) + " / " + JSON.stringify( mask ) );

	// マスクぶん右シフトして削る
	const subnetIPNetwork = subnetIPLong >>> ( 32 - mask );
	const targetIPNetwork = targetIPLong >>> ( 32 - mask );

	return ( subnetIPNetwork === targetIPNetwork );
};

/**
 * 対象IPがリスト内のサブネットIPにマッチするか判定する処理
 * @param {array} subnetIPArray	サブネットIPリスト
 * @param {string} targetIP		対象IP
 *
 * @return {boolean}	マッチしたか否か
 */
export const isIPv4Match = function ( subnetIPArray, targetIP )
{
	let isIPv4Match = false;

	// リストぶんループ
	for ( const value of subnetIPArray )
	{
		//console.log( "common > isIPv4Match () value : " + JSON.stringify( value ) );

		isIPv4Match = isIPv4InRange( value, targetIP );

		if ( isIPv4Match ) break;
	}

	return isIPv4Match;
}

/**
 * 【react-beautiful-dnd】ドロップ時の同一リスト内アイテム並べ替え処理
 *
 * @param {array}  list			リスト(配列)
 * @param {number} startIndex	ドラッグ(開始)インデックス
 * @param {number} endIndex		ドロップ(終了)インデックス
 *
 * @return {array}	並べ替え後リスト(配列)
 */
export const reactReautifulDnD_reOrder = function ( list, startIndex, endIndex )
{
	//console.log( "common > reactReautifulDnD_reOrder () list : " + JSON.stringify( list ) );
	//console.log( "common > reactReautifulDnD_reOrder () startIndex : " + JSON.stringify( startIndex ) );
	//console.log( "common > reactReautifulDnD_reOrder () endIndex : " + JSON.stringify( endIndex ) );

	// 配列の複製(値渡し)を生成
	const result = Array.from( list );
	// 指定インデックスの要素を取り除き取得
	const [ removed ] = result.splice( startIndex, 1 );
	// 指定インデックスに要素を挿入
	result.splice( endIndex, 0, removed );

	return result;
};

/**
 * 【react-beautiful-dnd】ドロップ時のリスト間アイテム移動並べ替え処理
 *
 * @param {array}  source			ドラッグ(開始)リスト(配列)
 * @param {array}  destination		ドロップ(終了)リスト(配列)
 * @param {object} eventSource		ドラッグ(開始)イベント情報
 * @param {object} eventDestination	ドロップ(終了)イベント情報
 *
 * @return {object}	並べ替え後リスト(配列)群
 */
export const reactReautifulDnD_move = function ( source, destination, eventSource, eventDestination )
{
	//console.log( "common > reactReautifulDnD_move () source : " + JSON.stringify( source ) );
	//console.log( "common > reactReautifulDnD_move () destination : " + JSON.stringify( destination ) );
	//console.log( "common > reactReautifulDnD_move () eventSource : " + JSON.stringify( eventSource ) );
	//console.log( "common > reactReautifulDnD_move () eventDestination : " + JSON.stringify( eventDestination ) );

	// 配列の複製(値渡し)を生成
	const sourceClone = Array.from( source );
	const destinationClone = Array.from( destination );
	// 指定インデックスの要素を取り除き取得
	const [ removed ] = sourceClone.splice( eventSource.index, 1 );
	// 指定インデックスに要素を挿入
	destinationClone.splice( eventDestination.index, 0, removed );

	// 返り値を1つの連想配列にまとめる
	const result = {};
	result[ eventSource.droppableId ] = sourceClone;
	result[ eventDestination.droppableId ] = destinationClone;

	return result;
};

/**
 * 【表示ページング】総ページ数算出処理
 *
 * @param {number} limit	1ページあたりの表示件数
 * @param {number} length	全件数
 *
 * @return {number}	総ページ数
 */
export const calcTotalPage = function ( limit, length )
{
	// 総ページ数の算出 (小数点以下切り上げ)
	return Math.ceil( length / limit );
};

/**
 * 【表示ページング】オフセット算出処理
 *
 * @param {number} limit	1ページあたりの表示件数
 * @param {number} page		現ページ
 *
 * @return {
 * 	{number}	表示先頭データ位置
 * 	{number}	表示最終データ位置
 * }
 */
export const calcPagingOffset = function ( limit, page )
{
	// 現ページの表示データページング
	// 表示先頭データ
	const start = ( page - 1 ) * limit;
	// 表示最終データ
	const end = start + limit;

	return { start, end };
};
