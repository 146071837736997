import {
//	BrowserRouter as Router,
	Switch,
	Route,
//	Link,
//	useRouteMatch,
//	useParams,
//	useLocation,
//	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import Error from "./Error";
import InformationDetail from "./InformationDetail";
import InformationList from "./InformationList";
import LeftMenu from "./LeftMenu";
import LinkList from "./LinkList";
import LinkListAdd from "./LinkListAdd";
import LinkListAddComplete from "./LinkListAddComplete";
import LinkListAddConfirm from "./LinkListAddConfirm";
import LinkListDeleteComplete from "./LinkListDeleteComplete";
import LinkListEdit from "./LinkListEdit";
import LinkListEditComplete from "./LinkListEditComplete";
import LinkListEditConfirm from "./LinkListEditConfirm";
import LinkListSortComplete from "./LinkListSortComplete";
import MemberAbsenceComplete from "./MemberAbsenceComplete";
import MemberAbsenceConfirm from "./MemberAbsenceConfirm";
import MemberCertificationComplete from "./MemberCertificationComplete";
import MemberCertificationConfirm from "./MemberCertificationConfirm";
import MemberCertificationList from "./MemberCertificationList";
import MemberDeleteComplete from "./MemberDeleteComplete";
import MemberDeleteConfirm from "./MemberDeleteConfirm";
import MemberEdit from "./MemberEdit";
import MemberEditComplete from "./MemberEditComplete";
import MemberEditConfirm from "./MemberEditConfirm";
import MemberInvite from "./MemberInvite";
import MemberInviteComplete from "./MemberInviteComplete";
import MemberInviteConfirm from "./MemberInviteConfirm";
import MemberList from "./MemberList";
import Schedule from "./Schedule";
import TickerAdd from "./TickerAdd";
import TickerAddConfirm from "./TickerAddConfirm";
import TickerAddComplete from "./TickerAddComplete";
import TickerDeleteComplete from "./TickerDeleteComplete";
import TickerList from "./TickerList";
import TickerPageAdd from "./TickerPageAdd";
import TickerPageAddComplete from "./TickerPageAddComplete";
import TickerPageAddConfirm from "./TickerPageAddConfirm";
import TickerPageDeleteComplete from "./TickerPageDeleteComplete";
import TickerPageEdit from "./TickerPageEdit";
import TickerPageEditComplete from "./TickerPageEditComplete";
import TickerPageEditConfirm from "./TickerPageEditConfirm";
import TickerPageList from "./TickerPageList";
import Top from "./Top";
import TopicSort from "./TopicSort";
import TopicSortComplete from "./TopicSortComplete";
import DebugTool from "./DebugTool";


// Style Sheet =================================================================

import "./css/Frame.css";


// Component ===================================================================

function Frame ( props )
{
	//console.log( "Frame > Frame () props : " + JSON.stringify( props ) );

	return (
		// 疑似フレーム
		<div className="frame">

			{/* 左フレーム */}
			<div className="left_frame bg-primary">
				<LeftMenu userData={ props.userData } memberData={ props.memberData } />
			</div>

			{/* 右フレーム */}
			<div className="right_frame">
				<Switch>

					<Route path={ "/component/Error" }>
						<Error />
					</Route>

					<Route path={ "/component/InformationDetail" }>
						<InformationDetail />
					</Route>

					<Route path={ "/component/InformationList" }>
						<InformationList />
					</Route>

					<Route path={ "/component/LinkList" }>
						<LinkList userData={ props.userData } />
					</Route>

					<Route path={ "/component/LinkListAdd" }>
						<LinkListAdd />
					</Route>

					<Route path={ "/component/LinkListAddComplete" }>
						<LinkListAddComplete />
					</Route>

					<Route path={ "/component/LinkListAddConfirm" }>
						<LinkListAddConfirm userData={ props.userData } />
					</Route>

					<Route path={ "/component/LinkListDeleteComplete" }>
						<LinkListDeleteComplete />
					</Route>

					<Route path={ "/component/LinkListEdit" }>
						<LinkListEdit />
					</Route>

					<Route path={ "/component/LinkListEditComplete" }>
						<LinkListEditComplete />
					</Route>

					<Route path={ "/component/LinkListEditConfirm" }>
						<LinkListEditConfirm userData={ props.userData } />
					</Route>

					<Route path={ "/component/LinkListSortComplete" }>
						<LinkListSortComplete />
					</Route>

					{ ( props.memberData.permission === "A" )
						?
							<Route path={ "/component/MemberAbsenceComplete" }>
								<MemberAbsenceComplete />
							</Route>
						:
							null
					}

					{ ( props.memberData.permission === "A" )
						?
							<Route path={ "/component/MemberAbsenceConfirm" }>
								<MemberAbsenceConfirm userData={ props.userData } />
							</Route>
						:
							null
					}

					{ ( props.memberData.permission === "A" )
						?
							<Route path={ "/component/MemberCertificationComplete" }>
								<MemberCertificationComplete />
							</Route>
						:
							null
					}

					{ ( props.memberData.permission === "A" )
						?
							<Route path={ "/component/MemberCertificationConfirm" }>
								<MemberCertificationConfirm />
							</Route>
						:
							null
					}

					{ ( props.memberData.permission === "A" )
						?
							<Route path={ "/component/MemberCertificationList" }>
								<MemberCertificationList userData={ props.userData } />
							</Route>
						:
							null
					}

					{ ( props.memberData.permission === "A" )
						?
							<Route path={ "/component/MemberDeleteComplete" }>
								<MemberDeleteComplete />
							</Route>
						:
							null
					}

					{ ( props.memberData.permission === "A" )
						?
							<Route path={ "/component/MemberDeleteConfirm" }>
								<MemberDeleteConfirm userData={ props.userData } />
							</Route>
						:
							null
					}

					{ ( props.memberData.permission === "A" )
						?
							<Route path={ "/component/MemberEdit" }>
								<MemberEdit userData={ props.userData } memberData={ props.memberData } />
							</Route>
						:
							null
					}

					{ ( props.memberData.permission === "A" )
						?
							<Route path={ "/component/MemberEditComplete" }>
								<MemberEditComplete />
							</Route>
						:
							null
					}

					{ ( props.memberData.permission === "A" )
						?
							<Route path={ "/component/MemberEditConfirm" }>
								<MemberEditConfirm />
							</Route>
						:
							null
					}

					{ ( props.memberData.permission === "A" )
						?
							<Route path={ "/component/MemberInvite" }>
								<MemberInvite userData={ props.userData } />
							</Route>
						:
							null
					}

					{ ( props.memberData.permission === "A" )
						?
							<Route path={ "/component/MemberInviteComplete" }>
								<MemberInviteComplete />
							</Route>
						:
							null
					}

					{ ( props.memberData.permission === "A" )
						?
							<Route path={ "/component/MemberInviteConfirm" }>
								<MemberInviteConfirm userData={ props.userData } />
							</Route>
						:
							null
					}

					{ ( props.memberData.permission === "A" )
						?
							<Route path={ "/component/MemberList" }>
								<MemberList userData={ props.userData } />
							</Route>
						:
							null
					}

					<Route path={ "/component/Schedule" }>
						<Schedule userData={ props.userData } />
					</Route>

					<Route path={ "/component/TickerAdd" }>
						<TickerAdd />
					</Route>

					<Route path={ "/component/TickerAddConfirm" }>
						<TickerAddConfirm />
					</Route>

					<Route path={ "/component/TickerAddComplete" }>
						<TickerAddComplete />
					</Route>

					<Route path={ "/component/TickerDeleteComplete" }>
						<TickerDeleteComplete />
					</Route>

					<Route path={ "/component/TickerList" }>
						<TickerList userData={ props.userData } />
					</Route>

					<Route path={ "/component/TickerPageAdd" }>
						<TickerPageAdd />
					</Route>

					<Route path={ "/component/TickerPageAddComplete/:id" }>
						<TickerPageAddComplete />
					</Route>

					<Route path={ "/component/TickerPageAddConfirm" }>
						<TickerPageAddConfirm />
					</Route>

					<Route path={ "/component/TickerPageDeleteComplete/:id" }>
						<TickerPageDeleteComplete />
					</Route>

					<Route path={ "/component/TickerPageEdit" }>
						<TickerPageEdit />
					</Route>

					<Route path={ "/component/TickerPageEditComplete/:id" }>
						<TickerPageEditComplete />
					</Route>

					<Route path={ "/component/TickerPageEditConfirm" }>
						<TickerPageEditConfirm />
					</Route>

					<Route path={ "/component/TickerPageList/:id" }>
						<TickerPageList userData={ props.userData } />
					</Route>

					<Route path={ "/component/TopicSort" }>
						<TopicSort userData={ props.userData } />
					</Route>

					<Route path={ "/component/TopicSortComplete" }>
						<TopicSortComplete />
					</Route>

					<Route path={ "/component/DebugTool" }>
						<DebugTool />
					</Route>

					<Route>
						<Top userData={ props.userData } memberData={ props.memberData } />
					</Route>

				</Switch>
			</div>

		</div>
	);
}

export default Frame;
