import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import { createEtc3001CynageInvite, updateEtc3001CynageInvite, deleteEtc3001CynageInvite } from "../graphql/mutations";

import * as common from "./common";
import * as database from "./database";

import TopNav from "./TopNav";

import {
	Alert,
	Breadcrumb,
	Button,
	Container,
	Modal,
	Table
} from "react-bootstrap";


// Style Sheet =================================================================

import "./css/common.css";


// Component ===================================================================

function MemberCertificationConfirm ()
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "MemberCertificationConfirm > MemberCertificationConfirm () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "MemberCertificationConfirm > MemberCertificationConfirm () location : " + JSON.stringify( location ) );
	//console.log( "MemberCertificationConfirm > MemberCertificationConfirm () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ tableList, setTableList ] = useState( null );
	const [ showInfoModal, setShowInfoModal ] = useState( false );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [メンバー招待認証(確認)]";

		// リストの更新
		setTableList( buildTableList( location.state.inviteList ) );
	}, [] );

	// メンバー招待認証一覧へ戻る処理
	const goBackPrevious = function ( event )
	{
		// クエリパラメータ作成
		const param = {
			pathname: "/component/MemberCertificationList",
			//search: "",
			//hash: "",
			state: {
				inviteList: location.state.inviteList		// ※ リストを戻してはいるが、現状受け取っていない
			}
		};
		// 遷移
		history.push( param );
	};

	// 招待認証処理
	const certifyInvite = async function ( event )
	{
		//console.dir( event );

		// 招待キューデータリスト(配列)を基に招待キューデータを更新
		for ( const value of location.state.inviteList )
		{
			//console.log( "MemberCertificationConfirm > certifyInvite () value : " + JSON.stringify( value ) );

			// 認証する場合
			// 認証状態:
			// 	承認:
			// 		isApproved = true;
			// 		isProcessed = false;
			// 	否認:
			// 		isApproved = false;
			// 		isProcessed = true;
			// 	保留:
			// 		isApproved = false;
			// 		isProcessed = false;
			if ( ( ( value.isApproved ) || ( value.isApproved === "true" ) )
				|| ( ( value.isProcessed ) || ( value.isProcessed === "true" ) ) )
			{
				// 招待キューデータ生成
				const input = {
					id: value.id,
					invitedDate: value.invitedDate,
					permission: value.permission,
					isApproved: value.isApproved,
					isProcessed: value.isProcessed
				};
				const response = await database.mutationUpdate( updateEtc3001CynageInvite, input );

				//console.log( "MemberCertificationConfirm > certifyInvite () response : " + JSON.stringify( response ) );

				// 招待キューデータの生成に成功した場合
				if ( response )
				{
					;
				}
				// 招待キューデータの生成に失敗した場合
				else
				{
					console.error( "MemberCertificationConfirm > certifyInvite () member certify error. mailAddress : " + value.mailAddress );
				}
			}
		}

		// メンバー異動履歴(招待)追加
		// ※ Cognito のユーザー追加、および、MemberTransferHistory(招待)データの追加(要sub)は、AWS-SDK(要AWSアカウント情報)が必要となってしまうので、バッチ側にお任せ

		// 遷移
		history.push( "/component/MemberCertificationComplete" );
	};

	// リスト生成処理
	const buildTableList = function ( list )
	{
		// 表組み行生成
		const result = list.map( function ( value, index )
		{
			//console.log( "MemberCertificationConfirm > buildTableList () list[ " + index + " ] : " + JSON.stringify( value ) );

			// 招待された日時(UTC)をJSTに変換
			const invitedDate = common.getJSTDateTime( value.invitedDate, "YYYY/MM/DD" );

			return (
				<tr
					key={ index }
				>

					{/* 招待日 */}
					<td>
						{ invitedDate }
					</td>

					{/* メールアドレス */}
					<td>
						{ value.mailAddress }
					</td>

					{/* 認証(承認／否認／保留) */}
					<td>
						{
							( function ()
							{
								if ( ( ( value.isApproved ) || ( value.isApproved === "true" ) ) && ( ( !value.isProcessed ) || ( value.isProcessed === "false" ) ) ) return "承認";
								else if ( ( ( !value.isApproved ) || ( value.isApproved === "false" ) ) && ( ( value.isProcessed ) || ( value.isProcessed === "true" ) ) ) return "否認";
								else if ( ( ( !value.isApproved ) || ( value.isApproved === "false" ) ) && ( ( !value.isProcessed ) || ( value.isProcessed === "false" ) ) ) return "保留";
							} )()
						}
					</td>

					{/* 利用権限 */}
					<td>
						{
							( function ()
							{
								if ( value.permission === "N" ) return "一般";
								else if ( value.permission === "D" ) return "編集者";
								else if ( value.permission === "A" ) return "管理者";
							} )()
						}
					</td>

					{/* 招待者 */}
					<td>
						{ value.inviterName }
					</td>

				</tr>
			);
		} );

		//console.dir( result );

		return result;
	};

	// 情報モーダルを閉じるイベントハンドラ
	const handleCloseInfoModal = () => setShowInfoModal( false );

	// 情報モーダルを表示するイベントハンドラ
	const handleShowInfoModal = () => setShowInfoModal( true );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/" ) }>TOP</Breadcrumb.Item>
					<Breadcrumb.Item active>招待認証 (確認)</Breadcrumb.Item>
				</Breadcrumb>

				{/* インフォメーションボタン */}
				<button onClick={ handleShowInfoModal } className="no_border_button info_link text-primary">
					<span className="material-icons-outlined info_icon">info</span>
				</button>

				{/* インフォメーションダイアログ */}
				<Modal show={ showInfoModal } onHide={ handleCloseInfoModal }>
					<Modal.Header className="text-white bg-primary">
						<Modal.Title>
							<span className="material-icons-outlined modal_info_icon">info</span>
							TIPS
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span>
							このページでは、メンバー招待の認証ができます。
						</span>

						<div className="modal_action_btn_group">
							<Button variant="secondary" onClick={ handleCloseInfoModal }>閉じる</Button>
						</div>
					</Modal.Body>
				</Modal>

				<TopNav />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<h4 className="mb-4">
					招待者の承認 ／ 否認 ／ 保留 (確認)
				</h4>

				{/* プログレストラッカー */}
				<Alert className="progress_tracker mb-4">
					<div>招待認証</div>
					<div className="active">確認</div>
				</Alert>

				<p>
					招待の承認または否認を確認してください。
				</p>

				<Table striped bordered hover>
					<thead>
						<tr>
							<th className="text-center" style={{ width: "100px" }}>
								招待日
							</th>
							<th className="text-center">
								メールアドレス
							</th>
							<th className="text-center" style={{ width: "140px" }}>
								承認／否認／保留
							</th>
							<th className="text-center" style={{ width: "80px" }}>
								権限
							</th>
							<th className="text-center" style={{ width: "150px" }}>
								招待者
							</th>
						</tr>
					</thead>
					<tbody>
						{ tableList }
					</tbody>
				</Table>

				<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
					{/* 戻るボタン */}
					<Button variant="secondary" onClick={ goBackPrevious }>戻る</Button>
					{/* 決定ボタン */}
					<Button onClick={ certifyInvite }>決定</Button>
				</div>

			</Container>

		</>
	);
}

export default MemberCertificationConfirm;
