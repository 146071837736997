import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import { getEtc3001CynageInvite, listEtc3001CynageInvites } from "../graphql/queries";
import { getEtc3001CynageInformation, listEtc3001CynageInformations } from "../graphql/queries";

import * as common from "./common";

import TopNav from "./TopNav";

import {
	Badge,
	Button,
	Breadcrumb,
	Card,
	Container,
	Modal
} from "react-bootstrap";


// Component ===================================================================

function Top ( props )
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "Top > Top () props : " + JSON.stringify( props ) );
	//console.log( "Top > Top () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "Top > Top () location : " + JSON.stringify( location ) );
	//console.log( "Top > Top () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ showInfoModal, setShowInfoModal ] = useState( false );
	const [ unCertification, setUnCertification ] = useState();
	const [ unRead, setUnRead ] = useState();

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [トップ]";

		( async function ()
		{
			// 組織データの取得に成功した場合
			if ( props.userData.domain )
			{
				// 招待キューデータリスト(配列)取得(非同期)
				const inviteList = await common.getInviteArray( listEtc3001CynageInvites, props.userData.domain.id, false, false );

				//console.log( "Top > useEffect () inviteList : " + JSON.stringify( inviteList ) );
				//console.dir( inviteList );

				// 招待キューデータの取得に成功した場合
				if ( inviteList )
				{
					// 未認証件数の反映
					setUnCertification( inviteList.length );
				}
				// 招待キューデータの取得に失敗した場合
				else
				{
					console.error( "Top > useEffect () invite get list error." );
				}

				// お知らせデータリスト(配列)取得(非同期)
				const informationList = await common.getInformationArray( listEtc3001CynageInformations );

				//console.log( "Top > useEffect () informationList : " + JSON.stringify( informationList ) );

				// お知らせデータの取得に成功した場合
				if ( informationList )
				{
					// 既読お知らせIDリスト(localStorage)取得
					const readedInformationList = common.getReadedInformationList( informationList );

					// 未読件数の反映
					setUnRead( informationList.length - readedInformationList.length );
				}
				// お知らせデータの取得に失敗した場合
				else
				{
					console.error( "Top > useEffect () information get list error." );
				}
			}
			// 組織データの取得に失敗した場合
			else
			{
				alert( "not belong cognito:group error. : " + JSON.stringify( props.userData.user.attributes.email ) );
			}
		} )();
	}, [] );

	// 情報モーダルを閉じるイベントハンドラ
	const handleCloseInfoModal = () => setShowInfoModal( false );

	// 情報モーダルを表示するイベントハンドラ
	const handleShowInfoModal = () => setShowInfoModal( true );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item active>TOP</Breadcrumb.Item>
				</Breadcrumb>

				{/* インフォメーションボタン */}
				<button onClick={ handleShowInfoModal } className="no_border_button info_link text-primary">
					<span className="material-icons-outlined info_icon">info</span>
				</button>

				{/* インフォメーションダイアログ */}
				<Modal show={ showInfoModal } onHide={ handleCloseInfoModal }>
					<Modal.Header className="text-white bg-primary">
						<Modal.Title>
							<span className="material-icons-outlined modal_info_icon">info</span>
							TIPS
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span>
							このページでは、お知らせが表示されます。
						</span>

						<div className="modal_action_btn_group">
							<Button variant="secondary" onClick={ handleCloseInfoModal }>閉じる</Button>
						</div>
					</Modal.Body>
				</Modal>

				<TopNav userData={ props.userData } />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				{ ( props.memberData.permission === "A" )
					?
						<Link to="/component/MemberCertificationList" className="bg_link d-block" style={{ maxWidth: '500px' }}>
							<Card>
								<Card.Body className="d-flex justify-content-between align-items-center" style={{ height: '80px' }}>
									招待者の承認 ／ 否認 ／ 保留
									{ unCertification > 0 &&
										<Badge bg="secondary">{ unCertification }</Badge>
									}
								</Card.Body>
							</Card>
						</Link>
					:
						null
				}

				<Link to="/component/InformationList" className="bg_link d-block" style={{ maxWidth: '500px' }}>
					<Card style={{ maxWidth: '500px' }}>
						<Card.Body className="d-flex justify-content-between align-items-center" style={{ height: '80px' }}>
							CYnAGE 事務局からのお知らせ
							{ unRead > 0 &&
								<Badge bg="secondary">{ unRead }</Badge>
							}
						</Card.Body>
					</Card>
				</Link>

			</Container>

		</>
	);
}

export default Top;
