import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import * as common from "./common";

import TopNav from "./TopNav";

import {
	Alert,
	Breadcrumb,
	Button,
	Container
} from "react-bootstrap";


// Component ===================================================================

function TickerAddComplete ()
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "TickerAddComplete > TickerAddComplete () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "TickerAddComplete > TickerAddComplete () location : " + JSON.stringify( location ) );
	//console.log( "TickerAddComplete > TickerAddComplete () params : " + JSON.stringify( params ) );

	// ページ番号を確保
	const page = ( location.state && location.state.page ) ? location.state.page : 0;

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [トピックス(新規追加完了)]";
	}, [] );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/TickerList" ) }>トピックス</Breadcrumb.Item>
					{ ( 0 < page ) ? <Breadcrumb.Item active>...</Breadcrumb.Item> : "" }
					<Breadcrumb.Item active>新規作成 (完了)</Breadcrumb.Item>
				</Breadcrumb>

				<TopNav />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<h4 className="mb-4">
					トピックス新規作成 (完了)
				</h4>

				{/* プログレストラッカー */}
				<Alert className="progress_tracker mb-4">
					<div>No. (新規)-1</div>
					<div>確認</div>
					<div className="active">完了</div>
				</Alert>

				<div className="mt-5">
					<span className="material-icons success_icon">check_circle_outline</span>
				</div>

				<p className="text-center">
					トピックスを保存しました。
				</p>

				<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
					<Button variant="secondary" onClick={ () => history.push( "/component/TickerList" ) }>トピックス一覧</Button>
				</div>

			</Container>

		</>
	);
}

export default TickerAddComplete;
