import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
// 	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import {
//	AmplifyAuthenticator,
//	AmplifySignIn,
//	AmplifyForgotPassword,
//	AmplifySignUp,
//	AmplifySignOut,
	withAuthenticator
} from "@aws-amplify/ui-react";

import { getEtc3001CynageDomain, listEtc3001CynageDomains } from "../graphql/queries";

import * as common from "./common";

import {
	NavDropdown
} from "react-bootstrap";


// Component ===================================================================

function TopNav ( props )
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "TopNav > TopNav () props : " + JSON.stringify( props ) );
	//console.log( "TopNav > TopNav () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "TopNav > TopNav () location : " + JSON.stringify( location ) );
	//console.log( "TopNav > TopNav () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ loginUserEmail, setLoginUserEmail ] = useState( null );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		( async function ()
		{
			// ユーザデータの取得に成功した場合、ユーザーデータ確保
			// ユーザデータの取得に失敗した場合、ユーザーデータ取得(非同期)
			const userData = ( props.userData ) ? props.userData : await common.getUserData( getEtc3001CynageDomain );

			//console.log( "TopNav > useEffect () userData : " + JSON.stringify( userData ) );
			//console.dir( userData );

			// ユーザデータの取得に成功した場合
			if ( userData )
			{
				// ログインユーザーメールアドレスの更新
				setLoginUserEmail( userData.user.attributes.email );
			}
			// ユーザデータの取得に失敗した場合
			else
			{
				alert( "not belong cognito:group error. : " + JSON.stringify( userData.user.attributes.email ) );
			}
		} )();
	}, [] );

	return (
		<NavDropdown className="top_nav" title={
			<span className="material-icons">account_circle</span>
		}>
			<NavDropdown.ItemText className="login_user">ログインユーザ<br />{ loginUserEmail }</NavDropdown.ItemText>
			<NavDropdown.Divider />
			<NavDropdown.Item className="text-primary" href="#" onClick={ async () => await common.signOut( history ) }>ログアウト</NavDropdown.Item>
		</NavDropdown>
	);
}

export default withAuthenticator( TopNav );
