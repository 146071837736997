import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import { getEtc3001CynageInformation, listEtc3001CynageInformations } from "../graphql/queries";

import * as common from "./common";

import { Paging } from "./ReactBootstrapPagination";

import TopNav from "./TopNav";

import {
	Breadcrumb,
	Button,
	Col,
	Container,
	Modal,
	Row,
	Table
} from "react-bootstrap";


// define const ================================================================

// 1ページあたりの表示メンバー数
const PAGING_LIMIT = 10;

// NEW!表示日数
const NEW_DISPLAY_DAY = 7;


// Global Variable =============================================================

// お知らせデータリスト(配列)
let informationList = [];

// 既読お知らせIDリスト(localStorage)
let readedInformationList = [];


// Component ===================================================================

function InformationList ()
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "InformationList > InformationList () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "InformationList > InformationList () location : " + JSON.stringify( location ) );
	//console.log( "InformationList > InformationList () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ tableList, setTableList ] = useState( null );
	const [ showInfoModal, setShowInfoModal ] = useState( false );
	const [ currentPage, setCurrentPage ] = useState( 1 );
	const [ totalPage, setTotalPage ] = useState( 0 );
	const [ unRead, setUnRead ] = useState();

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [お知らせ(一覧)]";

		( async function ()
		{
			// お知らせデータリスト(配列)取得(非同期)
			informationList = await common.getInformationArray( listEtc3001CynageInformations );

			//console.log( "InformationList > useEffect () informationList : " + JSON.stringify( informationList ) );

			// お知らせデータの取得に成功した場合
			if ( informationList )
			{
				// 既読お知らせIDリスト(localStorage)取得
				readedInformationList = common.getReadedInformationList( informationList );

				// 未読件数の反映
				setUnRead( informationList.length - readedInformationList.length );

				// 現在のページ初期化
				setCurrentPage( 1 );

				// リストの更新
				setTableList( buildTableList( informationList, 1 ) );

				// 【表示ページング】総ページ数の算出 (小数点以下切り上げ)
				const total = common.calcTotalPage( PAGING_LIMIT, informationList.length );
				setTotalPage( total );
			}
			// お知らせデータの取得に失敗した場合
			else
			{
				console.error( "InformationList > useEffect () information get list error." );
			}
		} )();
	}, [] );

	// 該当IDのお知らせが既読か否か確認
	const isReaded = function ( id )
	{
		const result = readedInformationList.find( function ( value )
		{
			return id === value;
		} );

		return result;
	}

	// お知らせ詳細へ遷移
	const handleLink = function ( event, data )
	{
		// ページ遷移(リロード)動作をキャンセル
		event.preventDefault();

		//console.log( "InformationList > handleLink () data : " + JSON.stringify( data ) );

		// 未読のお知らせか否か確認
		// 保存に成功した場合
		if ( !isReaded( data.id ) )
		{
			// 選択したお知らせIDを既読お知らせIDリストに追加
			readedInformationList.push( data.id );

			// 既読お知らせIDリスト(localStorage)保存
			common.setReadedInformationList( readedInformationList );
		}

		// クエリパラメータ作成
		const param = {
			//pathname: "/component/InformationDetail",		// これでもOKだが
			pathname: "/component/InformationDetail/" + data.id,		// これなら<a>タグlinkとvisitedの色違いの表現が可能(ルーティングのパスパラメータは無しのまま)
			//search: "",
			//hash: "",
			state: {
				id: data.id,
				infoDate: data.infoDate,
				subject: data.subject,
				body: data.body
			}
		};
		// 遷移
		history.push( param );
	};

	// ページ更新
	const updatePage = function ( page )
	{
		// 現在のページ更新
		setCurrentPage( page );

		// リストの更新
		setTableList( buildTableList( informationList, page ) );
	};

	// リスト生成処理
	const buildTableList = function ( list, page )
	{
		//console.log( "InformationList > buildTableList () page : " + page );

		// 【表示ページング】現ページの表示データオフセット算出
		const { start, end } = common.calcPagingOffset( PAGING_LIMIT, page );

		//console.log( "InformationList > buildTableList () start : " + start );
		//console.log( "InformationList > buildTableList () end : " + end );

		// 表組み行生成
		const result = list.slice( start, end ).map( function ( value, index )
		{
			//console.log( "InformationList > buildTableList () list[ " + index + " ] : " + JSON.stringify( value ) );

			// 経過日数算出
			const diffDay = common.calcDiffDay( value.infoDate );

			//console.log( "InformationList > buildTableList () diffDay : " + diffDay );

			// 既読のお知らせか否か確認
			// 未読か既読で下記お知らせ件名のテキスト色を指定
			const fontColor = ( isReaded( value.id ) ) ? "#e4b276" : "#b48246";

			return (
				<tr
					key={ index }
				>

					{/* お知らせ日 */}
					<td style={{ width: "100px" }}>
						{ common.getJSTDateTime( value.infoDate, "YYYY/MM/DD" ) }
					</td>
					{/* NEW!表示 */}
					<td className="text-center" style={{ width: "60px", color: "#f00" }}>
						{ ( diffDay <= NEW_DISPLAY_DAY ) ? "NEW!" : null }
					</td>
					{/* お知らせ件名 */}
					<td>
						<Link
							to={ "/component/InformationDetail/" + value.id }
							onClick={ ( event ) => handleLink( event, value ) }
							className="information_link"
							style={{ color: fontColor }}
						>
							{ value.subject }
						</Link>
					</td>

				</tr>
			);
		} );

		//console.dir( result );

		return result;
	};

	// 情報モーダルを閉じるイベントハンドラ
	const handleCloseInfoModal = () => setShowInfoModal( false );

	// 情報モーダルを表示するイベントハンドラ
	const handleShowInfoModal = () => setShowInfoModal( true );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/" ) }>TOP</Breadcrumb.Item>
					<Breadcrumb.Item active>お知らせ</Breadcrumb.Item>
				</Breadcrumb>

				{/* インフォメーションボタン */}
				<button onClick={ handleShowInfoModal } className="no_border_button info_link text-primary">
					<span className="material-icons-outlined info_icon">info</span>
				</button>

				{/* インフォメーションダイアログ */}
				<Modal show={ showInfoModal } onHide={ handleCloseInfoModal }>
					<Modal.Header className="text-white bg-primary">
						<Modal.Title>
							<span className="material-icons-outlined modal_info_icon">info</span>
							TIPS
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span>
							このページでは、CYnAGE 事務局からのお知らせ一覧が表示されます。
						</span>

						<div className="modal_action_btn_group">
							<Button variant="secondary" onClick={ handleCloseInfoModal }>閉じる</Button>
						</div>
					</Modal.Body>
				</Modal>

				<TopNav />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<h4 className="mb-4">
					CYnAGE 事務局からのお知らせ
				</h4>

				{/* 未読数表示 */}
				<div className="mb-1">
					未読 { unRead } 件
				</div>

				<Table striped bordered hover>
					<tbody>
						{ tableList }
					</tbody>
				</Table>

				{/* 【表示ページング】 */}
				<Row>
					<Col className="mb-2 d-flex justify-content-center">
						<Paging currentPage={ currentPage } totalPage={ totalPage } onClick={ updatePage } />
					</Col>
				</Row>

			</Container>

		</>
	);
}

export default InformationList;
