/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEtc3001CynageDomain = /* GraphQL */ `
  query GetEtc3001CynageDomain($id: ID!) {
    getEtc3001CynageDomain(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listEtc3001CynageDomains = /* GraphQL */ `
  query ListEtc3001CynageDomains(
    $filter: ModelEtc3001CynageDomainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEtc3001CynageDomains(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEtc3001CynageSchedule = /* GraphQL */ `
  query GetEtc3001CynageSchedule($id: ID!) {
    getEtc3001CynageSchedule(id: $id) {
      id
      targetDate
      signageID
      signage {
        id
        number
        name
        description
        tickers {
          items {
            signageID
            signage {
              id
              number
              name
              description
              tickers {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            tickerID
            ticker {
              id
              number
              name
              mainContents
              subContents
              signages {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              displayStartDate
              displayEndDate
              limitDate
              memberAmount
              clickAmount
              completionAmount
              completionRate
              isDeleted
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEtc3001CynageSchedules = /* GraphQL */ `
  query ListEtc3001CynageSchedules(
    $filter: ModelEtc3001CynageScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEtc3001CynageSchedules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetDate
        signageID
        signage {
          id
          number
          name
          description
          tickers {
            items {
              signageID
              signage {
                id
                number
                name
                description
                domainID
                createdAt
                updatedAt
              }
              tickerID
              ticker {
                id
                number
                name
                mainContents
                subContents
                domainID
                displayStartDate
                displayEndDate
                limitDate
                memberAmount
                clickAmount
                completionAmount
                completionRate
                isDeleted
                createdAt
                updatedAt
              }
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          domainID
          domain {
            id
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEtc3001CynageSignage = /* GraphQL */ `
  query GetEtc3001CynageSignage($id: ID!) {
    getEtc3001CynageSignage(id: $id) {
      id
      number
      name
      description
      tickers {
        items {
          signageID
          signage {
            id
            number
            name
            description
            tickers {
              items {
                signageID
                tickerID
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            domainID
            domain {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          tickerID
          ticker {
            id
            number
            name
            mainContents
            subContents
            signages {
              items {
                signageID
                tickerID
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            domainID
            domain {
              id
              name
              createdAt
              updatedAt
            }
            displayStartDate
            displayEndDate
            limitDate
            memberAmount
            clickAmount
            completionAmount
            completionRate
            isDeleted
            createdAt
            updatedAt
          }
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEtc3001CynageSignages = /* GraphQL */ `
  query ListEtc3001CynageSignages(
    $filter: ModelEtc3001CynageSignageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEtc3001CynageSignages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        name
        description
        tickers {
          items {
            signageID
            signage {
              id
              number
              name
              description
              tickers {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            tickerID
            ticker {
              id
              number
              name
              mainContents
              subContents
              signages {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              displayStartDate
              displayEndDate
              limitDate
              memberAmount
              clickAmount
              completionAmount
              completionRate
              isDeleted
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEtc3001CynageSignageTicker = /* GraphQL */ `
  query GetEtc3001CynageSignageTicker($signageID: ID!, $order: Int!) {
    getEtc3001CynageSignageTicker(signageID: $signageID, order: $order) {
      signageID
      signage {
        id
        number
        name
        description
        tickers {
          items {
            signageID
            signage {
              id
              number
              name
              description
              tickers {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            tickerID
            ticker {
              id
              number
              name
              mainContents
              subContents
              signages {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              displayStartDate
              displayEndDate
              limitDate
              memberAmount
              clickAmount
              completionAmount
              completionRate
              isDeleted
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      tickerID
      ticker {
        id
        number
        name
        mainContents
        subContents
        signages {
          items {
            signageID
            signage {
              id
              number
              name
              description
              tickers {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            tickerID
            ticker {
              id
              number
              name
              mainContents
              subContents
              signages {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              displayStartDate
              displayEndDate
              limitDate
              memberAmount
              clickAmount
              completionAmount
              completionRate
              isDeleted
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        displayStartDate
        displayEndDate
        limitDate
        memberAmount
        clickAmount
        completionAmount
        completionRate
        isDeleted
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const listEtc3001CynageSignageTickers = /* GraphQL */ `
  query ListEtc3001CynageSignageTickers(
    $signageID: ID
    $order: ModelIntKeyConditionInput
    $filter: ModelEtc3001CynageSignageTickerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEtc3001CynageSignageTickers(
      signageID: $signageID
      order: $order
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        signageID
        signage {
          id
          number
          name
          description
          tickers {
            items {
              signageID
              signage {
                id
                number
                name
                description
                domainID
                createdAt
                updatedAt
              }
              tickerID
              ticker {
                id
                number
                name
                mainContents
                subContents
                domainID
                displayStartDate
                displayEndDate
                limitDate
                memberAmount
                clickAmount
                completionAmount
                completionRate
                isDeleted
                createdAt
                updatedAt
              }
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          domainID
          domain {
            id
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        tickerID
        ticker {
          id
          number
          name
          mainContents
          subContents
          signages {
            items {
              signageID
              signage {
                id
                number
                name
                description
                domainID
                createdAt
                updatedAt
              }
              tickerID
              ticker {
                id
                number
                name
                mainContents
                subContents
                domainID
                displayStartDate
                displayEndDate
                limitDate
                memberAmount
                clickAmount
                completionAmount
                completionRate
                isDeleted
                createdAt
                updatedAt
              }
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          domainID
          domain {
            id
            name
            createdAt
            updatedAt
          }
          displayStartDate
          displayEndDate
          limitDate
          memberAmount
          clickAmount
          completionAmount
          completionRate
          isDeleted
          createdAt
          updatedAt
        }
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEtc3001CynageTicker = /* GraphQL */ `
  query GetEtc3001CynageTicker($id: ID!) {
    getEtc3001CynageTicker(id: $id) {
      id
      number
      name
      mainContents
      subContents
      signages {
        items {
          signageID
          signage {
            id
            number
            name
            description
            tickers {
              items {
                signageID
                tickerID
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            domainID
            domain {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          tickerID
          ticker {
            id
            number
            name
            mainContents
            subContents
            signages {
              items {
                signageID
                tickerID
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            domainID
            domain {
              id
              name
              createdAt
              updatedAt
            }
            displayStartDate
            displayEndDate
            limitDate
            memberAmount
            clickAmount
            completionAmount
            completionRate
            isDeleted
            createdAt
            updatedAt
          }
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      displayStartDate
      displayEndDate
      limitDate
      memberAmount
      clickAmount
      completionAmount
      completionRate
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const listEtc3001CynageTickers = /* GraphQL */ `
  query ListEtc3001CynageTickers(
    $filter: ModelEtc3001CynageTickerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEtc3001CynageTickers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        name
        mainContents
        subContents
        signages {
          items {
            signageID
            signage {
              id
              number
              name
              description
              tickers {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            tickerID
            ticker {
              id
              number
              name
              mainContents
              subContents
              signages {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              displayStartDate
              displayEndDate
              limitDate
              memberAmount
              clickAmount
              completionAmount
              completionRate
              isDeleted
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        displayStartDate
        displayEndDate
        limitDate
        memberAmount
        clickAmount
        completionAmount
        completionRate
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEtc3001CynageLinkList = /* GraphQL */ `
  query GetEtc3001CynageLinkList($id: ID!) {
    getEtc3001CynageLinkList(id: $id) {
      id
      order
      name
      link
      icon
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEtc3001CynageLinkLists = /* GraphQL */ `
  query ListEtc3001CynageLinkLists(
    $filter: ModelEtc3001CynageLinkListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEtc3001CynageLinkLists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        order
        name
        link
        icon
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEtc3001CynageMember = /* GraphQL */ `
  query GetEtc3001CynageMember($id: String!, $domainID: ID!) {
    getEtc3001CynageMember(id: $id, domainID: $domainID) {
      id
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      mailAddress
      isDisabled
      isOnLeave
      invitedDate
      approvedDate
      familyName
      firstName
      department
      jobTitle
      position
      permission
      lastLoginDate
      loginCountWithinMonth
      promptedList
      completeList
      disabledDate
      isCognitoDeleted
      createdAt
      updatedAt
    }
  }
`;
export const listEtc3001CynageMembers = /* GraphQL */ `
  query ListEtc3001CynageMembers(
    $id: String
    $domainID: ModelIDKeyConditionInput
    $filter: ModelEtc3001CynageMemberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEtc3001CynageMembers(
      id: $id
      domainID: $domainID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        mailAddress
        isDisabled
        isOnLeave
        invitedDate
        approvedDate
        familyName
        firstName
        department
        jobTitle
        position
        permission
        lastLoginDate
        loginCountWithinMonth
        promptedList
        completeList
        disabledDate
        isCognitoDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEtc3001CynageAmount = /* GraphQL */ `
  query GetEtc3001CynageAmount($aggregationMonth: String!, $domainID: ID!) {
    getEtc3001CynageAmount(
      aggregationMonth: $aggregationMonth
      domainID: $domainID
    ) {
      aggregationMonth
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      memberAmount
      memberActive
      topicAmount
      completionRate
      createdAt
      updatedAt
    }
  }
`;
export const listEtc3001CynageAmounts = /* GraphQL */ `
  query ListEtc3001CynageAmounts(
    $aggregationMonth: String
    $domainID: ModelIDKeyConditionInput
    $filter: ModelEtc3001CynageAmountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEtc3001CynageAmounts(
      aggregationMonth: $aggregationMonth
      domainID: $domainID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        aggregationMonth
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        memberAmount
        memberActive
        topicAmount
        completionRate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEtc3001CynageMemberTransferHistory = /* GraphQL */ `
  query GetEtc3001CynageMemberTransferHistory(
    $domainID: ID!
    $transferDate: AWSDateTime!
  ) {
    getEtc3001CynageMemberTransferHistory(
      domainID: $domainID
      transferDate: $transferDate
    ) {
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      transferDate
      inviteList
      retireList
      onLeaveList
      returnList
      createdAt
      updatedAt
    }
  }
`;
export const listEtc3001CynageMemberTransferHistorys = /* GraphQL */ `
  query ListEtc3001CynageMemberTransferHistorys(
    $domainID: ID
    $transferDate: ModelStringKeyConditionInput
    $filter: ModelEtc3001CynageMemberTransferHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEtc3001CynageMemberTransferHistorys(
      domainID: $domainID
      transferDate: $transferDate
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        transferDate
        inviteList
        retireList
        onLeaveList
        returnList
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEtc3001CynageMemberLoginHistory = /* GraphQL */ `
  query GetEtc3001CynageMemberLoginHistory(
    $memberID: String!
    $domainIDMonth: String!
  ) {
    getEtc3001CynageMemberLoginHistory(
      memberID: $memberID
      domainIDMonth: $domainIDMonth
    ) {
      memberID
      domainIDMonth
      targetDay
      createdAt
      updatedAt
    }
  }
`;
export const listEtc3001CynageMemberLoginHistorys = /* GraphQL */ `
  query ListEtc3001CynageMemberLoginHistorys(
    $memberID: String
    $domainIDMonth: ModelStringKeyConditionInput
    $filter: ModelEtc3001CynageMemberLoginHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEtc3001CynageMemberLoginHistorys(
      memberID: $memberID
      domainIDMonth: $domainIDMonth
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        memberID
        domainIDMonth
        targetDay
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEtc3001CynageTopicClickHistory = /* GraphQL */ `
  query GetEtc3001CynageTopicClickHistory(
    $memberID: String!
    $domainTopicID: String!
  ) {
    getEtc3001CynageTopicClickHistory(
      memberID: $memberID
      domainTopicID: $domainTopicID
    ) {
      memberID
      domainTopicID
      clickDate
      createdAt
      updatedAt
    }
  }
`;
export const listEtc3001CynageTopicClickHistorys = /* GraphQL */ `
  query ListEtc3001CynageTopicClickHistorys(
    $memberID: String
    $domainTopicID: ModelStringKeyConditionInput
    $filter: ModelEtc3001CynageTopicClickHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEtc3001CynageTopicClickHistorys(
      memberID: $memberID
      domainTopicID: $domainTopicID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        memberID
        domainTopicID
        clickDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEtc3001CynageTopicCompleteHistory = /* GraphQL */ `
  query GetEtc3001CynageTopicCompleteHistory(
    $memberID: String!
    $topicID: String!
  ) {
    getEtc3001CynageTopicCompleteHistory(
      memberID: $memberID
      topicID: $topicID
    ) {
      memberID
      topicID
      domainID
      completeDate
      createdAt
      updatedAt
    }
  }
`;
export const listEtc3001CynageTopicCompleteHistorys = /* GraphQL */ `
  query ListEtc3001CynageTopicCompleteHistorys(
    $memberID: String
    $topicID: ModelStringKeyConditionInput
    $filter: ModelEtc3001CynageTopicCompleteHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEtc3001CynageTopicCompleteHistorys(
      memberID: $memberID
      topicID: $topicID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        memberID
        topicID
        domainID
        completeDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEtc3001CynagePrompt = /* GraphQL */ `
  query GetEtc3001CynagePrompt($id: ID!) {
    getEtc3001CynagePrompt(id: $id) {
      id
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      topicID
      memberList
      subject
      body
      createdAt
      updatedAt
    }
  }
`;
export const listEtc3001CynagePrompts = /* GraphQL */ `
  query ListEtc3001CynagePrompts(
    $id: ID
    $filter: ModelEtc3001CynagePromptFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEtc3001CynagePrompts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        topicID
        memberList
        subject
        body
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEtc3001CynageInvite = /* GraphQL */ `
  query GetEtc3001CynageInvite($id: ID!) {
    getEtc3001CynageInvite(id: $id) {
      id
      invitedDate
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      mailAddress
      permission
      isApproved
      isProcessed
      inviter
      createdAt
      updatedAt
    }
  }
`;
export const listEtc3001CynageInvites = /* GraphQL */ `
  query ListEtc3001CynageInvites(
    $id: ID
    $filter: ModelEtc3001CynageInviteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEtc3001CynageInvites(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        invitedDate
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        mailAddress
        permission
        isApproved
        isProcessed
        inviter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEtc3001CynageInformation = /* GraphQL */ `
  query GetEtc3001CynageInformation($id: ID!, $infoDate: AWSDateTime!) {
    getEtc3001CynageInformation(id: $id, infoDate: $infoDate) {
      id
      infoDate
      subject
      body
      createdAt
      updatedAt
    }
  }
`;
export const listEtc3001CynageInformations = /* GraphQL */ `
  query ListEtc3001CynageInformations(
    $id: ID
    $infoDate: ModelStringKeyConditionInput
    $filter: ModelEtc3001CynageInformationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEtc3001CynageInformations(
      id: $id
      infoDate: $infoDate
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        infoDate
        subject
        body
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEtc3001CynageMemberActHistory = /* GraphQL */ `
  query GetEtc3001CynageMemberActHistory(
    $aggregationMonth: String!
    $memberID: String!
  ) {
    getEtc3001CynageMemberActHistory(
      aggregationMonth: $aggregationMonth
      memberID: $memberID
    ) {
      aggregationMonth
      memberID
      member {
        id
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        mailAddress
        isDisabled
        isOnLeave
        invitedDate
        approvedDate
        familyName
        firstName
        department
        jobTitle
        position
        permission
        lastLoginDate
        loginCountWithinMonth
        promptedList
        completeList
        disabledDate
        isCognitoDeleted
        createdAt
        updatedAt
      }
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      loginCountWithinMonth
      incompleteCount
      createdAt
      updatedAt
    }
  }
`;
export const listEtc3001CynageMemberActHistorys = /* GraphQL */ `
  query ListEtc3001CynageMemberActHistorys(
    $aggregationMonth: String
    $memberID: ModelStringKeyConditionInput
    $filter: ModelEtc3001CynageMemberActHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEtc3001CynageMemberActHistorys(
      aggregationMonth: $aggregationMonth
      memberID: $memberID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        aggregationMonth
        memberID
        member {
          id
          domainID
          domain {
            id
            name
            createdAt
            updatedAt
          }
          mailAddress
          isDisabled
          isOnLeave
          invitedDate
          approvedDate
          familyName
          firstName
          department
          jobTitle
          position
          permission
          lastLoginDate
          loginCountWithinMonth
          promptedList
          completeList
          disabledDate
          isCognitoDeleted
          createdAt
          updatedAt
        }
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        loginCountWithinMonth
        incompleteCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEtc3001CynageTopicOrder = /* GraphQL */ `
  query GetEtc3001CynageTopicOrder($domainID: ID!) {
    getEtc3001CynageTopicOrder(domainID: $domainID) {
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      topicOrder
      createdAt
      updatedAt
    }
  }
`;
export const listEtc3001CynageTopicOrders = /* GraphQL */ `
  query ListEtc3001CynageTopicOrders(
    $domainID: ID
    $filter: ModelEtc3001CynageTopicOrderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEtc3001CynageTopicOrders(
      domainID: $domainID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        topicOrder
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEtc3001CynageSequence = /* GraphQL */ `
  query GetEtc3001CynageSequence($domainID: ID!, $name: String!) {
    getEtc3001CynageSequence(domainID: $domainID, name: $name) {
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const listEtc3001CynageSequences = /* GraphQL */ `
  query ListEtc3001CynageSequences(
    $domainID: ID
    $name: ModelStringKeyConditionInput
    $filter: ModelEtc3001CynageSequenceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEtc3001CynageSequences(
      domainID: $domainID
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        name
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const etc3001CynageTickerForDisplayEnd = /* GraphQL */ `
  query Etc3001CynageTickerForDisplayEnd(
    $domainID: ID
    $displayEndDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEtc3001CynageTickerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    Etc3001CynageTickerForDisplayEnd(
      domainID: $domainID
      displayEndDate: $displayEndDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        name
        mainContents
        subContents
        signages {
          items {
            signageID
            signage {
              id
              number
              name
              description
              tickers {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            tickerID
            ticker {
              id
              number
              name
              mainContents
              subContents
              signages {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              displayStartDate
              displayEndDate
              limitDate
              memberAmount
              clickAmount
              completionAmount
              completionRate
              isDeleted
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        displayStartDate
        displayEndDate
        limitDate
        memberAmount
        clickAmount
        completionAmount
        completionRate
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const etc3001CynageMemberForMemberCleanup = /* GraphQL */ `
  query Etc3001CynageMemberForMemberCleanup(
    $isDisabled: String
    $sortDirection: ModelSortDirection
    $filter: ModelEtc3001CynageMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    Etc3001CynageMemberForMemberCleanup(
      isDisabled: $isDisabled
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        mailAddress
        isDisabled
        isOnLeave
        invitedDate
        approvedDate
        familyName
        firstName
        department
        jobTitle
        position
        permission
        lastLoginDate
        loginCountWithinMonth
        promptedList
        completeList
        disabledDate
        isCognitoDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const etc3001CynageMemberForDomainID = /* GraphQL */ `
  query Etc3001CynageMemberForDomainID(
    $domainID: ID
    $id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEtc3001CynageMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    Etc3001CynageMemberForDomainID(
      domainID: $domainID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        mailAddress
        isDisabled
        isOnLeave
        invitedDate
        approvedDate
        familyName
        firstName
        department
        jobTitle
        position
        permission
        lastLoginDate
        loginCountWithinMonth
        promptedList
        completeList
        disabledDate
        isCognitoDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const etc3001CynageMemberLoginHistoryForQuery = /* GraphQL */ `
  query Etc3001CynageMemberLoginHistoryForQuery(
    $targetDay: String
    $domainIDMonth: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEtc3001CynageMemberLoginHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    Etc3001CynageMemberLoginHistoryForQuery(
      targetDay: $targetDay
      domainIDMonth: $domainIDMonth
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        memberID
        domainIDMonth
        targetDay
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const etc3001CynageTopicClickHistoryForQuery = /* GraphQL */ `
  query Etc3001CynageTopicClickHistoryForQuery(
    $clickDate: String
    $domainTopicID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEtc3001CynageTopicClickHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    Etc3001CynageTopicClickHistoryForQuery(
      clickDate: $clickDate
      domainTopicID: $domainTopicID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        memberID
        domainTopicID
        clickDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const etc3001CynageTopicCompleteHistoryForQuery = /* GraphQL */ `
  query Etc3001CynageTopicCompleteHistoryForQuery(
    $completeDate: String
    $domainID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEtc3001CynageTopicCompleteHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    Etc3001CynageTopicCompleteHistoryForQuery(
      completeDate: $completeDate
      domainID: $domainID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        memberID
        topicID
        domainID
        completeDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const etc3001CynageTopicCompleteHistoryForQuery2 = /* GraphQL */ `
  query Etc3001CynageTopicCompleteHistoryForQuery2(
    $domainID: String
    $topicID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEtc3001CynageTopicCompleteHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    Etc3001CynageTopicCompleteHistoryForQuery2(
      domainID: $domainID
      topicID: $topicID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        memberID
        topicID
        domainID
        completeDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const etc3001CynageInviteForQueue = /* GraphQL */ `
  query Etc3001CynageInviteForQueue(
    $isProcessed: String
    $invitedDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEtc3001CynageInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    Etc3001CynageInviteForQueue(
      isProcessed: $isProcessed
      invitedDate: $invitedDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invitedDate
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        mailAddress
        permission
        isApproved
        isProcessed
        inviter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
