import { Pagination } from "react-bootstrap";

/**
 * React Bootstrap ページング処理コンポーネント
 *
 * @param {
 * 	{number} currentPage	現在のページ
 * 	{number} totalPage		総ページ数の算出
 * 	{function} onClick		クリック時コールバック
 * }
 *
 * @return {object}	<Pagination>
 *
 * @cite: https://zenn.dev/toshiokun/articles/2ff73de5dafc76
 */
export const Paging = function ( {
	currentPage,
	totalPage,
	onClick
} )
{
	let displayPages = [];
	let items = [];

	for ( let i = 1; i <= totalPage; i++ )
	{
		if (
			( i === 1 ) ||
			( i === totalPage ) ||
			( ( currentPage - 2 <= i ) && ( i <= currentPage + 2 ) )
		)
		{
			displayPages.push( i );
		}
	}

	let previousPage;

	// 先頭ページ
	const onFirst = function ()
	{
		if ( 1 < currentPage ) onClick( 1 );
	};
	// 前ページ
	const onPrev = function ()
	{
		if ( 1 < currentPage ) onClick( currentPage - 1 );
	};
	// 次ページ
	const onNext = function ()
	{
		if ( currentPage < totalPage ) onClick( currentPage + 1 );
	};
	// 最終ページ
	const onLast = function ()
	{
		if ( currentPage < totalPage ) onClick( totalPage );
	};

	for ( const page of displayPages )
	{
		if ( previousPage )
		{
			if ( page - previousPage === 2 )
			{
				items.push(
					<Pagination.Item
						key={ previousPage + 1 }
						active={ previousPage + 1 === currentPage }
						onClick={ function ()
						{
							onClick( this.previousPage + 1 );
						} }
					>
						{ previousPage + 1 }
					</Pagination.Item>
				);
			}
			else if ( 2 < page - previousPage )
			{
				// 「…」(三点リーダ)追加
				items.push( <Pagination.Ellipsis key={ previousPage + 1 } /> );
			}
		}

		items.push(
			<Pagination.Item
				key={ page }
				active={ page === currentPage }
				onClick={ function ()
				{
					onClick( page );
				} }
			>
				{ page }
			</Pagination.Item>
		);

		previousPage = page;
	}

	//console.dir( items );

	return (
		<Pagination>
			{/* 先頭ページ */}
			<Pagination.First onClick={ onFirst } />
			{/* 前ページ */}
			<Pagination.Prev onClick={ onPrev } />
			{/* ページリスト */}
			{ items }
			{/* 次ページ */}
			<Pagination.Next onClick={ onNext } />
			{/* 最終ページ */}
			<Pagination.Last onClick={ onLast } />
		</Pagination>
	);
};
