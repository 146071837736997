import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import { getEtc3001CynageMember, listEtc3001CynageMembers } from "../graphql/queries";

import * as common from "./common";

import TopNav from "./TopNav";

import {
	Alert,
	Breadcrumb,
	Button,
	Container,
	Form,
	Modal
} from "react-bootstrap";


// Component ===================================================================

function MemberInvite ( props )
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "MemberInvite > MemberInvite () props : " + JSON.stringify( props ) );
	//console.log( "MemberInvite > MemberInvite () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "MemberInvite > MemberInvite () location : " + JSON.stringify( location ) );
	//console.log( "MemberInvite > MemberInvite () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const inputTextEmailList = useRef();
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ showInfoModal, setShowInfoModal ] = useState( false );
	const [ validated, setValidated ] = useState( false );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [メンバー招待]";
	}, [] );

	// メンバー一覧へ戻る処理
	const goBackPrevious = function ( event )
	{
		// 遷移
		history.push( "/component/MemberList" );
	};

	// 送信処理
	const handleForm = async function ( event )
	{
		// バリデーションチェック
		const form = event.currentTarget;

		setValidated( true );

		// ページ遷移(リロード)動作をキャンセル
		event.preventDefault();

		// メールアドレスリストが入力されている場合
		if ( 0 < inputTextEmailList.current.value.length )
		{
			// 招待メールアドレスリスト解析
			const list = common.parseEmailList( inputTextEmailList.current.value );

			//console.log( "MemberInvite > handleForm () list.length : " + JSON.stringify( list.length ) );

			if ( 0 < list.length )
			{
				// メンバーデータリスト(配列)取得(非同期)
				const memberList = await common.getMemberArray( listEtc3001CynageMembers, props.userData.domain.id );

				//console.log( "MemberInvite > handleForm () memberList : " + JSON.stringify( memberList ) );
				//console.dir( memberList );

				// メールアドレスリスト解析
				for ( let index = 0; index < list.length; index++ )
				{
					//console.log( "MemberInvite > handleForm () list[ " + index + " ] : " + JSON.stringify( list[ index ] ) );

					// メールアドレスバリデーション ----------------------------
					// メールアドレスとして扱える場合
					if ( common.isMailAddress( list[ index ].mailAddress ) )
					{
						//console.log( "MemberInvite > handleForm () legal email address : " + JSON.stringify( list[ index ].mailAddress ) );
					}
					// メールアドレスとして扱えない場合
					else
					{
						//console.log( "MemberInvite > handleForm () illegal email address : " + JSON.stringify( list[ index ].mailAddress ) );

						alert( ( index + 1 ) + " 行目の「 " + list[ index ].mailAddress + " 」は、メールアドレスとして扱うことができません。" );

						// 関数から抜け、以降の処理を避ける
						return;
					}

					// Cognito ユーザーデータ不在バリデーション ----------------
					// 同じメールアドレス、且つ、Cognito ユーザーデータが存在する、メンバーデータを絞り込み
					const existCognitoMembers = memberList.filter( function ( value )
					{
						return (
							// 同じメールアドレス
							( value.mailAddress === list[ index ].mailAddress )
							// Cognito ユーザーデータが存在する
							&& ( ( !value.isCognitoDeleted ) || ( value.isCognitoDeleted === "false" ) )
						);
					} );

					//console.log( "MemberInvite > handleForm () existCognitoMembers : " + JSON.stringify( existCognitoMembers ) );

					// Cognito ユーザーデータが不在である場合
					if ( existCognitoMembers.length <= 0 )
					{
						//console.log( "MemberInvite > handleForm () not exist Cognito user : " + JSON.stringify( list[ index ].mailAddress ) );
					}
					// Cognito ユーザーデータが存在する場合
					else
					{
						//console.log( "MemberInvite > handleForm () exist Cognito user : " + JSON.stringify( list[ index ].mailAddress ) );

						alert( ( index + 1 ) + " 行目のメールアドレス「 " + list[ index ].mailAddress + " 」の Cognito ユーザーは、既に存在しています。" );

						// 関数から抜け、以降の処理を避ける
						return;
					}

					// 利用権限バリデーション ----------------------------------
					// 利用権限の指定が有る場合
					if ( list[ index ].permission )
					{
						// 利用権限として扱える場合
						if ( ( list[ index ].permission.toUpperCase() === "N" )
							|| ( list[ index ].permission.toUpperCase() === "D" )
							|| ( list[ index ].permission.toUpperCase() === "A" ) )
						{
							//console.log( "MemberInvite > handleForm () permission : " + JSON.stringify( list[ index ].permission ) );
						}
						// 利用権限として扱えない場合
						else
						{
							//console.log( "MemberInvite > handleForm () not permission : " + JSON.stringify( list[ index ].permission ) );

							alert( ( index + 1 ) + " 行目の " + list[ index ].mailAddress + " の「 " + list[ index ].permission + " 」は、利用権限として扱うことができません。" );

							// 関数から抜け、以降の処理を避ける
							return;
						}
					}
				}

				// バリデーションを通過時の処理
				if ( form.checkValidity() )
				{
					// クエリパラメータ作成
					const param = {
						pathname: "/component/MemberInviteConfirm",
						//search: "",
						//hash: "",
						state: {
							emailList: inputTextEmailList.current.value
						}
					};
					// 遷移
					history.push( param );
				}
			}
			else
			{
				alert( "メールアドレスを入力してください。" );
			}
		}
	};

	// 情報モーダルを閉じるイベントハンドラ
	const handleCloseInfoModal = () => setShowInfoModal( false );

	// 情報モーダルを表示するイベントハンドラ
	const handleShowInfoModal = () => setShowInfoModal( true );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/MemberList" ) }>メンバー</Breadcrumb.Item>
					<Breadcrumb.Item active>招待</Breadcrumb.Item>
				</Breadcrumb>

				{/* インフォメーションボタン */}
				<button onClick={ handleShowInfoModal } className="no_border_button info_link text-primary">
					<span className="material-icons-outlined info_icon">info</span>
				</button>

				{/* インフォメーションダイアログ */}
				<Modal show={ showInfoModal } onHide={ handleCloseInfoModal }>
					<Modal.Header className="text-white bg-primary">
						<Modal.Title>
							<span className="material-icons-outlined modal_info_icon">info</span>
							TIPS
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span>
							このページでは、メンバーの招待ができます。
						</span>

						<div className="modal_action_btn_group">
							<Button variant="secondary" onClick={ handleCloseInfoModal }>閉じる</Button>
						</div>
					</Modal.Body>
				</Modal>

				<TopNav />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container pl-3">

				<h4 className="mb-4">
					招待送信先
				</h4>

				<Form noValidate validated={ validated } onSubmit={ handleForm }>

					{/* プログレストラッカー */}
					<Alert className="progress_tracker mb-4">
						<div className="active">招待(入力)</div>
					</Alert>

					<Form.Group className="mb-3" controlId="validationTextName">
						<Form.Label>
							<p>
								招待したいメンバーのメールアドレスを入力してください。(1件1行) *<br />
								管理ツールの権限も付与したいメンバーには<br />
								メールアドレスの後ろに「,」を入力し、続けて権限を指定してください。
							</p>
							<span>
								入力例
							</span>
							<Alert className="pt-1 pb-1 alert_highlight">
								sato@example.com,A<br />
								suzuki@example.com,D<br />
								takahashi@example.com
							</Alert>
							<p>
								※管理者: <span className="text_highlight">A</span>　編集者: <span className="text_highlight">D</span>　管理ツール利用不可: 指定なし<br />
							</p>
						</Form.Label>
						<Form.Control as="textarea" ref={ inputTextEmailList } rows="10" placeholder="emailList" defaultValue={ location.state.emailList } required />
						<Form.Control.Feedback type="invalid">
							メールアドレスリストを入力してください。
						</Form.Control.Feedback>
					</Form.Group>

					<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
						{/* 戻るボタン */}
						<Button variant="secondary" onClick={ goBackPrevious }>戻る</Button>
						{/* 確認ボタン */}
						<Button type="submit">確認</Button>
					</div>

				</Form>

			</Container>

		</>
	);
}

export default MemberInvite;
