import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import { createEtc3001CynageMember, updateEtc3001CynageMember, deleteEtc3001CynageMember } from "../graphql/mutations";
import { getEtc3001CynageMember, listEtc3001CynageMembers } from "../graphql/queries";
import { createEtc3001CynageMemberTransferHistory, updateEtc3001CynageMemberTransferHistory, deleteEtc3001CynageMemberTransferHistory } from "../graphql/mutations";

import * as common from "./common";
import * as database from "./database";

import TopNav from "./TopNav";

import {
	Alert,
	Breadcrumb,
	Button,
	Col,
	Container,
	Modal,
	Row,
	Table
} from "react-bootstrap";


// Component ===================================================================

function MemberDeleteConfirm ( props )
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "MemberDeleteConfirm > MemberDeleteConfirm () props : " + JSON.stringify( props ) );
	//console.log( "MemberDeleteConfirm > MemberDeleteConfirm () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "MemberDeleteConfirm > MemberDeleteConfirm () location : " + JSON.stringify( location ) );
	//console.log( "MemberDeleteConfirm > MemberDeleteConfirm () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ tableList, setTableList ] = useState( null );
	const [ showInfoModal, setShowInfoModal ] = useState( false );
	const [ deleteMemberDisabled, setDeleteMemberDisabled ] = useState( false );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [メンバー削除(確認)]";

		( async function ()
		{
			// 組織データの取得に成功した場合
			if ( props.userData.domain )
			{
				// 削除メンバーリスト初期化
				const memberList = [];

				for ( const value of location.state.deleteList )
				{
					//console.log( "MemberDeleteConfirm > useEffect () value : " + JSON.stringify( value ) );

					// メンバーデータ取得
					const member = await common.getMember( getEtc3001CynageMember, value, props.userData.domain.id );

					//console.log( "MemberDeleteConfirm > useEffect () member : " + JSON.stringify( member ) );

					memberList.push( member );
				}

				// リストの更新
				setTableList( buildTableList( memberList ) );
			}
			// 組織データの取得に失敗した場合
			else
			{
				alert( "not belong cognito:group error. : " + JSON.stringify( props.userData.user.attributes.email ) );
			}
		} )();
	}, [] );

	// メンバー一覧へ戻る処理
	const goBackPrevious = function ( event )
	{
		// 遷移
		history.push( "/component/MemberList" );
	};

	// メンバー削除(更新)処理
	// ※ 非同期の重い処理が含まれているので、<form onSubmit={ deleteMember }>では処理不可(ページ遷移が先行しエラーとなる)
	const deleteMember = async function ( event )
	{
		// データを削除(更新)中でない場合
		if ( !deleteMemberDisabled )
		{
			// 削除ボタンの無効化
			setDeleteMemberDisabled( true );

			// 現在日時の取得
			const now = common.getUTCDateTime();

			//console.log( "MemberDeleteConfirm > deleteMember () now : " + JSON.stringify( now ) );

			// 削除メンバーリスト(配列)を基にメンバーデータを削除(更新)
			for ( const value of location.state.deleteList )
			{
				//console.log( "MemberDeleteConfirm > deleteMember () value : " + JSON.stringify( value ) );

				// メンバーデータ削除(更新)
				const input = {
					id: value,
					domainID: props.userData.domain.id,
					isDisabled: true,
					disabledDate: now
				};
				const response = await database.mutationUpdate( updateEtc3001CynageMember, input );

				//console.log( "MemberDeleteConfirm > deleteMember () response : " + JSON.stringify( response ) );

				// メンバーデータの削除(更新)に成功した場合
				if ( response )
				{
					;
				}
				// メンバーデータの削除(更新)に失敗した場合
				else
				{
					console.error( "MemberDeleteConfirm > deleteMember () member delete(update) error. id : " + value );

					// 関数から抜け、以降の処理を避ける
					return;
				}
			}

			// メンバー異動履歴(退職)追加
			// ※ Cognito のユーザー削除は、AWS-SDK(要AWSアカウント情報)が必要となってしまうので、バッチ側にお任せ
			const response = await common.createMemberTransferHistory(
				createEtc3001CynageMemberTransferHistory,
				props.userData.domain.id,
				undefined,
				location.state.deleteList
			);

			//console.log( "MemberDeleteConfirm > deleteMember () response : " + JSON.stringify( response ) );

			// データ追加に成功した場合
			if ( response )
			{
				// 遷移
				history.push( "/component/MemberDeleteComplete" );
			}
			// データ追加に失敗した場合
			else
			{
				console.error( "MemberDeleteConfirm > deleteMember () member transfer history create error. deleteList : " + location.state.deleteList );
			}
		}
	};

	// リスト生成処理
	const buildTableList = function ( list )
	{
		const result = list.map( function ( value, index )
		{
			//console.log( "MemberDeleteConfirm > buildTableList () list[ " + index + " ] : " + JSON.stringify( value ) );

			return (
				<tr
					key={ index }
				>

					{/* 氏名 */}
					<td>
						{ common.getFullName( value.familyName, value.firstName ) }
					</td>

					{/* 利用権限 */}
					<td>
						{
							( function ()
							{
								if ( value.permission === "N" ) return "一般";
								else if ( value.permission === "D" ) return "編集者";
								else if ( value.permission === "A" ) return "管理者";
							} )()
						}
					</td>

					{/* 所属部署 */}
					<td>
						{ value.department }
					</td>

					{/* 職種 */}
					<td>
						{ value.jobTitle }
					</td>

					{/* 役職 */}
					<td>
						{ value.position }
					</td>

					{/* メールアドレス */}
					<td>
						{ value.mailAddress }
					</td>

				</tr>
			);
		} );

		//console.dir( result );

		return result;
	};

	// 情報モーダルを閉じるイベントハンドラ
	const handleCloseInfoModal = () => setShowInfoModal( false );

	// 情報モーダルを表示するイベントハンドラ
	const handleShowInfoModal = () => setShowInfoModal( true );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/MemberList" ) }>メンバー</Breadcrumb.Item>
					<Breadcrumb.Item active>メンバー削除 (確認)</Breadcrumb.Item>
				</Breadcrumb>

				{/* インフォメーションボタン */}
				<button onClick={ handleShowInfoModal } className="no_border_button info_link text-primary">
					<span className="material-icons-outlined info_icon">info</span>
				</button>

				{/* インフォメーションダイアログ */}
				<Modal show={ showInfoModal } onHide={ handleCloseInfoModal }>
					<Modal.Header className="text-white bg-primary">
						<Modal.Title>
							<span className="material-icons-outlined modal_info_icon">info</span>
							TIPS
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span>
							このページでは、メンバーの削除ができます。
						</span>

						<div className="modal_action_btn_group">
							<Button variant="secondary" onClick={ handleCloseInfoModal }>閉じる</Button>
						</div>
					</Modal.Body>
				</Modal>

				<TopNav userData={ props.userData } />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<h4 className="mb-4">
					メンバー削除 (確認)
				</h4>

				{/* プログレストラッカー */}
				<Alert className="progress_tracker mb-4">
					<div className="active">削除確認</div>
				</Alert>

				<Row>
					<Col>
						下記のメンバーを削除します。<br />
						よろしいですか？
					</Col>
				</Row>

				<Table striped bordered hover>
					<thead>
						<tr>
							<th className="text-center" style={{ width: "150px" }}>
								氏名
							</th>
							<th className="text-center" style={{ width: "80px" }}>
								権限
							</th>
							<th className="text-center" style={{ width: "150px" }}>
								所属部署
							</th>
							<th className="text-center" style={{ width: "150px" }}>
								職種
							</th>
							<th className="text-center" style={{ width: "80px" }}>
								役職
							</th>
							<th className="text-center">
								メールアドレス
							</th>
						</tr>
					</thead>
					<tbody>
						{ tableList }
					</tbody>
				</Table>

				<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
					{/* 戻るボタン */}
					<Button variant="secondary" onClick={ goBackPrevious }>戻る</Button>
					{/* 削除ボタン */}
					<Button onClick={ deleteMember } disabled={ deleteMemberDisabled }>削除</Button>
				</div>

			</Container>

		</>
	);
}

export default MemberDeleteConfirm;
