import Amplify, { Auth, API, graphqlOperation } from "aws-amplify";


// function ====================================================================

/**
 * データレコード生成(非同期)処理
 *
 * @param {object} object		ミューテーション生成オブジェクト
 * @param {object} input		生成内容
 * @param {object} condition	@ToDo: 要確認
 *
 * @return {object}	処理成功時:データレコード内容|処理失敗時:null
 */
export const mutationCreate = async function ( object, input, condition = undefined )
{
	//console.log( "database > mutationCreate () object : " + JSON.stringify( object ) );
	console.log( "database > mutationCreate () input : " + JSON.stringify( input ) );
	console.log( "database > mutationCreate () condition : " + JSON.stringify( condition ) );

	let result = null;

	// サーバー通信処理 (DynamoDB)
	await API.graphql( graphqlOperation( object, { input: input, condition: condition } ) )
		.then( ( response ) =>
		{
			result = response;
		} )
		.catch( ( error ) =>
		{
			console.error( "database > mutationCreate () : graphqlOperation error. : " + JSON.stringify( error ) );
		} );

	//console.log( "database > mutationCreate () : result : " + JSON.stringify( result ) );
	console.dir( result );

	if ( ( result ) && ( result.data ) ) return Object.values( result.data )[ 0 ];
	return null;
};

/**
 * データレコード更新(非同期)処理
 *
 * @param {object} object		ミューテーション更新オブジェクト
 * @param {object} input		更新内容
 * @param {object} condition	@ToDo: 要確認
 * @param {string} add			ADD したい場合のカラム名
 *
 * @return {object}	処理成功時:データレコード内容|処理失敗時:null
 */
export const mutationUpdate = async function ( object, input, condition = undefined, add = undefined )
{
	//console.log( "database > mutationUpdate () object : " + JSON.stringify( object ) );
	console.log( "database > mutationUpdate () input : " + JSON.stringify( input ) );
	console.log( "database > mutationUpdate () condition : " + JSON.stringify( condition ) );

	let result = null;

	// サーバー通信処理 (DynamoDB)
	await API.graphql( graphqlOperation( object, { input: input, condition: condition, add: add } ) )
		.then( ( response ) =>
		{
			result = response;
		} )
		.catch( ( error ) =>
		{
			console.error( "database > mutationUpdate () : graphqlOperation error. : " + JSON.stringify( error ) );
		} );

	//console.log( "database > mutationUpdate () : result : " + JSON.stringify( result ) );
	console.dir( result );

	if ( ( result ) && ( result.data ) ) return Object.values( result.data )[ 0 ];
	return null;
};

/**
 * データレコード削除(非同期)処理
 *
 * @param {object} object		ミューテーション削除オブジェクト
 * @param {object} input		指定内容
 * @param {object} condition	その他条件
 *
 * @return {object}	処理成功時:データレコード内容|処理失敗時:null
 */
export const mutationDelete = async function ( object, input, condition = undefined )
{
	//console.log( "database > mutationDelete () object : " + JSON.stringify( object ) );
	console.log( "database > mutationDelete () input : " + JSON.stringify( input ) );
	console.log( "database > mutationDelete () condition : " + JSON.stringify( condition ) );

	let result = null;

	// サーバー通信処理 (DynamoDB)
	await API.graphql( graphqlOperation( object, { input: input, condition: condition } ) )
		.then( ( response ) =>
		{
			result = response;
		} )
		.catch( ( error ) =>
		{
			console.error( "database > mutationDelete () : graphqlOperation error. : " + JSON.stringify( error ) );
		} );

	//console.log( "database > mutationDelete () : result : " + JSON.stringify( result ) );
	console.dir( result );

	if ( ( result ) && ( result.data ) ) return Object.values( result.data )[ 0 ];
	return null;
};

/**
 * データレコード取得(非同期)処理
 *
 * @param {object} object		クエリ取得オブジェクト
 * @param {object} variables	取得条件
 *
 * @return {object}	データレコード
 */
export const queryGet = async function ( object, variables )
{
	//console.log( "database > queryGet () object : " + JSON.stringify( object ) );
	console.log( "database > queryGet () variables : " + JSON.stringify( variables ) );

	let result = null;

	// サーバー通信処理 (DynamoDB)
	await API.graphql( graphqlOperation( object, variables ) )
		.then( ( response ) =>
		{
			result = response;
		} )
		.catch( ( error ) =>
		{
			console.error( "database > queryGet () : graphqlOperation error. : " + JSON.stringify( error ) );
		} );

	//console.log( "database > queryGet () : result : " + JSON.stringify( result ) );
	console.dir( result );

	if ( ( result ) && ( result.data ) ) return Object.values( result.data )[ 0 ];
	return null;
};

/**
 * データレコードリスト取得(非同期)処理
 *
 * @param {object} object		クエリリスト取得オブジェクト
 * @param {object} filter		絞り込みフィルタ
 * @param {int}    limit		ページング取得件数 … 全件取得する場合、指定無し
 * @param {string} nextToken	ページング次ページトークン情報
 *
 * @return {*}	データレコードリスト(配列)やページング次ページトークン情報
 */
export const queryList = async function ( object, filter = undefined, limit = undefined, nextToken = undefined )
{
	//console.log( "database > queryList () object : " + JSON.stringify( object ) );
	console.log( "database > queryList () filter : " + JSON.stringify( filter ) );
	//console.log( "database > queryList () limit : " + JSON.stringify( limit ) );
	//console.log( "database > queryList () nextToken : " + JSON.stringify( nextToken ) );

	// ページング取得する場合
	if ( limit )
	{
		let result = null;

		// サーバー通信処理 (DynamoDB)
		await API.graphql( graphqlOperation( object, { filter: filter, limit: limit, nextToken: nextToken } ) )
			.then( ( response ) =>
			{
				result = response;
			} )
			.catch( ( error ) =>
			{
				console.error( "database > queryList () : graphqlOperation error. : " + JSON.stringify( error ) );
			} );

		//console.log( "database > queryList () : result : " + JSON.stringify( result ) );
		console.dir( result );

		return {
			items: Object.values( result.data )[ 0 ].items,
			nextToken: Object.values( result.data )[ 0 ].nextToken
		};
	}
	// 全件取得する場合
	else
	{
		let items = [];

		// 返される nextToken が null となるまでループ
		do
		{
			let result = null;		// ※ ループの外には置けないようだ

			// サーバー通信処理 (DynamoDB)
			await API.graphql( graphqlOperation( object, { filter: filter, limit: limit, nextToken: nextToken } ) )
				.then( ( response ) =>
				{
					result = response;
				} )
				.catch( ( error ) =>
				{
					console.error( "database > queryList () : graphqlOperation error. : " + JSON.stringify( error ) );
				} );

			//console.log( "database > queryList () : result : " + JSON.stringify( result ) );
			console.dir( result );

			// 取得したデータを確保
			items = items.concat( Object.values( result.data )[ 0 ].items );
			// 次の取得トークンを確保
			nextToken = Object.values( result.data )[ 0 ].nextToken;
		} while ( nextToken );

		return items;
	}
};
