import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import { createEtc3001CynageMember, updateEtc3001CynageMember, deleteEtc3001CynageMember } from "../graphql/mutations";
import { getEtc3001CynageMember, listEtc3001CynageMembers } from "../graphql/queries";
import { createEtc3001CynageMemberTransferHistory, updateEtc3001CynageMemberTransferHistory, deleteEtc3001CynageMemberTransferHistory } from "../graphql/mutations";

import * as common from "./common";
import * as database from "./database";

import { Paging } from "./ReactBootstrapPagination";

import TopNav from "./TopNav";

import {
	Breadcrumb,
	Button,
	Col,
	Container,
	Form,
	InputGroup,
	Modal,
	Row,
	Table
} from "react-bootstrap";


// Style Sheet =================================================================

import "./css/common.css";


// define const ================================================================

// 1ページあたりの表示メンバー数
const PAGING_LIMIT = 20;


// Global Variable =============================================================

// メンバーデータリスト(配列)
let memberList = [];
// メンバー表示リスト(配列)
let displayList = [];

// チェックボックスリスト(連想配列)
// @note: 想定される格納データは…
// 	チェックされた場合、true が入り、されていない場合、false が入る
// 	例) { "IDハッシュ":true, "IDハッシュ":false, "IDハッシュ":true, "IDハッシュ":false, "IDハッシュ":false, ... }
let checkBoxList = {};


// Component ===================================================================

function MemberList ( props )
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "MemberList > MemberList () props : " + JSON.stringify( props ) );
	//console.log( "MemberList > MemberList () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "MemberList > MemberList () location : " + JSON.stringify( location ) );
	//console.log( "MemberList > MemberList () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const inputTextSearch = useRef();
	const inputCheckMember = useRef( [] );
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ textRow, setTextRow ] = useState( 0 );
	const [ deleteAbsenceDisabled, setDeleteAbsenceDisabled ] = useState( true );
	const [ tableList, setTableList ] = useState( null );
	const [ showInfoModal, setShowInfoModal ] = useState( false );
	const [ currentPage, setCurrentPage ] = useState( 1 );
	const [ totalPage, setTotalPage ] = useState( 0 );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [メンバー(一覧)]";

		( async function ()
		{
			// 組織データの取得に成功した場合
			if ( props.userData.domain )
			{
				// メンバーデータリスト(配列)取得(非同期)
				memberList = await common.getMemberArray( listEtc3001CynageMembers, props.userData.domain.id );

				//console.log( "MemberList > useEffect () memberList : " + JSON.stringify( memberList ) );
				//console.dir( memberList );

				// メンバーデータの取得に成功した場合
				if ( memberList )
				{
					// 無効化されたメンバー以外に絞り込み
					// 配列要素絞り込み
					memberList = memberList.filter( function ( value )
					{
						// null である
						// undefined である
						// false (bool) である
						// "false" (string) である
						return ( ( !value.isDisabled ) || ( value.isDisabled === "false" ) );
					} );

					// メンバー表示リスト更新
					displayList = memberList;

					// 検索ヒット件数の更新
					setTextRow( displayList.length );

					// 現在のページ初期化
					setCurrentPage( 1 );

					// リストの更新
					setTableList( buildTableList( displayList, 1 ) );

					// 【表示ページング】総ページ数の算出 (小数点以下切り上げ)
					const total = common.calcTotalPage( PAGING_LIMIT, displayList.length );
					setTotalPage( total );
				}
				// メンバーデータの取得に失敗した場合
				else
				{
					console.error( "MemberList > useEffect () member get list error." );
				}
			}
			// 組織データの取得に失敗した場合
			else
			{
				alert( "not belong cognito:group error. : " + JSON.stringify( props.userData.user.attributes.email ) );
			}

			// チェックボックスリスト初期化
			checkBoxList = {};
		} )();
	}, [] );

	//console.log( "MemberList > useEffect () totalPage : " + totalPage );

	// 検索フォーム消去処理
	const clearTextSearch = function ()
	{
		inputTextSearch.current.value = "";
	};

	// メンバー検索処理
	// ※ React のフックを利用する為、コンポーネント内に配置
	const searchMember = function ( event )
	{
		//console.log( "MemberList > searchMember () inputTextSearch : " + JSON.stringify( inputTextSearch.current.value ) );

		// メンバー表示リスト氏名で絞り込み更新
		// 配列要素絞り込み
		displayList = memberList.filter( function ( value )
		{
			//console.log( "MemberList > searchMember () value : " + JSON.stringify( value ) );

			// フルネーム取得
			const fullName = common.getFullName( value.familyName, value.firstName );

			//console.log( "MemberList > searchMember () fullName : " + JSON.stringify( fullName ) );

			return (
				// (通常の)文字列内検索
				( 0 <= fullName.indexOf( inputTextSearch.current.value ) )
				|| ( 0 <= value.mailAddress.indexOf( inputTextSearch.current.value ) )
			);
		} );

		//console.log( "MemberList > searchMember () displayList : " + JSON.stringify( displayList ) );

		// 検索ヒット件数の更新
		setTextRow( displayList.length );

		// 現在のページ初期化
		setCurrentPage( 1 );

		// リストの更新
		setTableList( buildTableList( displayList, 1 ) );

		// 【表示ページング】総ページ数の算出 (小数点以下切り上げ)
		const total = common.calcTotalPage( PAGING_LIMIT, displayList.length );
		setTotalPage( total );
	};

	// メンバー招待
	const inviteMember = function ( event )
	{
		// クエリパラメータ作成
		const param = {
			pathname: "/component/MemberInvite",
			//search: "",
			//hash: "",
			state: {
				emailList: ""
			}
		};
		// 遷移
		history.push( param );
	};

	// メンバー削除処理
	const deleteMember = function ( event )
	{
		//console.log( "MemberList > deleteMember () checkBoxList : " + JSON.stringify( checkBoxList ) );

		// 削除・休職ボタンの無効化
		setDeleteAbsenceDisabled( true );

		// チェックされているキーのリストを作成
		let deleteList = [];
		for ( const key in checkBoxList )
		{
			// チェックされている場合
			if ( checkBoxList[ key ] )
			{
				//console.log( "MemberList > deleteMember () key : " + JSON.stringify( key ) );

				deleteList.push( key );
			}
		}

		//console.log( "MemberList > deleteMember () deleteList : " + JSON.stringify( deleteList ) );

		// クエリパラメータ作成
		const param = {
			pathname: "/component/MemberDeleteConfirm",
			//search: "",
			//hash: "",
			state: {
				deleteList: deleteList
			}
		};
		// 遷移
		history.push( param );
	};

	// メンバー休職処理
	const absenceMember = function ( event )
	{
		//console.log( "MemberList > absenceMember () checkBoxList : " + JSON.stringify( checkBoxList ) );

		// 削除・休職ボタンの無効化
		setDeleteAbsenceDisabled( true );

		// チェックされているキーのリストを作成
		let absenceList = [];
		for ( const key in checkBoxList )
		{
			// チェックされている場合
			if ( checkBoxList[ key ] )
			{
				//console.log( "MemberList > absenceMember () key : " + JSON.stringify( key ) );

				absenceList.push( key );
			}
		}

		//console.log( "MemberList > absenceMember () absenceList : " + JSON.stringify( absenceList ) );

		// クエリパラメータ作成
		const param = {
			pathname: "/component/MemberAbsenceConfirm",
			//search: "",
			//hash: "",
			state: {
				absenceList: absenceList
			}
		};
		// 遷移
		history.push( param );
	};

	// メンバー復職処理
	// ※ member は参照渡し
	const reinstateMember = async function ( event, member )
	{
		//console.dir( event );
		//console.log( "MemberList > reinstateMember () member : " + JSON.stringify( member ) );

		// 念の為、確認を入れて
		const confirm = window.confirm( "このメンバーを復職させますか？\n・" + common.getFullName( member.familyName, member.firstName ) + " (" + member.mailAddress + ")" );
		// 復職する場合
		if ( confirm )
		{
			// メンバーデータを復職処理(更新)
			const input = {
				id: member.id,
				domainID: member.domainID,
				isOnLeave: false
			};
			const response1 = await database.mutationUpdate( updateEtc3001CynageMember, input );

			//console.log( "MemberList > reinstateMember () response1 : " + JSON.stringify( response1 ) );

			// メンバーデータの更新に成功した場合
			if ( response1 )
			{
				// メンバー異動履歴(復職)追加
				const response2 = await common.createMemberTransferHistory(
					createEtc3001CynageMemberTransferHistory,
					member.domainID,
					undefined,
					undefined,
					undefined,
					[ member.id ]
				);

				//console.log( "MemberList > reinstateMember () response2 : " + JSON.stringify( response2 ) );

				// データ追加に成功した場合
				if ( response2 )
				{
					// 該当メンバーデータ(参照渡し)の更新
					member.isOnLeave = false;

					// 現在のページ初期化
					setCurrentPage( 1 );

					// リストの更新
					setTableList( buildTableList( displayList, 1 ) );
				}
				// データ追加に失敗した場合
				else
				{
					console.error( "MemberList > reinstateMember () member transfer history create error. member : " + member.id );
				}
			}
			// メンバーデータの更新に失敗した場合
			else
			{
				console.error( "MemberList > reinstateMember () member update error." );
			}
		}
	};

	// メンバー選択処理
	const selectMember = function ( event )
	{
		//console.dir( event );
		//console.log( "MemberList > selectMember () event.target.value : " + JSON.stringify( event.target.value ) );
		//console.log( "MemberList > selectMember () event.target.checked : " + JSON.stringify( event.target.checked ) );

		// 変更が有ったチェックボックスの状態を確保
		checkBoxList[ event.target.value ] = event.target.checked;

		//console.log( "MemberList > selectMember () checkBoxList : " + JSON.stringify( checkBoxList ) );

		// 選択されている行数を確認
		let count = 0;
		for ( const key in checkBoxList )		// 連想配列は iterable でないので for...in で
		{
			// チェックされている場合、カウント
			if ( checkBoxList[ key ] ) count++;
		}

		//console.log( "MemberList > selectMember () checkBoxList : count : " + count );

		// 削除・休職ボタンの有効/無効化
		setDeleteAbsenceDisabled( count === 0 );
	};

	// メンバー編集へ遷移
	const handleLink = function ( event, data )
	{
		// ページ遷移(リロード)動作をキャンセル
		event.preventDefault();

		//console.log( "MemberList > handleLink () data : " + JSON.stringify( data ) );

		// クエリパラメータ作成
		const param = {
			//pathname: "/component/MemberEdit",		// これでもOKだが
			pathname: "/component/MemberEdit/" + data.id,		// これなら<a>タグlinkとvisitedの色違いの表現が可能(ルーティングのパスパラメータは無しのまま)
			//search: "",
			//hash: "",
			state: {
				id: data.id,
				domainID: data.domainID,
				mailAddress: data.mailAddress,
				familyName: data.familyName,
				firstName: data.firstName,
				department: data.department,
				jobTitle: data.jobTitle,
				position: data.position,
				permission: data.permission
			}
		};
		// 遷移
		history.push( param );
	};

	// ページ更新
	const updatePage = function ( page )
	{
		// チェックボックスリスト初期化
		checkBoxList = {};

		// チェックボックスクリア
		for ( let index = 0; index < inputCheckMember.current.length; index++ )
		{
			inputCheckMember.current[ index ].current.checked = false;
		}

		// 削除・休職ボタンの無効化
		setDeleteAbsenceDisabled( true );

		// 現在のページ更新
		setCurrentPage( page );

		// リストの更新
		setTableList( buildTableList( displayList, page ) );
	};

	// リスト生成処理
	const buildTableList = function ( list, page )
	{
		//console.log( "MemberList > buildTableList () page : " + page );

		// 更新不要な DOM のフックの初期化
		inputCheckMember.current = [];

		// 【表示ページング】現ページの表示データオフセット算出
		const { start, end } = common.calcPagingOffset( PAGING_LIMIT, page );

		//console.log( "MemberList > buildTableList () start : " + start );
		//console.log( "MemberList > buildTableList () end : " + end );

		// 表組み行生成
		const result = list.slice( start, end ).map( function ( value, index )
		{
			//console.log( "MemberList > buildTableList () list[ " + index + " ] : " + JSON.stringify( value ) );

			// 更新不要な DOM のフックを作成
			inputCheckMember.current[ index ] = createRef();

			return (
				<tr
					key={ index }
				>

					{/* チェックボックス */}
					<td className="text-center">
						<Form.Check
							type="checkbox"
							className="d-flex justify-content-center"
							value={ value.id }
							ref={ inputCheckMember.current[ index ] }
							onChange={ ( event ) => selectMember( event ) }
							disabled={ ( value.id === props.userData.user.attributes.sub ) }
						/>
					</td>

					{/* 氏名 */}
					<td>
						<Link to={ "/component/MemberEdit/" + value.id } onClick={ ( event ) => handleLink( event, value ) }>
							{ common.getFullName( value.familyName, value.firstName ) }
						</Link>
					</td>

					{/* 利用権限 */}
					<td>
						{
							( function ()
							{
								if ( value.permission === "N" ) return "一般";
								else if ( value.permission === "D" ) return "編集者";
								else if ( value.permission === "A" ) return "管理者";
							} )()
						}
					</td>

					{/* 所属部署 */}
					<td>
						{ value.department }
					</td>

					{/* 職種 */}
					<td>
						{ value.jobTitle }
					</td>

					{/* 役職 */}
					<td>
						{ value.position }
					</td>

					{/* メールアドレス */}
					<td>
						{ value.mailAddress }
					</td>

					{/* 状態 */}
					<td>
						{
							( function ()
							{
								// もし無効化したメンバーの場合、エラー表示
								// true (bool) である
								// "true" (string) である
								if ( ( value.isDisabled === true ) || ( value.isDisabled === "true" ) ) return "Disabled";
								// 休職中のメンバーの場合、「復職」ボタンを表示
								else if ( ( value.isOnLeave === true ) || ( value.isOnLeave === "true" ) ) return <Button onClick={ async ( event, member ) => await reinstateMember( event, value ) }>復職</Button>;
								// その他のメンバーの場合
								else return "○";
							} )()
						}
					</td>

				</tr>
			);
		} );

		//console.dir( result );

		return result;
	};

	// 情報モーダルを閉じるイベントハンドラ
	const handleCloseInfoModal = () => setShowInfoModal( false );

	// 情報モーダルを表示するイベントハンドラ
	const handleShowInfoModal = () => setShowInfoModal( true );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/MemberList" ) }>メンバー</Breadcrumb.Item>
					<Breadcrumb.Item active>一覧</Breadcrumb.Item>
				</Breadcrumb>

				{/* インフォメーションボタン */}
				<button onClick={ handleShowInfoModal } className="no_border_button info_link text-primary">
					<span className="material-icons-outlined info_icon">info</span>
				</button>

				{/* インフォメーションダイアログ */}
				<Modal show={ showInfoModal } onHide={ handleCloseInfoModal }>
					<Modal.Header className="text-white bg-primary">
						<Modal.Title>
							<span className="material-icons-outlined modal_info_icon">info</span>
							TIPS
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span>
							このページでは、メンバーの一覧が表示されます。<br />
							<span className="text-danger">メンバー氏名をクリック</span>すると該当のメンバーを編集することができます。<br />
							<span className="text-danger">「メンバー招待」ボタン</span>からメンバーを招待することができます。<br />
							<span className="text-danger">「削除」ボタン</span>からメンバーを削除することができます。
						</span>

						<div className="modal_action_btn_group">
							<Button variant="secondary" onClick={ handleCloseInfoModal }>閉じる</Button>
						</div>
					</Modal.Body>
				</Modal>

				<TopNav userData={ props.userData } />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<Row>
					{ /* 検索フォーム */ }
					<Col xs style={{ maxWidth: "500px" }}>
						<InputGroup>
							<Form.Control
								className="search_text"
								type="text"
								placeholder="氏名もしくはメールアドレスを入力"
								ref={ inputTextSearch }
								onKeyPress={ ( event ) => common.checkKey( event, "Enter", searchMember ) }
							/>
							<span className="material-icons search_clear_button" onClick={ clearTextSearch }>close</span>
						</InputGroup>
					</Col>
					<Col xs="auto">
						<Button onClick={ searchMember }>検索</Button>
					</Col>
				</Row>

				<Row className="mt-3">
					{/* 総メンバー数表示 */}
					<Col className="mb-1 d-flex align-items-end">
						メンバー { textRow } 名
					</Col>
					{/* メンバー招待ボタン */}
					<Col className="mb-2 d-flex justify-content-end align-items-end action_btn_group">
						<Button onClick={ inviteMember }>メンバー招待</Button>
					</Col>
				</Row>

				<Table striped bordered hover>
					<thead>
						<tr>
							<th style={{ width: "40px" }}>
								{/* チェックボックス */}
							</th>
							<th className="text-center" style={{ width: "150px" }}>
								氏名
							</th>
							<th className="text-center" style={{ width: "80px" }}>
								権限
							</th>
							<th className="text-center" style={{ width: "150px" }}>
								所属部署
							</th>
							<th className="text-center" style={{ width: "150px" }}>
								職種
							</th>
							<th className="text-center" style={{ width: "80px" }}>
								役職
							</th>
							<th className="text-center">
								メールアドレス
							</th>
							<th className="text-center" style={{ width: "130px" }}>
								状態
							</th>
						</tr>
					</thead>
					<tbody>
						{ tableList }
					</tbody>
				</Table>

				{/* 【表示ページング】 */}
				<Row>
					<Col className="mb-2 d-flex justify-content-center">
						<Paging currentPage={ currentPage } totalPage={ totalPage } onClick={ updatePage } />
					</Col>
				</Row>

				<div className="mt-2 mb-2 d-flex justify-content-center action_btn_group">
					{/* 削除ボタン */}
					<Button onClick={ deleteMember } disabled={ deleteAbsenceDisabled }>削除</Button>
					{/* 休職ボタン */}
					<Button type="submit" onClick={ absenceMember } disabled={ deleteAbsenceDisabled }>休職</Button>
				</div>

			</Container>

		</>
	);
}

export default MemberList;
