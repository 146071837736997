import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import { createEtc3001CynageMember, updateEtc3001CynageMember, deleteEtc3001CynageMember } from "../graphql/mutations";

import * as common from "./common";
import * as database from "./database";

import TopNav from "./TopNav";

import {
	Alert,
	Breadcrumb,
	Button,
	Col,
	Container,
	Modal,
	Row
} from "react-bootstrap";


// Component ===================================================================

function MemberEditConfirm ()
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "MemberEditConfirm > MemberEditConfirm () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "MemberEditConfirm > MemberEditConfirm () location : " + JSON.stringify( location ) );
	//console.log( "MemberEditConfirm > MemberEditConfirm () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ showInfoModal, setShowInfoModal ] = useState( false );
	const [ editMemberDisabled, setEditMemberDisabled ] = useState( false );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [メンバープロフィール編集(確認)]";
	}, [] );

	// 編集ページへ戻る処理
	const goBackPrevious = function ( event )
	{
		// クエリパラメータ作成
		const param = {
			pathname: "/component/MemberEdit/" + location.state.id,
			//search: "",
			//hash: "",
			state: {
				id: location.state.id,
				domainID: location.state.domainID,
				mailAddress: location.state.mailAddress,
				familyName: location.state.familyName,
				firstName: location.state.firstName,
				department: location.state.department,
				jobTitle: location.state.jobTitle,
				position: location.state.position,
				permission: location.state.permission
			}
		};
		// 遷移
		history.push( param );
	};

	// メンバー更新処理
	// ※ 非同期の重い処理が含まれているので、<form onSubmit={ updateMember }>では処理不可(ページ遷移が先行しエラーとなる)
	const updateMember = async function ( event )
	{
		// データを更新中でない場合
		if ( !editMemberDisabled )
		{
			// 保存ボタンの無効化
			setEditMemberDisabled( true );

			// メンバーデータ更新
			const input = {
				id: location.state.id,
				domainID: location.state.domainID,
				mailAddress: location.state.mailAddress,
				familyName: location.state.familyName,
				firstName: location.state.firstName,
				department: location.state.department,
				jobTitle: location.state.jobTitle,
				position: location.state.position,
				permission: location.state.permission
			};
			const response = await database.mutationUpdate( updateEtc3001CynageMember, input );

			//console.log( "MemberEditConfirm > updateMember () response : " + JSON.stringify( response ) );

			// メンバーデータの更新に成功した場合
			if ( response )
			{
				// 遷移
				history.push( "/component/MemberEditComplete" );
			}
			// メンバーデータの更新に失敗した場合
			else
			{
				console.error( "MemberEditConfirm > updateMember () member update error." );
			}
		}
	};

	// 情報モーダルを閉じるイベントハンドラ
	const handleCloseInfoModal = () => setShowInfoModal( false );

	// 情報モーダルを表示するイベントハンドラ
	const handleShowInfoModal = () => setShowInfoModal( true );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/MemberList" ) }>メンバー</Breadcrumb.Item>
					<Breadcrumb.Item active>プロフィール編集 (確認)</Breadcrumb.Item>
				</Breadcrumb>

				{/* インフォメーションボタン */}
				<button onClick={ handleShowInfoModal } className="no_border_button info_link text-primary">
					<span className="material-icons-outlined info_icon">info</span>
				</button>

				{/* インフォメーションダイアログ */}
				<Modal show={ showInfoModal } onHide={ handleCloseInfoModal }>
					<Modal.Header className="text-white bg-primary">
						<Modal.Title>
							<span className="material-icons-outlined modal_info_icon">info</span>
							TIPS
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span>
							このページでは、メンバーのプロフィール編集ができます。
						</span>

						<div className="modal_action_btn_group">
							<Button variant="secondary" onClick={ handleCloseInfoModal }>閉じる</Button>
						</div>
					</Modal.Body>
				</Modal>

				<TopNav />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<h4 className="mb-4">
					プロフィール編集 (確認)
				</h4>

				{/* プログレストラッカー */}
				<Alert className="progress_tracker mb-4">
					<div>編集(入力)</div>
					<div className="active">確認</div>
				</Alert>

				<div>
					メールアドレス<br className="wide_br" />
					<p className="ms-3">{ location.state.mailAddress }</p>
				</div>

				<Row>
					<Col>
						姓 (全角20文字以内)<br className="wide_br" />
						<p className="ms-3">{ location.state.familyName || "-" }</p>
					</Col>
					<Col>
						名 (全角20文字以内)<br className="wide_br" />
						<p className="ms-3">{ location.state.firstName || "-" }</p>
					</Col>
				</Row>

				<div>
					所属部門 (全角100文字以内)<br className="wide_br" />
					<p className="ms-3">{ location.state.department || "-" }</p>
				</div>

				<Row>
					<Col>
						職種 (全角20文字以内)<br className="wide_br" />
						<p className="ms-3">{ location.state.jobTitle || "-" }</p>
					</Col>
					<Col>
						役職 (全角20文字以内)<br className="wide_br" />
						<p className="ms-3">{ location.state.position || "-" }</p>
					</Col>
				</Row>

				<div>
					権限<br className="wide_br" />
					<p className="ms-3">
						{
							( function ()
							{
								if ( location.state.permission === "N" ) return "一般";
								else if ( location.state.permission === "D" ) return "編集者";
								else if ( location.state.permission === "A" ) return "管理者";
							} )()
						}
					</p>
				</div>

				<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
					<Button variant="secondary" onClick={ goBackPrevious }>修正</Button>
					<Button onClick={ updateMember } disabled={ editMemberDisabled }>保存</Button>
				</div>

			</Container>

		</>
	);
}

export default MemberEditConfirm;
