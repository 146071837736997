/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateExEtc3001CynageAmount = /* GraphQL */ `
  mutation UpdateExEtc3001CynageAmount(
    $add: [UpdateEtc3001CynageAmountEnum]
    $input: UpdateEtc3001CynageAmountInput!
    $condition: ModelEtc3001CynageAmountConditionInput
  ) {
    updateExEtc3001CynageAmount(
      add: $add
      input: $input
      condition: $condition
    ) {
      aggregationMonth
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      memberAmount
      memberActive
      topicAmount
      completionRate
      createdAt
      updatedAt
    }
  }
`;
export const updateExEtc3001CynageSequence = /* GraphQL */ `
  mutation UpdateExEtc3001CynageSequence(
    $add: [UpdateEtc3001CynageSequenceEnum]
    $input: UpdateEtc3001CynageSequenceInput!
    $condition: ModelEtc3001CynageSequenceConditionInput
  ) {
    updateExEtc3001CynageSequence(
      add: $add
      input: $input
      condition: $condition
    ) {
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateExEtc3001CynageTicker = /* GraphQL */ `
  mutation UpdateExEtc3001CynageTicker(
    $add: [UpdateEtc3001CynageTickerEnum]
    $input: UpdateEtc3001CynageTickerInput!
    $condition: ModelEtc3001CynageTickerConditionInput
  ) {
    updateExEtc3001CynageTicker(
      add: $add
      input: $input
      condition: $condition
    ) {
      id
      number
      name
      mainContents
      subContents
      signages {
        items {
          signageID
          signage {
            id
            number
            name
            description
            tickers {
              items {
                signageID
                tickerID
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            domainID
            domain {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          tickerID
          ticker {
            id
            number
            name
            mainContents
            subContents
            signages {
              items {
                signageID
                tickerID
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            domainID
            domain {
              id
              name
              createdAt
              updatedAt
            }
            displayStartDate
            displayEndDate
            limitDate
            memberAmount
            clickAmount
            completionAmount
            completionRate
            isDeleted
            createdAt
            updatedAt
          }
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      displayStartDate
      displayEndDate
      limitDate
      memberAmount
      clickAmount
      completionAmount
      completionRate
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const createEtc3001CynageDomain = /* GraphQL */ `
  mutation CreateEtc3001CynageDomain(
    $input: CreateEtc3001CynageDomainInput!
    $condition: ModelEtc3001CynageDomainConditionInput
  ) {
    createEtc3001CynageDomain(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateEtc3001CynageDomain = /* GraphQL */ `
  mutation UpdateEtc3001CynageDomain(
    $input: UpdateEtc3001CynageDomainInput!
    $condition: ModelEtc3001CynageDomainConditionInput
  ) {
    updateEtc3001CynageDomain(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteEtc3001CynageDomain = /* GraphQL */ `
  mutation DeleteEtc3001CynageDomain(
    $input: DeleteEtc3001CynageDomainInput!
    $condition: ModelEtc3001CynageDomainConditionInput
  ) {
    deleteEtc3001CynageDomain(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createEtc3001CynageSchedule = /* GraphQL */ `
  mutation CreateEtc3001CynageSchedule(
    $input: CreateEtc3001CynageScheduleInput!
    $condition: ModelEtc3001CynageScheduleConditionInput
  ) {
    createEtc3001CynageSchedule(input: $input, condition: $condition) {
      id
      targetDate
      signageID
      signage {
        id
        number
        name
        description
        tickers {
          items {
            signageID
            signage {
              id
              number
              name
              description
              tickers {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            tickerID
            ticker {
              id
              number
              name
              mainContents
              subContents
              signages {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              displayStartDate
              displayEndDate
              limitDate
              memberAmount
              clickAmount
              completionAmount
              completionRate
              isDeleted
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEtc3001CynageSchedule = /* GraphQL */ `
  mutation UpdateEtc3001CynageSchedule(
    $input: UpdateEtc3001CynageScheduleInput!
    $condition: ModelEtc3001CynageScheduleConditionInput
  ) {
    updateEtc3001CynageSchedule(input: $input, condition: $condition) {
      id
      targetDate
      signageID
      signage {
        id
        number
        name
        description
        tickers {
          items {
            signageID
            signage {
              id
              number
              name
              description
              tickers {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            tickerID
            ticker {
              id
              number
              name
              mainContents
              subContents
              signages {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              displayStartDate
              displayEndDate
              limitDate
              memberAmount
              clickAmount
              completionAmount
              completionRate
              isDeleted
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEtc3001CynageSchedule = /* GraphQL */ `
  mutation DeleteEtc3001CynageSchedule(
    $input: DeleteEtc3001CynageScheduleInput!
    $condition: ModelEtc3001CynageScheduleConditionInput
  ) {
    deleteEtc3001CynageSchedule(input: $input, condition: $condition) {
      id
      targetDate
      signageID
      signage {
        id
        number
        name
        description
        tickers {
          items {
            signageID
            signage {
              id
              number
              name
              description
              tickers {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            tickerID
            ticker {
              id
              number
              name
              mainContents
              subContents
              signages {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              displayStartDate
              displayEndDate
              limitDate
              memberAmount
              clickAmount
              completionAmount
              completionRate
              isDeleted
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEtc3001CynageSignage = /* GraphQL */ `
  mutation CreateEtc3001CynageSignage(
    $input: CreateEtc3001CynageSignageInput!
    $condition: ModelEtc3001CynageSignageConditionInput
  ) {
    createEtc3001CynageSignage(input: $input, condition: $condition) {
      id
      number
      name
      description
      tickers {
        items {
          signageID
          signage {
            id
            number
            name
            description
            tickers {
              items {
                signageID
                tickerID
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            domainID
            domain {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          tickerID
          ticker {
            id
            number
            name
            mainContents
            subContents
            signages {
              items {
                signageID
                tickerID
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            domainID
            domain {
              id
              name
              createdAt
              updatedAt
            }
            displayStartDate
            displayEndDate
            limitDate
            memberAmount
            clickAmount
            completionAmount
            completionRate
            isDeleted
            createdAt
            updatedAt
          }
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEtc3001CynageSignage = /* GraphQL */ `
  mutation UpdateEtc3001CynageSignage(
    $input: UpdateEtc3001CynageSignageInput!
    $condition: ModelEtc3001CynageSignageConditionInput
  ) {
    updateEtc3001CynageSignage(input: $input, condition: $condition) {
      id
      number
      name
      description
      tickers {
        items {
          signageID
          signage {
            id
            number
            name
            description
            tickers {
              items {
                signageID
                tickerID
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            domainID
            domain {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          tickerID
          ticker {
            id
            number
            name
            mainContents
            subContents
            signages {
              items {
                signageID
                tickerID
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            domainID
            domain {
              id
              name
              createdAt
              updatedAt
            }
            displayStartDate
            displayEndDate
            limitDate
            memberAmount
            clickAmount
            completionAmount
            completionRate
            isDeleted
            createdAt
            updatedAt
          }
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEtc3001CynageSignage = /* GraphQL */ `
  mutation DeleteEtc3001CynageSignage(
    $input: DeleteEtc3001CynageSignageInput!
    $condition: ModelEtc3001CynageSignageConditionInput
  ) {
    deleteEtc3001CynageSignage(input: $input, condition: $condition) {
      id
      number
      name
      description
      tickers {
        items {
          signageID
          signage {
            id
            number
            name
            description
            tickers {
              items {
                signageID
                tickerID
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            domainID
            domain {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          tickerID
          ticker {
            id
            number
            name
            mainContents
            subContents
            signages {
              items {
                signageID
                tickerID
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            domainID
            domain {
              id
              name
              createdAt
              updatedAt
            }
            displayStartDate
            displayEndDate
            limitDate
            memberAmount
            clickAmount
            completionAmount
            completionRate
            isDeleted
            createdAt
            updatedAt
          }
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEtc3001CynageSignageTicker = /* GraphQL */ `
  mutation CreateEtc3001CynageSignageTicker(
    $input: CreateEtc3001CynageSignageTickerInput!
    $condition: ModelEtc3001CynageSignageTickerConditionInput
  ) {
    createEtc3001CynageSignageTicker(input: $input, condition: $condition) {
      signageID
      signage {
        id
        number
        name
        description
        tickers {
          items {
            signageID
            signage {
              id
              number
              name
              description
              tickers {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            tickerID
            ticker {
              id
              number
              name
              mainContents
              subContents
              signages {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              displayStartDate
              displayEndDate
              limitDate
              memberAmount
              clickAmount
              completionAmount
              completionRate
              isDeleted
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      tickerID
      ticker {
        id
        number
        name
        mainContents
        subContents
        signages {
          items {
            signageID
            signage {
              id
              number
              name
              description
              tickers {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            tickerID
            ticker {
              id
              number
              name
              mainContents
              subContents
              signages {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              displayStartDate
              displayEndDate
              limitDate
              memberAmount
              clickAmount
              completionAmount
              completionRate
              isDeleted
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        displayStartDate
        displayEndDate
        limitDate
        memberAmount
        clickAmount
        completionAmount
        completionRate
        isDeleted
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateEtc3001CynageSignageTicker = /* GraphQL */ `
  mutation UpdateEtc3001CynageSignageTicker(
    $input: UpdateEtc3001CynageSignageTickerInput!
    $condition: ModelEtc3001CynageSignageTickerConditionInput
  ) {
    updateEtc3001CynageSignageTicker(input: $input, condition: $condition) {
      signageID
      signage {
        id
        number
        name
        description
        tickers {
          items {
            signageID
            signage {
              id
              number
              name
              description
              tickers {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            tickerID
            ticker {
              id
              number
              name
              mainContents
              subContents
              signages {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              displayStartDate
              displayEndDate
              limitDate
              memberAmount
              clickAmount
              completionAmount
              completionRate
              isDeleted
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      tickerID
      ticker {
        id
        number
        name
        mainContents
        subContents
        signages {
          items {
            signageID
            signage {
              id
              number
              name
              description
              tickers {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            tickerID
            ticker {
              id
              number
              name
              mainContents
              subContents
              signages {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              displayStartDate
              displayEndDate
              limitDate
              memberAmount
              clickAmount
              completionAmount
              completionRate
              isDeleted
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        displayStartDate
        displayEndDate
        limitDate
        memberAmount
        clickAmount
        completionAmount
        completionRate
        isDeleted
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteEtc3001CynageSignageTicker = /* GraphQL */ `
  mutation DeleteEtc3001CynageSignageTicker(
    $input: DeleteEtc3001CynageSignageTickerInput!
    $condition: ModelEtc3001CynageSignageTickerConditionInput
  ) {
    deleteEtc3001CynageSignageTicker(input: $input, condition: $condition) {
      signageID
      signage {
        id
        number
        name
        description
        tickers {
          items {
            signageID
            signage {
              id
              number
              name
              description
              tickers {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            tickerID
            ticker {
              id
              number
              name
              mainContents
              subContents
              signages {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              displayStartDate
              displayEndDate
              limitDate
              memberAmount
              clickAmount
              completionAmount
              completionRate
              isDeleted
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      tickerID
      ticker {
        id
        number
        name
        mainContents
        subContents
        signages {
          items {
            signageID
            signage {
              id
              number
              name
              description
              tickers {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            tickerID
            ticker {
              id
              number
              name
              mainContents
              subContents
              signages {
                nextToken
              }
              domainID
              domain {
                id
                name
                createdAt
                updatedAt
              }
              displayStartDate
              displayEndDate
              limitDate
              memberAmount
              clickAmount
              completionAmount
              completionRate
              isDeleted
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        displayStartDate
        displayEndDate
        limitDate
        memberAmount
        clickAmount
        completionAmount
        completionRate
        isDeleted
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const createEtc3001CynageTicker = /* GraphQL */ `
  mutation CreateEtc3001CynageTicker(
    $input: CreateEtc3001CynageTickerInput!
    $condition: ModelEtc3001CynageTickerConditionInput
  ) {
    createEtc3001CynageTicker(input: $input, condition: $condition) {
      id
      number
      name
      mainContents
      subContents
      signages {
        items {
          signageID
          signage {
            id
            number
            name
            description
            tickers {
              items {
                signageID
                tickerID
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            domainID
            domain {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          tickerID
          ticker {
            id
            number
            name
            mainContents
            subContents
            signages {
              items {
                signageID
                tickerID
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            domainID
            domain {
              id
              name
              createdAt
              updatedAt
            }
            displayStartDate
            displayEndDate
            limitDate
            memberAmount
            clickAmount
            completionAmount
            completionRate
            isDeleted
            createdAt
            updatedAt
          }
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      displayStartDate
      displayEndDate
      limitDate
      memberAmount
      clickAmount
      completionAmount
      completionRate
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const updateEtc3001CynageTicker = /* GraphQL */ `
  mutation UpdateEtc3001CynageTicker(
    $input: UpdateEtc3001CynageTickerInput!
    $condition: ModelEtc3001CynageTickerConditionInput
  ) {
    updateEtc3001CynageTicker(input: $input, condition: $condition) {
      id
      number
      name
      mainContents
      subContents
      signages {
        items {
          signageID
          signage {
            id
            number
            name
            description
            tickers {
              items {
                signageID
                tickerID
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            domainID
            domain {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          tickerID
          ticker {
            id
            number
            name
            mainContents
            subContents
            signages {
              items {
                signageID
                tickerID
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            domainID
            domain {
              id
              name
              createdAt
              updatedAt
            }
            displayStartDate
            displayEndDate
            limitDate
            memberAmount
            clickAmount
            completionAmount
            completionRate
            isDeleted
            createdAt
            updatedAt
          }
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      displayStartDate
      displayEndDate
      limitDate
      memberAmount
      clickAmount
      completionAmount
      completionRate
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteEtc3001CynageTicker = /* GraphQL */ `
  mutation DeleteEtc3001CynageTicker(
    $input: DeleteEtc3001CynageTickerInput!
    $condition: ModelEtc3001CynageTickerConditionInput
  ) {
    deleteEtc3001CynageTicker(input: $input, condition: $condition) {
      id
      number
      name
      mainContents
      subContents
      signages {
        items {
          signageID
          signage {
            id
            number
            name
            description
            tickers {
              items {
                signageID
                tickerID
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            domainID
            domain {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          tickerID
          ticker {
            id
            number
            name
            mainContents
            subContents
            signages {
              items {
                signageID
                tickerID
                order
                createdAt
                updatedAt
              }
              nextToken
            }
            domainID
            domain {
              id
              name
              createdAt
              updatedAt
            }
            displayStartDate
            displayEndDate
            limitDate
            memberAmount
            clickAmount
            completionAmount
            completionRate
            isDeleted
            createdAt
            updatedAt
          }
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      displayStartDate
      displayEndDate
      limitDate
      memberAmount
      clickAmount
      completionAmount
      completionRate
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const createEtc3001CynageLinkList = /* GraphQL */ `
  mutation CreateEtc3001CynageLinkList(
    $input: CreateEtc3001CynageLinkListInput!
    $condition: ModelEtc3001CynageLinkListConditionInput
  ) {
    createEtc3001CynageLinkList(input: $input, condition: $condition) {
      id
      order
      name
      link
      icon
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEtc3001CynageLinkList = /* GraphQL */ `
  mutation UpdateEtc3001CynageLinkList(
    $input: UpdateEtc3001CynageLinkListInput!
    $condition: ModelEtc3001CynageLinkListConditionInput
  ) {
    updateEtc3001CynageLinkList(input: $input, condition: $condition) {
      id
      order
      name
      link
      icon
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEtc3001CynageLinkList = /* GraphQL */ `
  mutation DeleteEtc3001CynageLinkList(
    $input: DeleteEtc3001CynageLinkListInput!
    $condition: ModelEtc3001CynageLinkListConditionInput
  ) {
    deleteEtc3001CynageLinkList(input: $input, condition: $condition) {
      id
      order
      name
      link
      icon
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEtc3001CynageMember = /* GraphQL */ `
  mutation CreateEtc3001CynageMember(
    $input: CreateEtc3001CynageMemberInput!
    $condition: ModelEtc3001CynageMemberConditionInput
  ) {
    createEtc3001CynageMember(input: $input, condition: $condition) {
      id
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      mailAddress
      isDisabled
      isOnLeave
      invitedDate
      approvedDate
      familyName
      firstName
      department
      jobTitle
      position
      permission
      lastLoginDate
      loginCountWithinMonth
      promptedList
      completeList
      disabledDate
      isCognitoDeleted
      createdAt
      updatedAt
    }
  }
`;
export const updateEtc3001CynageMember = /* GraphQL */ `
  mutation UpdateEtc3001CynageMember(
    $input: UpdateEtc3001CynageMemberInput!
    $condition: ModelEtc3001CynageMemberConditionInput
  ) {
    updateEtc3001CynageMember(input: $input, condition: $condition) {
      id
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      mailAddress
      isDisabled
      isOnLeave
      invitedDate
      approvedDate
      familyName
      firstName
      department
      jobTitle
      position
      permission
      lastLoginDate
      loginCountWithinMonth
      promptedList
      completeList
      disabledDate
      isCognitoDeleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteEtc3001CynageMember = /* GraphQL */ `
  mutation DeleteEtc3001CynageMember(
    $input: DeleteEtc3001CynageMemberInput!
    $condition: ModelEtc3001CynageMemberConditionInput
  ) {
    deleteEtc3001CynageMember(input: $input, condition: $condition) {
      id
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      mailAddress
      isDisabled
      isOnLeave
      invitedDate
      approvedDate
      familyName
      firstName
      department
      jobTitle
      position
      permission
      lastLoginDate
      loginCountWithinMonth
      promptedList
      completeList
      disabledDate
      isCognitoDeleted
      createdAt
      updatedAt
    }
  }
`;
export const createEtc3001CynageAmount = /* GraphQL */ `
  mutation CreateEtc3001CynageAmount(
    $input: CreateEtc3001CynageAmountInput!
    $condition: ModelEtc3001CynageAmountConditionInput
  ) {
    createEtc3001CynageAmount(input: $input, condition: $condition) {
      aggregationMonth
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      memberAmount
      memberActive
      topicAmount
      completionRate
      createdAt
      updatedAt
    }
  }
`;
export const updateEtc3001CynageAmount = /* GraphQL */ `
  mutation UpdateEtc3001CynageAmount(
    $input: UpdateEtc3001CynageAmountInput!
    $condition: ModelEtc3001CynageAmountConditionInput
  ) {
    updateEtc3001CynageAmount(input: $input, condition: $condition) {
      aggregationMonth
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      memberAmount
      memberActive
      topicAmount
      completionRate
      createdAt
      updatedAt
    }
  }
`;
export const deleteEtc3001CynageAmount = /* GraphQL */ `
  mutation DeleteEtc3001CynageAmount(
    $input: DeleteEtc3001CynageAmountInput!
    $condition: ModelEtc3001CynageAmountConditionInput
  ) {
    deleteEtc3001CynageAmount(input: $input, condition: $condition) {
      aggregationMonth
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      memberAmount
      memberActive
      topicAmount
      completionRate
      createdAt
      updatedAt
    }
  }
`;
export const createEtc3001CynageMemberTransferHistory = /* GraphQL */ `
  mutation CreateEtc3001CynageMemberTransferHistory(
    $input: CreateEtc3001CynageMemberTransferHistoryInput!
    $condition: ModelEtc3001CynageMemberTransferHistoryConditionInput
  ) {
    createEtc3001CynageMemberTransferHistory(
      input: $input
      condition: $condition
    ) {
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      transferDate
      inviteList
      retireList
      onLeaveList
      returnList
      createdAt
      updatedAt
    }
  }
`;
export const updateEtc3001CynageMemberTransferHistory = /* GraphQL */ `
  mutation UpdateEtc3001CynageMemberTransferHistory(
    $input: UpdateEtc3001CynageMemberTransferHistoryInput!
    $condition: ModelEtc3001CynageMemberTransferHistoryConditionInput
  ) {
    updateEtc3001CynageMemberTransferHistory(
      input: $input
      condition: $condition
    ) {
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      transferDate
      inviteList
      retireList
      onLeaveList
      returnList
      createdAt
      updatedAt
    }
  }
`;
export const deleteEtc3001CynageMemberTransferHistory = /* GraphQL */ `
  mutation DeleteEtc3001CynageMemberTransferHistory(
    $input: DeleteEtc3001CynageMemberTransferHistoryInput!
    $condition: ModelEtc3001CynageMemberTransferHistoryConditionInput
  ) {
    deleteEtc3001CynageMemberTransferHistory(
      input: $input
      condition: $condition
    ) {
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      transferDate
      inviteList
      retireList
      onLeaveList
      returnList
      createdAt
      updatedAt
    }
  }
`;
export const createEtc3001CynageMemberLoginHistory = /* GraphQL */ `
  mutation CreateEtc3001CynageMemberLoginHistory(
    $input: CreateEtc3001CynageMemberLoginHistoryInput!
    $condition: ModelEtc3001CynageMemberLoginHistoryConditionInput
  ) {
    createEtc3001CynageMemberLoginHistory(
      input: $input
      condition: $condition
    ) {
      memberID
      domainIDMonth
      targetDay
      createdAt
      updatedAt
    }
  }
`;
export const updateEtc3001CynageMemberLoginHistory = /* GraphQL */ `
  mutation UpdateEtc3001CynageMemberLoginHistory(
    $input: UpdateEtc3001CynageMemberLoginHistoryInput!
    $condition: ModelEtc3001CynageMemberLoginHistoryConditionInput
  ) {
    updateEtc3001CynageMemberLoginHistory(
      input: $input
      condition: $condition
    ) {
      memberID
      domainIDMonth
      targetDay
      createdAt
      updatedAt
    }
  }
`;
export const deleteEtc3001CynageMemberLoginHistory = /* GraphQL */ `
  mutation DeleteEtc3001CynageMemberLoginHistory(
    $input: DeleteEtc3001CynageMemberLoginHistoryInput!
    $condition: ModelEtc3001CynageMemberLoginHistoryConditionInput
  ) {
    deleteEtc3001CynageMemberLoginHistory(
      input: $input
      condition: $condition
    ) {
      memberID
      domainIDMonth
      targetDay
      createdAt
      updatedAt
    }
  }
`;
export const createEtc3001CynageTopicClickHistory = /* GraphQL */ `
  mutation CreateEtc3001CynageTopicClickHistory(
    $input: CreateEtc3001CynageTopicClickHistoryInput!
    $condition: ModelEtc3001CynageTopicClickHistoryConditionInput
  ) {
    createEtc3001CynageTopicClickHistory(input: $input, condition: $condition) {
      memberID
      domainTopicID
      clickDate
      createdAt
      updatedAt
    }
  }
`;
export const updateEtc3001CynageTopicClickHistory = /* GraphQL */ `
  mutation UpdateEtc3001CynageTopicClickHistory(
    $input: UpdateEtc3001CynageTopicClickHistoryInput!
    $condition: ModelEtc3001CynageTopicClickHistoryConditionInput
  ) {
    updateEtc3001CynageTopicClickHistory(input: $input, condition: $condition) {
      memberID
      domainTopicID
      clickDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteEtc3001CynageTopicClickHistory = /* GraphQL */ `
  mutation DeleteEtc3001CynageTopicClickHistory(
    $input: DeleteEtc3001CynageTopicClickHistoryInput!
    $condition: ModelEtc3001CynageTopicClickHistoryConditionInput
  ) {
    deleteEtc3001CynageTopicClickHistory(input: $input, condition: $condition) {
      memberID
      domainTopicID
      clickDate
      createdAt
      updatedAt
    }
  }
`;
export const createEtc3001CynageTopicCompleteHistory = /* GraphQL */ `
  mutation CreateEtc3001CynageTopicCompleteHistory(
    $input: CreateEtc3001CynageTopicCompleteHistoryInput!
    $condition: ModelEtc3001CynageTopicCompleteHistoryConditionInput
  ) {
    createEtc3001CynageTopicCompleteHistory(
      input: $input
      condition: $condition
    ) {
      memberID
      topicID
      domainID
      completeDate
      createdAt
      updatedAt
    }
  }
`;
export const updateEtc3001CynageTopicCompleteHistory = /* GraphQL */ `
  mutation UpdateEtc3001CynageTopicCompleteHistory(
    $input: UpdateEtc3001CynageTopicCompleteHistoryInput!
    $condition: ModelEtc3001CynageTopicCompleteHistoryConditionInput
  ) {
    updateEtc3001CynageTopicCompleteHistory(
      input: $input
      condition: $condition
    ) {
      memberID
      topicID
      domainID
      completeDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteEtc3001CynageTopicCompleteHistory = /* GraphQL */ `
  mutation DeleteEtc3001CynageTopicCompleteHistory(
    $input: DeleteEtc3001CynageTopicCompleteHistoryInput!
    $condition: ModelEtc3001CynageTopicCompleteHistoryConditionInput
  ) {
    deleteEtc3001CynageTopicCompleteHistory(
      input: $input
      condition: $condition
    ) {
      memberID
      topicID
      domainID
      completeDate
      createdAt
      updatedAt
    }
  }
`;
export const createEtc3001CynagePrompt = /* GraphQL */ `
  mutation CreateEtc3001CynagePrompt(
    $input: CreateEtc3001CynagePromptInput!
    $condition: ModelEtc3001CynagePromptConditionInput
  ) {
    createEtc3001CynagePrompt(input: $input, condition: $condition) {
      id
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      topicID
      memberList
      subject
      body
      createdAt
      updatedAt
    }
  }
`;
export const updateEtc3001CynagePrompt = /* GraphQL */ `
  mutation UpdateEtc3001CynagePrompt(
    $input: UpdateEtc3001CynagePromptInput!
    $condition: ModelEtc3001CynagePromptConditionInput
  ) {
    updateEtc3001CynagePrompt(input: $input, condition: $condition) {
      id
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      topicID
      memberList
      subject
      body
      createdAt
      updatedAt
    }
  }
`;
export const deleteEtc3001CynagePrompt = /* GraphQL */ `
  mutation DeleteEtc3001CynagePrompt(
    $input: DeleteEtc3001CynagePromptInput!
    $condition: ModelEtc3001CynagePromptConditionInput
  ) {
    deleteEtc3001CynagePrompt(input: $input, condition: $condition) {
      id
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      topicID
      memberList
      subject
      body
      createdAt
      updatedAt
    }
  }
`;
export const createEtc3001CynageInvite = /* GraphQL */ `
  mutation CreateEtc3001CynageInvite(
    $input: CreateEtc3001CynageInviteInput!
    $condition: ModelEtc3001CynageInviteConditionInput
  ) {
    createEtc3001CynageInvite(input: $input, condition: $condition) {
      id
      invitedDate
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      mailAddress
      permission
      isApproved
      isProcessed
      inviter
      createdAt
      updatedAt
    }
  }
`;
export const updateEtc3001CynageInvite = /* GraphQL */ `
  mutation UpdateEtc3001CynageInvite(
    $input: UpdateEtc3001CynageInviteInput!
    $condition: ModelEtc3001CynageInviteConditionInput
  ) {
    updateEtc3001CynageInvite(input: $input, condition: $condition) {
      id
      invitedDate
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      mailAddress
      permission
      isApproved
      isProcessed
      inviter
      createdAt
      updatedAt
    }
  }
`;
export const deleteEtc3001CynageInvite = /* GraphQL */ `
  mutation DeleteEtc3001CynageInvite(
    $input: DeleteEtc3001CynageInviteInput!
    $condition: ModelEtc3001CynageInviteConditionInput
  ) {
    deleteEtc3001CynageInvite(input: $input, condition: $condition) {
      id
      invitedDate
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      mailAddress
      permission
      isApproved
      isProcessed
      inviter
      createdAt
      updatedAt
    }
  }
`;
export const createEtc3001CynageInformation = /* GraphQL */ `
  mutation CreateEtc3001CynageInformation(
    $input: CreateEtc3001CynageInformationInput!
    $condition: ModelEtc3001CynageInformationConditionInput
  ) {
    createEtc3001CynageInformation(input: $input, condition: $condition) {
      id
      infoDate
      subject
      body
      createdAt
      updatedAt
    }
  }
`;
export const updateEtc3001CynageInformation = /* GraphQL */ `
  mutation UpdateEtc3001CynageInformation(
    $input: UpdateEtc3001CynageInformationInput!
    $condition: ModelEtc3001CynageInformationConditionInput
  ) {
    updateEtc3001CynageInformation(input: $input, condition: $condition) {
      id
      infoDate
      subject
      body
      createdAt
      updatedAt
    }
  }
`;
export const deleteEtc3001CynageInformation = /* GraphQL */ `
  mutation DeleteEtc3001CynageInformation(
    $input: DeleteEtc3001CynageInformationInput!
    $condition: ModelEtc3001CynageInformationConditionInput
  ) {
    deleteEtc3001CynageInformation(input: $input, condition: $condition) {
      id
      infoDate
      subject
      body
      createdAt
      updatedAt
    }
  }
`;
export const createEtc3001CynageMemberActHistory = /* GraphQL */ `
  mutation CreateEtc3001CynageMemberActHistory(
    $input: CreateEtc3001CynageMemberActHistoryInput!
    $condition: ModelEtc3001CynageMemberActHistoryConditionInput
  ) {
    createEtc3001CynageMemberActHistory(input: $input, condition: $condition) {
      aggregationMonth
      memberID
      member {
        id
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        mailAddress
        isDisabled
        isOnLeave
        invitedDate
        approvedDate
        familyName
        firstName
        department
        jobTitle
        position
        permission
        lastLoginDate
        loginCountWithinMonth
        promptedList
        completeList
        disabledDate
        isCognitoDeleted
        createdAt
        updatedAt
      }
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      loginCountWithinMonth
      incompleteCount
      createdAt
      updatedAt
    }
  }
`;
export const updateEtc3001CynageMemberActHistory = /* GraphQL */ `
  mutation UpdateEtc3001CynageMemberActHistory(
    $input: UpdateEtc3001CynageMemberActHistoryInput!
    $condition: ModelEtc3001CynageMemberActHistoryConditionInput
  ) {
    updateEtc3001CynageMemberActHistory(input: $input, condition: $condition) {
      aggregationMonth
      memberID
      member {
        id
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        mailAddress
        isDisabled
        isOnLeave
        invitedDate
        approvedDate
        familyName
        firstName
        department
        jobTitle
        position
        permission
        lastLoginDate
        loginCountWithinMonth
        promptedList
        completeList
        disabledDate
        isCognitoDeleted
        createdAt
        updatedAt
      }
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      loginCountWithinMonth
      incompleteCount
      createdAt
      updatedAt
    }
  }
`;
export const deleteEtc3001CynageMemberActHistory = /* GraphQL */ `
  mutation DeleteEtc3001CynageMemberActHistory(
    $input: DeleteEtc3001CynageMemberActHistoryInput!
    $condition: ModelEtc3001CynageMemberActHistoryConditionInput
  ) {
    deleteEtc3001CynageMemberActHistory(input: $input, condition: $condition) {
      aggregationMonth
      memberID
      member {
        id
        domainID
        domain {
          id
          name
          createdAt
          updatedAt
        }
        mailAddress
        isDisabled
        isOnLeave
        invitedDate
        approvedDate
        familyName
        firstName
        department
        jobTitle
        position
        permission
        lastLoginDate
        loginCountWithinMonth
        promptedList
        completeList
        disabledDate
        isCognitoDeleted
        createdAt
        updatedAt
      }
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      loginCountWithinMonth
      incompleteCount
      createdAt
      updatedAt
    }
  }
`;
export const createEtc3001CynageTopicOrder = /* GraphQL */ `
  mutation CreateEtc3001CynageTopicOrder(
    $input: CreateEtc3001CynageTopicOrderInput!
    $condition: ModelEtc3001CynageTopicOrderConditionInput
  ) {
    createEtc3001CynageTopicOrder(input: $input, condition: $condition) {
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      topicOrder
      createdAt
      updatedAt
    }
  }
`;
export const updateEtc3001CynageTopicOrder = /* GraphQL */ `
  mutation UpdateEtc3001CynageTopicOrder(
    $input: UpdateEtc3001CynageTopicOrderInput!
    $condition: ModelEtc3001CynageTopicOrderConditionInput
  ) {
    updateEtc3001CynageTopicOrder(input: $input, condition: $condition) {
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      topicOrder
      createdAt
      updatedAt
    }
  }
`;
export const deleteEtc3001CynageTopicOrder = /* GraphQL */ `
  mutation DeleteEtc3001CynageTopicOrder(
    $input: DeleteEtc3001CynageTopicOrderInput!
    $condition: ModelEtc3001CynageTopicOrderConditionInput
  ) {
    deleteEtc3001CynageTopicOrder(input: $input, condition: $condition) {
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      topicOrder
      createdAt
      updatedAt
    }
  }
`;
export const createEtc3001CynageSequence = /* GraphQL */ `
  mutation CreateEtc3001CynageSequence(
    $input: CreateEtc3001CynageSequenceInput!
    $condition: ModelEtc3001CynageSequenceConditionInput
  ) {
    createEtc3001CynageSequence(input: $input, condition: $condition) {
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateEtc3001CynageSequence = /* GraphQL */ `
  mutation UpdateEtc3001CynageSequence(
    $input: UpdateEtc3001CynageSequenceInput!
    $condition: ModelEtc3001CynageSequenceConditionInput
  ) {
    updateEtc3001CynageSequence(input: $input, condition: $condition) {
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteEtc3001CynageSequence = /* GraphQL */ `
  mutation DeleteEtc3001CynageSequence(
    $input: DeleteEtc3001CynageSequenceInput!
    $condition: ModelEtc3001CynageSequenceConditionInput
  ) {
    deleteEtc3001CynageSequence(input: $input, condition: $condition) {
      domainID
      domain {
        id
        name
        createdAt
        updatedAt
      }
      name
      value
      createdAt
      updatedAt
    }
  }
`;
