import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import * as common from "./common";

import TopNav from "./TopNav";

import {
	Alert,
	Breadcrumb,
	Button,
	Container
} from "react-bootstrap";


// Component ===================================================================

function TopicSortComplete ()
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "TopicSortComplete > TopicSortComplete () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "TopicSortComplete > TopicSortComplete () location : " + JSON.stringify( location ) );
	//console.log( "TopicSortComplete > TopicSortComplete () params : " + JSON.stringify( params ) );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [トピックス表示順編集(完了)]";
	}, [] );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/TopicSort" ) }>トピックス表示順</Breadcrumb.Item>
					<Breadcrumb.Item active>表示順編集 (完了)</Breadcrumb.Item>
				</Breadcrumb>

				<TopNav />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<h4 className="mb-4">
					表示順編集 (完了)
				</h4>

				{/* プログレストラッカー */}
				<Alert className="progress_tracker mb-4">
					<div>表示順編集</div>
					<div className="active">完了</div>
				</Alert>

				<div className="mt-5">
					<span className="material-icons success_icon">check_circle_outline</span>
				</div>

				<p className="text-center">
					トピックスの表示順編集を完了しました。
				</p>

				<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
					<Button variant="secondary" onClick={ () => history.push( "/component/TopicSort" ) }>トピックス表示順編集</Button>
				</div>

			</Container>

		</>
	);
}

export default TopicSortComplete;
