import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import * as common from "./common";

import TopNav from "./TopNav";

import {
	Breadcrumb,
	Button,
	Container
} from "react-bootstrap";


// Component ===================================================================

function LinkListDeleteComplete ()
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "LinkListDeleteComplete > LinkListDeleteComplete () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "LinkListDeleteComplete > LinkListDeleteComplete () location : " + JSON.stringify( location ) );
	//console.log( "LinkListDeleteComplete > LinkListDeleteComplete () params : " + JSON.stringify( params ) );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [リンクリスト(削除完了)]";
	}, [] );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/LinkList" ) }>リンクリスト</Breadcrumb.Item>
					<Breadcrumb.Item active>削除 (完了)</Breadcrumb.Item>
				</Breadcrumb>

				<TopNav />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<div className="mt-5">
					<span className="material-icons success_icon">check_circle_outline</span>
				</div>

				<p className="text-center">
					リンクを削除しました。
				</p>

				<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
					<Button variant="secondary" onClick={ () => history.push( "/component/LinkList" ) }>リンクリスト一覧</Button>
				</div>

			</Container>

		</>
	);
}

export default LinkListDeleteComplete;
