import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import { getEtc3001CynageTicker, listEtc3001CynageTickers } from "../graphql/queries";
import { createEtc3001CynageTopicOrder, updateEtc3001CynageTopicOrder, deleteEtc3001CynageTopicOrder } from "../graphql/mutations";
import { getEtc3001CynageTopicOrder, listEtc3001CynageTopicOrders } from "../graphql/queries";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import * as common from "./common";
import * as database from "./database";

import TopNav from "./TopNav";

import {
	Breadcrumb,
	Button,
	Container,
	Modal,
	Table
} from "react-bootstrap";


// Global Variable =============================================================

// ティッカーデータリスト(配列)
let tickerList = [];

// トピックス順データ
let topicOrder = {};


// Component ===================================================================

function TopicSort ( props )
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "TopicSort > TopicSort () props : " + JSON.stringify( props ) );
	//console.log( "TopicSort > TopicSort () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "TopicSort > TopicSort () location : " + JSON.stringify( location ) );
	//console.log( "TopicSort > TopicSort () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ updateDisabled, setUpdateDisabled ] = useState( true );
	const [ tableList, setTableList ] = useState( null );
	const [ messageDisplay, setMessageDisplay ] = useState( "none" );
	const [ showInfoModal, setShowInfoModal ] = useState( false );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [トピックス表示順編集]";

		( async function ()
		{
			// 組織データの取得に成功した場合
			if ( props.userData.domain )
			{
				// ティッカーデータリスト(配列)取得(非同期)
				tickerList = await common.getTickerArray( listEtc3001CynageTickers, props.userData.domain.id );

				//console.log( "TopicSort > useEffect () tickerList : " + JSON.stringify( tickerList ) );
				//console.dir( tickerList );

				// ティッカーデータの取得に成功した場合
				if ( tickerList )
				{
					// 日時(Date型)データの取得
					// 本日
					const today = new Date( common.getJSTDateTime( null, "YYYY-MM-DD" ) );

					//console.log( "TopicSort > useEffect () today : " + JSON.stringify( today ) + " : " + JSON.stringify( today.getTime() ) );

					// ---------------------------------------------------------

					// 本日が表示期間内のトピックスに絞り込む
					tickerList = tickerList.filter( function ( value )
					{
						//console.log( "TopicSort > useEffect () value : " + JSON.stringify( value ) );

						// 表示開始日
						const displayStartDate = new Date( value.displayStartDate );
						// 表示終了日
						const displayEndDate = new Date( value.displayEndDate );

						//console.log( "TopicSort > useEffect () displayStartDate : " + JSON.stringify( displayStartDate ) + " : " + JSON.stringify( displayStartDate.getTime() ) );
						//console.log( "TopicSort > useEffect () displayEndDate : " + JSON.stringify( displayEndDate ) + " : " + JSON.stringify( displayEndDate.getTime() ) );

						return ( ( displayStartDate.getTime() <= today.getTime() ) && ( today.getTime() <= displayEndDate.getTime() ) );
					} );

					// ---------------------------------------------------------

					// ティッカーデータ(配列)を表示開始日(displayStartDate)で降順ソート(破壊的変更)
					tickerList.sort( function ( a, b )
					{
						return new Date( b.displayStartDate ) - new Date( a.displayStartDate );
					} );

					// ---------------------------------------------------------

					// 設定されているトピックス順に並べ替え
					let dataList = [];

					// ① トピックス順データ取得
					const variables = {
						domainID: props.userData.domain.id
					};
					topicOrder = await database.queryGet( getEtc3001CynageTopicOrder, variables );

					//console.log( "TopicSort > useEffect () topicOrder : " + JSON.stringify( topicOrder ) );
					//console.dir( topicOrder );

					// ② トピックス順データの取得に成功した場合
					if ( ( topicOrder ) && ( topicOrder.topicOrder ) )
					{
						// ③ トピックス順データを本日が表示期間内のトピックスで絞り込む
						for ( const value1 of topicOrder.topicOrder )
						{
							//console.log( "TopicSort > useEffect () value1 : " + JSON.stringify( value1 ) );

							// 本日が表示期間内のトピックスに含まれているか確認
							const response = tickerList.find( function ( value2 )
							{
								return ( value1 === value2.id );
							} );
							// 本日が表示期間内のトピックスに含まれている場合
							if ( response )
							{
								// ティッカーデータを確保
								dataList.push( response );
							}
						}
					}

					//console.log( "TopicSort > useEffect (1) dataList : " + JSON.stringify( dataList ) );

					// ④ 上記で絞り込んだティッカーデータ(配列)の末尾に、残りの本日が表示期間内のトピックスを追加
					for ( const value1 of tickerList )
					{
						//console.log( "TopicSort > useEffect () value1 : " + JSON.stringify( value1 ) );

						// 上記で絞り込んだティッカーデータ(配列)に含まれているか確認
						const response = dataList.find( function ( value2 )
						{
							return ( value1.id === value2.id );
						} );
						// 上記で絞り込んだティッカーデータ(配列)に含まれていない場合
						if ( !response )
						{
							// ティッカーデータを確保
							dataList.push( value1 );
						}
					}

					//console.log( "TopicSort > useEffect (2) dataList : " + JSON.stringify( dataList ) );

					// ⑤ 並べ替え反映
					tickerList = dataList;

					// ---------------------------------------------------------

					// ティッカーデータリストが空の場合
					if ( tickerList.length <= 0 )
					{
						// データ無しメッセージ表示
						setMessageDisplay( "block" )
					}
					// ティッカーデータリストが空でない場合
					else
					{
						// リストの更新
						setTableList( buildTableList( tickerList ) );

						// データ無しメッセージ非表示
						setMessageDisplay( "none" )
					}
				}
				// ティッカーデータの取得に失敗した場合
				else
				{
					console.error( "TopicSort > useEffect () ticker get list error." );
				}
			}
			// 組織データの取得に失敗した場合
			else
			{
				alert( "not belong cognito:group error. : " + JSON.stringify( props.userData.user.attributes.email ) );
			}
		} )();
	}, [] );

	// トピックス表示順更新処理
	const updateTopicOrder = async function ( event )
	{
		// 念の為、確認を入れて
		const confirm = window.confirm( "トピックスの表示順序を更新しますか？" );
		// 並べ替える場合
		if ( confirm )
		{
			// 更新ボタンの無効化
			setUpdateDisabled( true );

			//console.log( "TopicSort > updateTopicOrder () tickerList : " + JSON.stringify( tickerList ) );

			// トピックス表示順リスト(配列)の生成
			let idList = [];
			for ( const value of tickerList )
			{
				//console.log( "TopicSort > updateTopicOrder () value : " + JSON.stringify( value ) );

				idList.push( value.id );
			}

			//console.log( "TopicSort > updateTopicOrder () idList : " + JSON.stringify( idList ) );

			const input = {
				domainID: props.userData.domain.id,
				topicOrder: idList
			};

			// トピックス順データが存在しない(取得に成功していない)場合
			if ( ( !topicOrder ) || ( !topicOrder.topicOrder ) )
			{
				// トピックス順データ生成
				const response = await database.mutationCreate( createEtc3001CynageTopicOrder, input );
				// トピックス順データの生成に成功した場合
				if ( response )
				{
					// 遷移
					history.push( "/component/TopicSortComplete" );
				}
				// トピックス順データの生成に失敗した場合
				else
				{
					console.error( "TopicSort > updateTopicOrder () topic order data create error." );
				}
			}
			// トピックス順データが存在する(取得に成功していた)場合
			else
			{
				// トピックス順データ更新
				const response = await database.mutationUpdate( updateEtc3001CynageTopicOrder, input );
				// トピックス順データの更新に成功した場合
				if ( response )
				{
					// 遷移
					history.push( "/component/TopicSortComplete" );
				}
				// トピックス順データの更新に失敗した場合
				else
				{
					console.error( "TopicSort > updateTopicOrder () topic order data update error." );
				}
			}
		}
	};

	// リスト生成処理
	const buildTableList = function ( list )
	{
		const result = list.map( function ( value, index )
		{
			//console.log( "TopicSort > buildTableList () list[ " + index + " ] : " + JSON.stringify( value ) );

			return (

				<Draggable
					key={ index }
					draggableId={ "draggable1-" + index }
					index={ index }
				>
					{
						function ( provided )
						{
							return (

								<tr
									ref={ provided.innerRef }
									{ ...provided.draggableProps }
									{ ...provided.dragHandleProps }
								>

									{/* No. */}
									<td>
										{ value.number }<br />
										{ ( common.calcDiffDay( value.createdAt ) < 8.0 ) ? <img src="/new.png" alt="" /> : null }
									</td>
									{/* トピックス名 */}
									<td>
										{ value.name }
									</td>
									{/* 表示開始日 */}
									<td className="text-center">
										{ common.getReplaceDate( value.displayStartDate, "-", "YYYY/MM/DD" ) }
									</td>
									{/* 表示終了日 */}
									<td className="text-center">
										{ common.getReplaceDate( value.displayEndDate, "-", "YYYY/MM/DD" ) }
									</td>
									{/* タスク締切日 */}
									<td className="text-center">
										{ common.getReplaceDate( value.limitDate, "-", "YYYY/MM/DD" ) || "-" }
									</td>

								</tr>

							);
						}
					}
				</Draggable>

			);
		} );

		//console.dir( result );

		return result;
	};

	// react-beautiful-dnd のドラッグ開始時処理
	const handleDragStart = function ( event )
	{
		//console.log( "TopicSort > handleDragStart () event : " + JSON.stringify( event ) );
	};

	// react-beautiful-dnd のドラッグ中リスト更新処理
	const handleDragUpdate = function ( event )
	{
		//console.log( "TopicSort > handleDragUpdate () event : " + JSON.stringify( event ) );
	};

	// react-beautiful-dnd のドロップ時処理 (react-beautiful-dnd 必須処理)
	const handleDragEnd = function ( event )
	{
		//console.log( "TopicSort > handleDragEnd () event : " + JSON.stringify( event ) );
		//console.log( "TopicSort > handleDragEnd () event.source (ドラッグ元) : " + JSON.stringify( event.source ) );
		//console.log( "TopicSort > handleDragEnd () event.destination (ドロップ先) : " + JSON.stringify( event.destination ) );

		// ドロップ先が存在する場合
		if ( event.destination )
		{
			// 同一リスト内のドロップの場合
			if ( event.source.droppableId === event.destination.droppableId )
			{
				// ドラッグ元位置とドロップ先位置が違う場合
				if ( event.source.index !== event.destination.index )
				{
					// react-beautiful-dnd ドロップ時の同一リスト内アイテム並べ替え
					tickerList = common.reactReautifulDnD_reOrder( tickerList, event.source.index, event.destination.index );

					// リストの更新
					setTableList( buildTableList( tickerList ) );
				}
			}
		}

		// 更新ボタンの有効化
		setUpdateDisabled( false );
	};

	// 情報モーダルを閉じるイベントハンドラ
	const handleCloseInfoModal = () => setShowInfoModal( false );

	// 情報モーダルを表示するイベントハンドラ
	const handleShowInfoModal = () => setShowInfoModal( true );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/TopicSort" ) }>トピックス表示順</Breadcrumb.Item>
					<Breadcrumb.Item active>編集</Breadcrumb.Item>
				</Breadcrumb>

				{/* インフォメーションボタン */}
				<button onClick={ handleShowInfoModal } className="no_border_button info_link text-primary">
					<span className="material-icons-outlined info_icon">info</span>
				</button>

				{/* インフォメーションダイアログ */}
				<Modal show={ showInfoModal } onHide={ handleCloseInfoModal }>
					<Modal.Header className="text-white bg-primary">
						<Modal.Title>
							<span className="material-icons-outlined modal_info_icon">info</span>
							TIPS
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span>
							このページでは、トピックスの表示順を編集することができます。<br />
							<span className="text-danger">リスト内のトピックスをドラッグ＆ドロップして更新ボタンをクリック</span>すると並べ替えができます。
						</span>

						<div className="modal_action_btn_group">
							<Button variant="secondary" onClick={ handleCloseInfoModal }>閉じる</Button>
						</div>
					</Modal.Body>
				</Modal>

				<TopNav />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<div>
					現時点(表示期間内)での配信トピックスの表示順を編集できます。
				</div>

				<DragDropContext
					onDragStart={ handleDragStart }
					onDragUpdate={ handleDragUpdate }
					onDragEnd={ handleDragEnd }
				>
					<Droppable
						droppableId="droppable1"
						isDropDisabled={ false }
					>
						{
							function ( provided )
							{
								return (
									<div
										ref={ provided.innerRef }
										{ ...provided.droppableProps }
									>

										<Table striped bordered hover>
											<thead>
												<tr>
													<th className="text-center" style={{ width: "80px" }}>
														No.
													</th>
													<th className="text-center">
														トピックス名
													</th>
													<th className="text-center" style={{ width: "100px" }}>
														表示開始日
													</th>
													<th className="text-center" style={{ width: "100px" }}>
														表示終了日
													</th>
													<th className="text-center" style={{ width: "110px" }}>
														タスク締切日
													</th>
												</tr>
											</thead>
											<tbody>
												{ tableList }

												{ provided.placeholder }

											</tbody>
										</Table>

									</div>
								);
							}
						}
					</Droppable>
				</DragDropContext>

				{/* データ無しメッセージ */}
				<div style={{ textAlign: "center", display: messageDisplay }}>
					該当のデータはありません。
				</div>

				<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
					<Button onClick={ updateTopicOrder } disabled={ updateDisabled }>更新</Button>
				</div>

			</Container>

		</>
	);
}

export default TopicSort;
