import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import { createEtc3001CynageLinkList, updateEtc3001CynageLinkList, deleteEtc3001CynageLinkList } from "../graphql/mutations";
import { getEtc3001CynageLinkList, listEtc3001CynageLinkLists } from "../graphql/queries";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import * as common from "./common";
import * as database from "./database";

import TopNav from "./TopNav";

import {
	Breadcrumb,
	Button,
	Col,
	Container,
	Form,
	Modal,
	Row,
	Table
} from "react-bootstrap";


// Global Variable =============================================================

// リンクリストデータリスト(配列)
let linkList = [];

// チェックボックスリスト(連想配列)
// @note: 想定される格納データは …
// 	チェックされた場合、true が入り、されていない場合、false が入る
// 	例) { "IDハッシュ":true, "IDハッシュ":false, "IDハッシュ":true, "IDハッシュ":false, "IDハッシュ":false, ... }
let checkBoxList = {};


// Component ===================================================================

function LinkList ( props )
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "LinkList > LinkList () props : " + JSON.stringify( props ) );
	//console.log( "LinkList > LinkList () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "LinkList > LinkList () location : " + JSON.stringify( location ) );
	//console.log( "LinkList > LinkList () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ updateDisabled, setUpdateDisabled ] = useState( true );
	const [ deleteDisabled, setDeleteDisabled ] = useState( true );
	const [ tableList, setTableList ] = useState( null );
	const [ messageDisplay, setMessageDisplay ] = useState( "none" );
	const [ showInfoModal, setShowInfoModal ] = useState( false );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [リンクリスト(一覧)]";

		( async function ()
		{
			// 組織データの取得に成功した場合
			if ( props.userData.domain )
			{
				// リンクリストデータリスト(配列)取得(非同期)
				linkList = await common.getLinkListArray( listEtc3001CynageLinkLists, props.userData.domain.id );

				//console.log( "LinkList > useEffect () linkList : " + JSON.stringify( linkList ) );
				//console.dir( linkList );

				// リンクリストデータの取得に成功した場合
				if ( linkList )
				{
					// リンクリストデータリストが空の場合
					if ( linkList.length <= 0 )
					{
						// データ無しメッセージ表示
						setMessageDisplay( "block" )
					}
					// リンクリストデータリストが空でない場合
					else
					{
						// リストの更新
						setTableList( buildTableList( linkList ) );

						// データ無しメッセージ非表示
						setMessageDisplay( "none" )
					}
				}
				// リンクリストデータの取得に失敗した場合
				else
				{
					console.error( "LinkList > useEffect () link list get list error." );
				}
			}
			// 組織データの取得に失敗した場合
			else
			{
				alert( "not belong cognito:group error. : " + JSON.stringify( props.userData.user.attributes.email ) );
			}

			// チェックボックスリスト初期化
			checkBoxList = {};
		} )();
	}, [] );

	// リンク削除処理
	const deleteLinkList = async function ( event )
	{
		//console.log( "LinkList > deleteLinkList () checkBoxList : " + JSON.stringify( checkBoxList ) );

		// 念の為、確認を入れて
		const confirm = window.confirm( "選択されたリンクを削除しますか？" );
		// 削除する場合
		if ( confirm )
		{
			// 削除ボタンの無効化
			setDeleteDisabled( true );

			for ( const key in checkBoxList )
			{
				// チェックされている場合
				if ( checkBoxList[ key ] )
				{
					//console.log( "LinkList > deleteLinkList () key : " + JSON.stringify( key ) );

					// リンクリストデータ削除(非同期)
					const input = {
						id: key
					};
					const condition = {
						domainID: { eq: props.userData.domain.id }
					};
					const response = await database.mutationDelete( deleteEtc3001CynageLinkList, input, condition );

					//console.log( "LinkList > deleteLinkList () response : " + JSON.stringify( response ) );

					// リンクリストデータの削除に成功した場合
					if ( response )
					{
						;
					}
					// リンクリストデータの削除に失敗した場合
					else
					{
						console.error( "LinkList > deleteLinkList () link delete error. id : " + JSON.stringify( key ) );
						break;
					}
				}
			}

			// ページ遷移
			history.push( "/component/LinkListDeleteComplete" );
		}
	};

	// リンクリスト追加処理
	const addLinkList = function ( event )
	{
		// クエリパラメータ作成
		const param = {
			pathname: "/component/LinkListAdd",
			//search: "",
			//hash: "",
			state: {
				name: "",
				link: "",
				icon: ""
			}
		};
		// 遷移
		history.push( param );
	};

	// リンクリスト更新処理
	const updateLinkList = async function ( event )
	{
		// 念の為、確認を入れて
		const confirm = window.confirm( "リンクリストの表示順序を更新しますか？" );
		// 並べ替える場合
		if ( confirm )
		{
			// 更新ボタンの無効化
			setUpdateDisabled( true );

			let success = true;

			// リンクリスト表示順(order)の再付番
			for ( let index = 0; index < linkList.length; index++ )
			{
				linkList[ index ].order = index + 1;

				// リンクリストデータ更新
				const input = {
					id: linkList[ index ].id,
					order: linkList[ index ].order,
					domainID: linkList[ index ].domainID
				};
				const response = await database.mutationUpdate( updateEtc3001CynageLinkList, input );

				//console.log( "LinkList > updateLinkList () response : " + JSON.stringify( response ) );

				// リンクリストデータの更新に成功した場合
				if ( response )
				{
					;
				}
				// リンクリストデータの更新に失敗した場合
				else
				{
					success = false;
					console.error( "LinkList > updateLinkList () link list update error." );
					break;
				}
			}

			//console.log( "LinkList > updateLinkList () linkList : " + JSON.stringify( linkList ) );

			// 全リンクリストデータの更新に成功した場合
			if ( success )
			{
				// 遷移
				history.push( "/component/LinkListSortComplete" );
			}
		}
	};

	// リンクリスト選択処理
	const selectLinkList = function ( event )
	{
		//console.dir( event );
		//console.log( "LinkList > selectLinkList () event.target.value : " + JSON.stringify( event.target.value ) );
		//console.log( "LinkList > selectLinkList () event.target.checked : " + JSON.stringify( event.target.checked ) );

		// 変更が有ったチェックボックスの状態を確保
		checkBoxList[ event.target.value ] = event.target.checked;

		//console.log( "LinkList > selectLinkList () checkBoxList : " + JSON.stringify( checkBoxList ) );

		// 選択されている行数を確認
		let count = 0;
		for ( const key in checkBoxList )		// 連想配列は iterable でないので for...in で
		{
			// チェックされている場合、カウント
			if ( checkBoxList[ key ] ) count++;
		}

		//console.log( "LinkList > selectLinkList () checkBoxList : count : " + count );

		// 削除ボタンの有効/無効化
		setDeleteDisabled( count === 0 );
	};

	// リンクリスト編集へ遷移
	const handleLink = function ( event, data )
	{
		// ページ遷移(リロード)動作をキャンセル
		event.preventDefault();

		//console.log( "LinkList > handleLink () data : " + JSON.stringify( data ) );

		// クエリパラメータ作成
		const param = {
			//pathname: "/component/LinkListEdit",		// これでもOKだが
			pathname: "/component/LinkListEdit/" + data.id,		// これなら<a>タグlinkとvisitedの色違いの表現が可能(ルーティングのパスパラメータは無しのまま)
			//search: "",
			//hash: "",
			state: {
				id: data.id,
				order: data.order,
				name: data.name,
				link: data.link,
				icon: data.icon
			}
		};
		// 遷移
		history.push( param );
	};

	// リスト生成処理
	const buildTableList = function ( list )
	{
		const result = list.map( function ( value, index )
		{
			//console.log( "LinkList > buildTableList () list[ " + index + " ] : " + JSON.stringify( value ) );

			return (

				<Draggable
					key={ index }
					draggableId={ "draggable1-" + index }
					index={ index }
				>
					{
						function ( provided )
						{
							return (

								<tr
									ref={ provided.innerRef }
									{ ...provided.draggableProps }
									{ ...provided.dragHandleProps }
								>

								{/* チェックボックス */}
								<td className="text-center" style={{ width: "40px" }}>
									<Form.Check
										type="checkbox"
										className="d-flex justify-content-center"
										defaultValue={ value.id }
										onChange={ ( event ) => selectLinkList( event ) }
									/>
								</td>
								{/* No. */}
								<td style={{ width: "100px" }}>
									{ value.order }
								</td>
								{/* アイコン */}
								<td style={{ width: "100px" }}>
									{
										( value.icon ) ?
											<img src={ value.icon } alt="" width="24" height="24" /> :
											<span>無し</span>
									}
								</td>
								{/* リンク先 */}
								<td>
									<Link to={ "/component/LinkListEdit/" + value.id } onClick={ ( event ) => handleLink( event, value ) }>
										{ value.name }
									</Link>
								</td>

								</tr>

							);
						}
					}
				</Draggable>

			);
		} );

		//console.dir( result );

		return result;
	};

	// react-beautiful-dnd のドラッグ開始時処理
	const handleDragStart = function ( event )
	{
		//console.log( "LinkList > handleDragStart () event : " + JSON.stringify( event ) );
	};

	// react-beautiful-dnd のドラッグ中リスト更新処理
	const handleDragUpdate = function ( event )
	{
		//console.log( "LinkList > handleDragUpdate () event : " + JSON.stringify( event ) );
	};

	// react-beautiful-dnd のドロップ時処理 (react-beautiful-dnd 必須処理)
	const handleDragEnd = function ( event )
	{
		//console.log( "LinkList > handleDragEnd () event : " + JSON.stringify( event ) );
		//console.log( "LinkList > handleDragEnd () event.source (ドラッグ元) : " + JSON.stringify( event.source ) );
		//console.log( "LinkList > handleDragEnd () event.destination (ドロップ先) : " + JSON.stringify( event.destination ) );

		// ドロップ先が存在する場合
		if ( event.destination )
		{
			// 同一リスト内のドロップの場合
			if ( event.source.droppableId === event.destination.droppableId )
			{
				// ドラッグ元位置とドロップ先位置が違う場合
				if ( event.source.index !== event.destination.index )
				{
					// react-beautiful-dnd ドロップ時の同一リスト内アイテム並べ替え
					linkList = common.reactReautifulDnD_reOrder( linkList, event.source.index, event.destination.index );

					// リストの更新
					setTableList( buildTableList( linkList ) );
				}
			}
		}

		// 更新ボタンの有効化
		setUpdateDisabled( false );
	};

	// 情報モーダルを閉じるイベントハンドラ
	const handleCloseInfoModal = () => setShowInfoModal( false );

	// 情報モーダルを表示するイベントハンドラ
	const handleShowInfoModal = () => setShowInfoModal( true );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/LinkList" ) }>リンクリスト</Breadcrumb.Item>
					<Breadcrumb.Item active>一覧</Breadcrumb.Item>
				</Breadcrumb>

				{/* インフォメーションボタン */}
				<button onClick={ handleShowInfoModal } className="no_border_button info_link text-primary">
					<span className="material-icons-outlined info_icon">info</span>
				</button>

				{/* インフォメーションダイアログ */}
				<Modal show={ showInfoModal } onHide={ handleCloseInfoModal }>
					<Modal.Header className="text-white bg-primary">
						<Modal.Title>
							<span className="material-icons-outlined modal_info_icon">info</span>
							TIPS
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span>
							このページでは、リンクリストの一覧が表示されます。<br />
							<span className="text-danger">リスト内のリンクをドラッグ＆ドロップして更新ボタンをクリック</span>すると並べ替えができます。
						</span>

						<div className="modal_action_btn_group">
							<Button variant="secondary" onClick={ handleCloseInfoModal }>閉じる</Button>
						</div>
					</Modal.Body>
				</Modal>

				<TopNav userData={ props.userData } />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<Row className="mt-3">
					<Col className="mb-2 d-flex justify-content-end align-items-end action_btn_group">
						<Button onClick={ addLinkList }>新規作成</Button>
						<Button onClick={ deleteLinkList } disabled={ deleteDisabled }>削除</Button>
					</Col>
				</Row>

				<DragDropContext
					onDragStart={ handleDragStart }
					onDragUpdate={ handleDragUpdate }
					onDragEnd={ handleDragEnd }
				>
					<Droppable
						droppableId="droppable1"
						isDropDisabled={ false }
					>
						{
							function ( provided )
							{
								return (
									<div
										ref={ provided.innerRef }
										{ ...provided.droppableProps }
									>

										<Table striped bordered hover>
											<thead>
												<tr>
													<th style={{ width: "40px" }}>
														{/* チェックボックス */}
													</th>
													<th className="text-center" style={{ width: "100px" }}>
														No.
													</th>
													<th className="text-center" style={{ width: "100px" }}>
														アイコン
													</th>
													<th className="text-center">
														リンク先
													</th>
												</tr>
											</thead>
											<tbody>
												{ tableList }

												{ provided.placeholder }

											</tbody>
										</Table>

									</div>
								);
							}
						}
					</Droppable>
				</DragDropContext>

				{/* データ無しメッセージ */}
				<div style={{ textAlign: "center", display: messageDisplay }}>
					該当のデータはありません。
				</div>

				<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
					<Button onClick={ updateLinkList } disabled={ updateDisabled }>更新</Button>
				</div>

			</Container>

		</>
	);
}

export default LinkList;
