import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import Amplify, { Auth, API, graphqlOperation } from "aws-amplify";
import awsconfig from "../aws-exports";
import {
	AmplifyAuthenticator,
	AmplifySignIn,
	AmplifyForgotPassword,
	AmplifySignUp,
//	AmplifySignOut,
//	withAuthenticator
} from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

import { getEtc3001CynageDomain, listEtc3001CynageDomains } from "../graphql/queries";
import { getEtc3001CynageMember, listEtc3001CynageMembers } from "../graphql/queries";

import * as common from "./common";
import Frame from "./Frame";
import Error from "./Error";


// Style Sheet =================================================================

import "./css/bootstrap.css";
import "./css/BootstrapCustom.css";
import "./css/common.css";


// Global Variable =============================================================

// ユーザーデータ
let userData = {};

// メンバーデータ
let memberData = {};


// Initialization ==============================================================

// ログイン状態を保持
Amplify.configure( awsconfig );


// Component ===================================================================

function Login ()
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "Login > Login () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "Login > Login () location : " + JSON.stringify( location ) );
	//console.log( "Login > Login () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ authState, setAuthState ] = useState();
	const [ user, setUser ] = useState();
	const [ permission, setPermission ] = useState( null );
	const [ isOnLeave, setIsOnLeave ] = useState( false );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		//( async function ()
		//{
		//	;
		//} )();

		// 認証処理
		// @cite: https://amplify-sns.workshop.aws/ja/30_mock/10_auth.html
		return onAuthUIStateChange( async function ( nextAuthState, authData )
		{
			//console.log( "Login > useEffect > onAuthUIStateChange () nextAuthState : " + JSON.stringify( nextAuthState ) );
			//console.log( "Login > useEffect > onAuthUIStateChange () authData : " + JSON.stringify( authData ) );

			// サインイン(ログイン)済みの場合
			if ( ( nextAuthState === AuthState.SignedIn ) && ( authData ) )
			{
				// ユーザーデータまたはメンバーデータが未だ未取得の場合
				if ( ( common.isEmpty( userData ) ) || ( common.isEmpty( memberData ) )
					// メンバーIDが違う場合
					|| ( authData.attributes.sub !== memberData.id ) )
				{
					// ユーザーデータ取得(非同期)
					userData = await common.getUserData( getEtc3001CynageDomain );

					//console.log( "Login > useEffect > onAuthUIStateChange () userData : " + JSON.stringify( userData ) );

					// ユーザーデータの取得に成功した場合
					if ( ( userData ) && ( userData.domain ) && ( userData.user ) )
					{
						// メンバーデータ取得
						memberData = await common.getMember( getEtc3001CynageMember, userData.user.attributes.sub, userData.domain.id );

						//console.log( "Login > useEffect > onAuthUIStateChange () memberData : " + JSON.stringify( memberData ) );

						// メンバーデータの取得に成功した場合
						if ( memberData )
						{
							// 利用権限の確保
							if ( memberData.permission )
							{
								setPermission( memberData.permission );
							}

							// 休職フラグの確保
							if ( memberData.isOnLeave )
							{
								setIsOnLeave( memberData.isOnLeave === "true" );
							}
						}
						// メンバーデータの取得に失敗した場合
						else
						{
							console.error( "Login > useEffect > onAuthUIStateChange () member data get error. member id(sub) : " + JSON.stringify( userData.user.attributes.sub ) );
						}
					}
					// ユーザーデータの取得に失敗した場合
					else
					{
						// ログアウトさせておく
						await common.signOut( history );

						alert( "not belong cognito:group error." );
					}
				}
			}
			// 未サインイン(未ログイン)の場合
			else if ( nextAuthState === AuthState.SignIn )
			{
				// ユーザーデータの初期化
				userData = {};

				// メンバーデータの初期化
				memberData = {};

				// 利用権限の初期化
				setPermission( null );
				// 休職フラグの初期化
				setIsOnLeave( false );
			}

			setAuthState( nextAuthState );
			setUser( authData );
		} );
	}, [] );

	//console.log( "Login > Login () authState : " + JSON.stringify( authState ) );
	//console.log( "Login > Login () user : " + JSON.stringify( user ) );
	//console.log( "Login > Login () userData : " + JSON.stringify( userData ) );
	//console.log( "Login > Login () memberData : " + JSON.stringify( memberData ) );
	//console.log( "Login > Login () permission : " + JSON.stringify( permission ) );
	//console.log( "Login > Login () isOnLeave : " + JSON.stringify( isOnLeave ) );

	// サインイン(ログイン)済みの場合
	if ( ( authState === AuthState.SignedIn ) && ( user ) )
	{
		// 一般ユーザーの場合
		if ( permission === "N" )
		{
			return ( <Error message="このページにアクセスする権限がありません。" /> );
		}
		// 編集者ユーザーまたは管理者ユーザーの場合
		else if ( ( permission === "D" ) || ( permission === "A" ) )
		{
			// 休職中の場合
			if ( isOnLeave )
			{
				return ( <Error message="このページにアクセスする権限がありません。" /> );
			}

			// フレーム画面を表示
			return ( <Frame userData={ userData } memberData={ memberData } /> );
		}

		// ユーザーデータおよびメンバーデータ取得中 (その他) の場合
		// ※ 外部データ取得は遅い為、画面表示タイミングの方が早く来てしまう
		// ※ useLayoutEffect() でも待ちきれない
		// ※ 若干白画面の表示時間が長めになるが仕方がない
		return null;
	}
	// 未サインイン(未ログイン)の場合
	else
	{
		// サインインやサインアップ画面を表示
		return (
			<AmplifyAuthenticator>

				{/* サインイン */}
				<AmplifySignIn
					slot="sign-in"
					hideSignUp={ true }		// サインアップへのリンクを非表示
					formFields={ [
						{ type: "email", label: "メールアドレス", placeholder: "メールアドレスを入力", required: true },
						{ type: "password", label: "パスワード", placeholder: "パスワードを入力", required: true }
					] }
					headerText="ログイン"
					submitButtonText="ログイン"
				/>

				{/* パスワード再設定(忘れリセット) */}
				<AmplifyForgotPassword
					slot="forgot-password"
					usernameAlias="email"		// これが無いと Username の入力となってしまう
					headerText="リセットパスワード"
					sendButtonText="コード送信"
					submitButtonText="送信"
				/>

				{/* サインアップ (テスト時以外使われることは無い) */}
				<AmplifySignUp
					slot="sign-up"
					formFields={ [
						{ type: "email" },
						{ type: "password" }
					] }
				/>

			</AmplifyAuthenticator>
		);
	}
}

export default Login;
