import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import { createEtc3001CynageTicker, updateEtc3001CynageTicker, deleteEtc3001CynageTicker } from "../graphql/mutations";
import { getEtc3001CynageTicker, listEtc3001CynageTickers } from "../graphql/queries";
import { createEtc3001CynageTopicOrder, updateEtc3001CynageTopicOrder, deleteEtc3001CynageTopicOrder } from "../graphql/mutations";
import { getEtc3001CynageTopicOrder, listEtc3001CynageTopicOrders } from "../graphql/queries";

import * as common from "./common";
import * as database from "./database";

import TopNav from "./TopNav";

import {
	Breadcrumb,
	Button,
	Col,
	Container,
	Form,
	Modal,
	Row,
	Table
} from "react-bootstrap";


// Global Variable =============================================================

// ティッカーデータ取得
let tickerData = {};

// チェックボックスリスト(配列)
// @note: 想定される格納データは…
// 	チェックされた場合、true が入り、されていない場合、false が入る
// 	例) [ true, false, true, false, false, ... ]
let checkBoxList = [];


// Component ===================================================================

function TickerPageList ( props )
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "TickerPageList > TickerPageList () props : " + JSON.stringify( props ) );
	//console.log( "TickerPageList > TickerPageList () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "TickerPageList > TickerPageList () location : " + JSON.stringify( location ) );
	//console.log( "TickerPageList > TickerPageList () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ number, setNumber ] = useState();
	const [ name, setName ] = useState();
	const [ deleteDisabled, setDeleteDisabled ] = useState( true );
	const [ tableList, setTableList ] = useState( null );
	const [ showInfoModal, setShowInfoModal ] = useState( false );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [トピックスページ(一覧)]";

		( async function ()
		{
			// ティッカーデータ取得
			const variables = {
				id: params.id,
				domainID: props.userData.domain.id
			};
			tickerData = await database.queryGet( getEtc3001CynageTicker, variables );

			//console.log( "TickerPageList > useEffect () tickerData : " + JSON.stringify( tickerData ) );

			// ティッカーデータの取得に成功した場合
			if ( tickerData )
			{
				// チェックボックスリスト初期化
				checkBoxList = Array( tickerData.mainContents.length ).fill( false );

				// ティッカー番号の更新
				setNumber( tickerData.number );
				// ティッカー名の更新
				setName( tickerData.name );

				// リストの更新
				setTableList( buildTableList( tickerData.mainContents ) );
			}
			// ティッカーデータの取得に失敗した場合
			else
			{
				console.error( "TickerPageList > useEffect () ticker data get error. id : " + JSON.stringify( params.id ) );
			}
		} )();
	}, [] );

	// ティッカーページ削除処理
	const deleteTickerPage = async function ( event )
	{
		//console.log( "TickerPageList > deleteTickerPage () checkBoxList : " + JSON.stringify( checkBoxList ) );

		// 選択されている行数を確認
		let count = 0;
		for ( const value of checkBoxList )
		{
			// チェックされている場合、カウント
			if ( value ) count++;
		}

		//console.log( "TickerPageList > deleteTickerPage () count : " + JSON.stringify( count ) );

		// 全ティッカーページが選択されている場合
		if ( tickerData.mainContents.length <= count )
		{
			// 念の為、確認を入れて
			const confirm = window.confirm( "トピックス内の全てのページが選択されております。\nこのトピックスを削除しますか？" );
			// 削除する場合
			if ( confirm )
			{
				// 削除ボタンの無効化
				setDeleteDisabled( true );

				// トピックス順データ取得
				const variables = {
					domainID: props.userData.domain.id
				};
				const topicOrder = await database.queryGet( getEtc3001CynageTopicOrder, variables );

				//console.log( "TickerPageList > deleteTickerPage () topicOrder : " + JSON.stringify( topicOrder ) );
				//console.dir( topicOrder );

				// トピックス順データの取得に成功した場合
				if ( ( topicOrder ) && ( topicOrder.topicOrder ) )
				{
					// トピックス順データ(配列)から削除対象のトピックス ID を抜く
					topicOrder.topicOrder = topicOrder.topicOrder.filter( function ( value )
					{
						return ( value !== params.id );
					} );

					//console.log( "TickerPageList > deleteTickerPage () topicOrder.topicOrder : " + JSON.stringify( topicOrder.topicOrder ) );

					// トピックス順データ更新
					const input = {
						domainID: props.userData.domain.id,
						topicOrder: topicOrder.topicOrder
					};
					const response = await database.mutationUpdate( updateEtc3001CynageTopicOrder, input );
					// トピックス順データの更新に失敗した場合
					if ( !response )
					{
						console.error( "TickerPageList > deleteTickerPage () topic order data update error. domain.id : " + JSON.stringify( props.userData.domain.id ) );
					}
				}

				// 削除対象のティッカーデータの削除フラグ(isDeleted)を立てる
				// ティッカーデータ更新
				const input = {
					id: params.id,
					domainID: props.userData.domain.id,
					isDeleted: true
				};
				const response = await database.mutationUpdate( updateEtc3001CynageTicker, input );
				// ティッカーデータの更新に成功した場合
				if ( response )
				{
					// 遷移
					history.push( "/component/TickerDeleteComplete" );
				}
				// ティッカーデータの更新に失敗した場合
				else
				{
					console.error( "TickerPageList > deleteTickerPage () ticker data update error. domain.id : " + JSON.stringify( props.userData.domain.id ) );
				}
			}
		}
		// ティッカーページが選択されている場合
		else
		{
			// 念の為、確認を入れて
			const confirm = window.confirm( "選択されたトピックスページを削除しますか？" );
			// 削除する場合
			if ( confirm )
			{
				// 削除ボタンの無効化
				setDeleteDisabled( true );

				// 配列の要素を削除する為、後ろからループ
				for ( let index = checkBoxList.length - 1; 0 <= index; index-- )
				{
					// チェックされている場合
					if ( checkBoxList[ index ] )
					{
						// 配列の要素の削除
						tickerData.mainContents.splice( index, 1 );
						tickerData.subContents.splice( index, 1 );
					}
				}

				// ティッカーデータ更新
				const input = {
					id: params.id,
					mainContents: tickerData.mainContents,
					subContents: tickerData.subContents,
					domainID: props.userData.domain.id
				};
				const response = await database.mutationUpdate( updateEtc3001CynageTicker, input );

				//console.log( "TickerPageList > deleteTickerPage () response : " + JSON.stringify( response ) );

				// ティッカーデータの更新に成功した場合
				if ( response )
				{
					// ページ遷移
					history.push( "/component/TickerPageDeleteComplete/" + params.id );
				}
				// ティッカーデータの更新に失敗した場合
				else
				{
					console.error( "TickerPageList > deleteTickerPage () ticker page delete error. id : " + JSON.stringify( params.id ) );
				}
			}
		}
	};

	// ティッカーページ追加へ遷移
	const addTickerPage = function ( event )
	{
		// クエリパラメータ作成
		const param = {
			pathname: "/component/TickerPageAdd",
			//search: "",
			//hash: "",
			state: {
				id: params.id,
				number: tickerData.number,
				page: tickerData.mainContents.length,
				name: tickerData.name,
				mainContents: tickerData.mainContents,
				subContents: tickerData.subContents,
				domainID: props.userData.domain.id,
				displayStartDate: tickerData.displayStartDate,
				displayEndDate: tickerData.displayEndDate,
				limitDate: tickerData.limitDate
			}
		};
		// 遷移
		history.push( param );
	};

	// ティッカーページ選択処理
	const selectTickerPage = function ( event )
	{
		//console.dir( event );
		//console.log( "TickerPageList > selectTickerPage () event.target.value : " + JSON.stringify( event.target.value ) );
		//console.log( "TickerPageList > selectTickerPage () event.target.checked : " + JSON.stringify( event.target.checked ) );

		// 変更が有ったチェックボックスの状態を確保
		checkBoxList[ event.target.value ] = event.target.checked;

		//console.log( "TickerPageList > selectTickerPage () checkBoxList : " + JSON.stringify( checkBoxList ) );

		// 選択されている行数を確認
		let count = 0;
		for ( const value of checkBoxList )
		{
			// チェックされている場合、カウント
			if ( value ) count++;
		}

		//console.log( "TickerPageList > selectTickerPage () checkBoxList : count : " + count );

		// 削除ボタンの有効/無効化
		setDeleteDisabled( count === 0 );
	};

	// ティッカーページ編集へ遷移
	const handleLink = function ( event, index )
	{
		// ページ遷移(リロード)動作をキャンセル
		event.preventDefault();

		// クエリパラメータ作成
		const param = {
			//pathname: "/component/TickerPageEdit",		// これでもOKだが
			pathname: "/component/TickerPageEdit/" + params.id + "/" + index,		// これなら<a>タグlinkとvisitedの色違いの表現が可能(ルーティングのパスパラメータは無しのまま)
			//search: "",
			//hash: "",
			state: {
				id: params.id,
				number: tickerData.number,
				page: index,
				name: tickerData.name,
				mainContents: tickerData.mainContents,
				subContents: tickerData.subContents,
				domainID: props.userData.domain.id,
				displayStartDate: tickerData.displayStartDate,
				displayEndDate: tickerData.displayEndDate,
				limitDate: tickerData.limitDate,
				originalStartDate: tickerData.displayStartDate,
				originalEndDate: tickerData.displayEndDate
			}
		};
		// 遷移
		history.push( param );
	};

	// リスト生成処理
	const buildTableList = function ( list )
	{
		const result = list.map( function ( value, index )
		{
			//console.log( "TickerPageList > buildTableList () list[ " + index + " ] : " + JSON.stringify( value ) );

			return (
				<tr
					key={ index }
					className="table_tr"
				>

					{/* チェックボックス */}
					<td className="text-center">
						<Form.Check
							type="checkbox"
							className="d-flex justify-content-center"
							defaultValue={ index }
							onChange={ ( event ) => selectTickerPage( event ) }
						/>
					</td>
					{/* No. */}
					<td>
						{ index + 1 }
					</td>
					{/* トピックス名 */}
					<td>
						<a href={ "/component/TickerPageEdit/" + params.id + "/" + index } onClick={ ( event ) => handleLink( event, index ) }>{ value }</a>
					</td>

				</tr>
			);
		} );

		//console.dir( result );

		return result;
	};

	// 情報モーダルを閉じるイベントハンドラ
	const handleCloseInfoModal = () => setShowInfoModal( false );

	// 情報モーダルを表示するイベントハンドラ
	const handleShowInfoModal = () => setShowInfoModal( true );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/TickerList" ) }>トピックス</Breadcrumb.Item>
					<Breadcrumb.Item onClick={ () => history.push( "/component/TickerPageList/" + params.id ) }>トピックスページ</Breadcrumb.Item>
					<Breadcrumb.Item active>編集</Breadcrumb.Item>
				</Breadcrumb>

				{/* インフォメーションボタン */}
				<button onClick={ handleShowInfoModal } className="no_border_button info_link text-primary">
					<span className="material-icons-outlined info_icon">info</span>
				</button>

				{/* インフォメーションダイアログ */}
				<Modal show={ showInfoModal } onHide={ handleCloseInfoModal }>
					<Modal.Header className="text-white bg-primary">
						<Modal.Title>
							<span className="material-icons-outlined modal_info_icon">info</span>
							TIPS
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span>
							このページでは、トピックスの編集ができます。<br />
							<span className="text-danger">トピックス内容をクリック</span>すると該当のトピックスページを編集することができます。<br />
							<span className="text-danger">「新規追加」ボタン</span>から新規のトピックスページを追加することができます。
						</span>

						<div className="modal_action_btn_group">
							<Button variant="secondary" onClick={ handleCloseInfoModal }>閉じる</Button>
						</div>
					</Modal.Body>
				</Modal>

				<TopNav />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<h4 className="mb-4">
					トピックス編集
				</h4>

				<Row className="mt-3">
					<Col className="mb-1 d-flex align-items-end">
						(1) 編集するトピックス内容を選択してください。
					</Col>
					<Col className="mb-2 d-flex justify-content-end align-items-end action_btn_group">
						<Button onClick={ addTickerPage }>新規追加</Button>
						<Button onClick={ deleteTickerPage } disabled={ deleteDisabled }>削除</Button>
					</Col>
				</Row>

				<Table striped bordered hover>
					<thead>
						<tr>
							<th style={{ width: "40px" }}>
								{/* チェックボックス */}
							</th>
							<th className="text-center" style={{ width: "100px" }}>
								No.
							</th>
							<th className="text-center">
								トピックス内容
							</th>
						</tr>
					</thead>
					<tbody>
						{ tableList }
					</tbody>
				</Table>

				<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
					<Button variant="secondary" onClick={ () => history.push( "/component/TickerList" ) }>トピックス一覧</Button>
				</div>

			</Container>
		</>
	);
}

export default TickerPageList;
