import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import { createEtc3001CynageTicker, updateEtc3001CynageTicker, deleteEtc3001CynageTicker } from "../graphql/mutations";
import { getEtc3001CynageTicker, listEtc3001CynageTickers } from "../graphql/queries";
import { createEtc3001CynageTopicOrder, updateEtc3001CynageTopicOrder, deleteEtc3001CynageTopicOrder } from "../graphql/mutations";
import { getEtc3001CynageTopicOrder, listEtc3001CynageTopicOrders } from "../graphql/queries";
import { createEtc3001CynageSequence, updateEtc3001CynageSequence, deleteEtc3001CynageSequence } from "../graphql/mutations";
import { updateExEtc3001CynageSequence } from "../graphql/mutations";
import { getEtc3001CynageSequence, listEtc3001CynageSequences } from "../graphql/queries";

import * as common from "./common";
import * as database from "./database";

import TopNav from "./TopNav";

import {
	Breadcrumb,
	Button,
	Col,
	Container,
	Form,
	InputGroup,
	Modal,
	Row,
	Table
} from "react-bootstrap";


// Style Sheet =================================================================

import "./css/common.css";


// Global Variable =============================================================

// ティッカーデータリスト(配列)
let tickerList = [];

// チェックボックスリスト(連想配列)
// @note: 想定される格納データは…
// 	チェックされた場合、true が入り、されていない場合、false が入る
// 	例) { "IDハッシュ":true, "IDハッシュ":false, "IDハッシュ":true, "IDハッシュ":false, "IDハッシュ":false, ... }
let checkBoxList = {};


// Component ===================================================================

function TickerList ( props )
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "TickerList > TickerList () props : " + JSON.stringify( props ) );
	//console.log( "TickerList > TickerList () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "TickerList > TickerList () location : " + JSON.stringify( location ) );
	//console.log( "TickerList > TickerList () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const inputTextSearch = useRef();
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ textRow, setTextRow ] = useState( 0 );
	const [ deleteDisabled, setDeleteDisabled ] = useState( true );
	const [ tableList, setTableList ] = useState( null );
	const [ showInfoModal, setShowInfoModal ] = useState( false );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [トピックス(一覧)]";

		( async function ()
		{
			// 組織データの取得に成功した場合
			if ( props.userData.domain )
			{
				// ティッカーデータリスト(配列)取得(非同期)
				tickerList = await common.getTickerArray( listEtc3001CynageTickers, props.userData.domain.id );

				//console.log( "TickerList > useEffect () tickerList : " + JSON.stringify( tickerList ) );
				//console.dir( tickerList );

				// ティッカーデータの取得に成功した場合
				if ( tickerList )
				{
					// 検索ヒット件数の更新
					setTextRow( tickerList.length );

					// リストの更新
					setTableList( buildTableList( tickerList ) );
				}
				// ティッカーデータの取得に失敗した場合
				else
				{
					console.error( "TickerList > useEffect () ticker get list error." );
				}
			}
			// 組織データの取得に失敗した場合
			else
			{
				alert( "not belong cognito:group error. : " + JSON.stringify( props.userData.user.attributes.email ) );
			}

			// チェックボックスリスト初期化
			checkBoxList = {};
		} )();
	}, [] );

	// 検索フォーム消去処理
	const clearTextSearch = function ()
	{
		inputTextSearch.current.value = "";
	};

	// ティッカー検索処理
	// ※ React のフックを利用する為、コンポーネント内に配置
	const searchTicker = function ( event )
	{
		//console.log( "TickerList > searchTicker () inputTextSearch : " + JSON.stringify( inputTextSearch.current.value ) );

		// 配列要素絞り込み
		const list = tickerList.filter( function ( value )
		{
			// (通常の)文字列内検索
			return ( 0 <= value.name.indexOf( inputTextSearch.current.value ) );
		} );

		//console.log( "TickerList > searchTicker () list : " + JSON.stringify( list ) );

		// 検索ヒット件数の更新
		setTextRow( list.length );

		// リストの更新
		setTableList( buildTableList( list ) );
	};

	// ティッカー削除処理
	const deleteTicker = async function ( event )
	{
		//console.log( "TickerList > deleteTicker () checkBoxList : " + JSON.stringify( checkBoxList ) );

		// 念の為、確認を入れて
		const confirm = window.confirm( "選択されたトピックスを削除しますか？" );
		// 削除する場合
		if ( confirm )
		{
			// 削除ボタンの無効化
			setDeleteDisabled( true );

			// トピックス順データ取得
			const variables = {
				domainID: props.userData.domain.id
			};
			const topicOrder = await database.queryGet( getEtc3001CynageTopicOrder, variables );

			//console.log( "TickerList > deleteTicker () topicOrder : " + JSON.stringify( topicOrder ) );
			//console.dir( topicOrder );

			let success = true;

			// トピックス順データの取得に成功した場合
			if ( ( topicOrder ) && ( topicOrder.topicOrder ) )
			{
				// トピックス順データ(配列)から削除対象のトピックス ID を抜く
				for ( const key in checkBoxList )
				{
					// チェックされている場合
					if ( checkBoxList[ key ] )
					{
						//console.log( "TickerList > deleteTicker () key : " + JSON.stringify( key ) );

						topicOrder.topicOrder = topicOrder.topicOrder.filter( function ( value )
						{
							return ( value !== key );
						} );
					}
				}

				//console.log( "TickerList > deleteTicker () topicOrder.topicOrder : " + JSON.stringify( topicOrder.topicOrder ) );

				// トピックス順データ更新
				const input = {
					domainID: props.userData.domain.id,
					topicOrder: topicOrder.topicOrder
				};
				const response = await database.mutationUpdate( updateEtc3001CynageTopicOrder, input );
				// トピックス順データの更新に失敗した場合
				if ( !response )
				{
					console.error( "TickerList > deleteTicker () topic order data update error. domain.id : " + JSON.stringify( props.userData.domain.id ) );
				}
			}

			// 削除対象のティッカーデータの削除フラグ(isDeleted)を立てる
			for ( const key in checkBoxList )
			{
				// チェックされている場合
				if ( checkBoxList[ key ] )
				{
					//console.log( "TickerList > deleteTicker () key : " + JSON.stringify( key ) );

					// ティッカーデータ更新
					const input = {
						id: key,
						domainID: props.userData.domain.id,
						isDeleted: true
					};
					const response = await database.mutationUpdate( updateEtc3001CynageTicker, input );
					// ティッカーデータの更新に失敗した場合
					if ( !response )
					{
						success = false;
						console.error( "TickerList > deleteTicker () ticker data update error. domain.id : " + JSON.stringify( props.userData.domain.id ) );
						break;
					}
				}
			}

			// 全ティッカーデータの更新に成功した場合
			if ( success )
			{
				// 遷移
				history.push( "/component/TickerDeleteComplete" );
			}
		}
	};

	// ティッカーデータコピー処理
	const copyTicker = async function ( event, data )
	{
		//console.log( "TickerList > copyTicker () data : " + JSON.stringify( data ) );

		// 念の為、確認を入れて
		const confirm = window.confirm( "トピックスデータ『" + data.number + " : " + data.name + "』をコピーしますか？" );
		// コピーする場合
		if ( confirm )
		{
			// シーケンスデータ数値取得(非同期)
			const count = await common.getSequenceValue(
				getEtc3001CynageSequence,
				createEtc3001CynageSequence,
				updateExEtc3001CynageSequence,
				props.userData.domain.id,
				"Ticker",
				"number",
				1
			);

			//console.log( "TickerList > copyTicker () count : " + JSON.stringify( count ) );

			// シーケンスデータ数値の取得に成功した場合
			if ( 0 <= count )
			{
				// ティッカーデータ生成
				const input = {
					number: count,
					name: "コピー " + data.name,
					mainContents: data.mainContents,
					subContents: data.subContents,
					domainID: props.userData.domain.id
				};
				const response = await database.mutationCreate( createEtc3001CynageTicker, input );

				//console.log( "TickerList > copyTicker () response : " + JSON.stringify( response ) );

				// ティッカーデータの生成に成功した場合
				if ( response )
				{
					// ティッカーデータリスト(配列)取得(非同期)
					tickerList = await common.getTickerArray( listEtc3001CynageTickers, props.userData.domain.id );

					//console.log( "TickerList > useEffect () tickerList : " + JSON.stringify( tickerList ) );
					//console.dir( tickerList );

					// ティッカーデータの取得に成功した場合
					if ( tickerList )
					{
						// 検索ヒット件数の更新
						setTextRow( tickerList.length );

						// リストの更新
						setTableList( buildTableList( tickerList ) );

						alert( "トピックスデータをコピーしました。" );
					}
					// ティッカーデータの取得に失敗した場合
					else
					{
						console.error( "TickerList > useEffect () ticker get list error." );
					}
				}
				// ティッカーデータの生成に失敗した場合
				else
				{
					console.error( "TickerList > copyTicker () ticker data create error." );
				}
			}
			// シーケンスデータ数値の取得に失敗した場合
			else
			{
				console.error( "TickerList > copyTicker () sequence value (Ticker.number) get error." );
			}
		}
	};

	// ティッカー新規作成処理
	const addTicker = function ( event )
	{
		// クエリパラメータ作成
		const param = {
			pathname: "/component/TickerAdd",
			//search: "",
			//hash: "",
			state: {
				page: 0,
				name: "",
				mainContents: [],
				subContents: [],
				domainID: props.userData.domain.id,
				displayStartDate: common.getJSTDateTime( null, "YYYY-MM-DD" ),
				displayEndDate: common.getJSTDateTime( null, "YYYY-MM-DD" ),
				limitDate: ""
			}
		};
		// 遷移
		history.push( param );
	};

	// ティッカー選択処理
	const selectTicker = function ( event )
	{
		//console.dir( event );
		//console.log( "TickerList > selectTicker () event.target.value : " + JSON.stringify( event.target.value ) );
		//console.log( "TickerList > selectTicker () event.target.checked : " + JSON.stringify( event.target.checked ) );

		// 変更が有ったチェックボックスの状態を確保
		checkBoxList[ event.target.value ] = event.target.checked;

		//console.log( "TickerList > selectTicker () checkBoxList : " + JSON.stringify( checkBoxList ) );

		// 選択されている行数を確認
		let count = 0;
		for ( const key in checkBoxList )		// 連想配列は iterable でないので for...in で
		{
			// チェックされている場合、カウント
			if ( checkBoxList[ key ] ) count++;
		}

		//console.log( "TickerList > selectTicker () checkBoxList : count : " + count );

		// 削除ボタンの有効/無効化
		setDeleteDisabled( count === 0 );
	};

	// リスト生成処理
	const buildTableList = function ( list )
	{
		const result = list.map( function ( value, index )
		{
			//console.log( "TickerList > buildTableList () list[ " + index + " ] : " + JSON.stringify( value ) );

			return (
				<tr
					key={ index }
				>

					{/* チェックボックス */}
					<td className="text-center">
						<Form.Check
							type="checkbox"
							className="d-flex justify-content-center"
							defaultValue={ value.id }
							onChange={ ( event ) => selectTicker( event ) }
						/>
					</td>
					{/* No. */}
					<td>
						{ value.number }<br />
						{ ( common.calcDiffDay( value.createdAt ) < 8.0 ) ? <img src="/new.png" alt="" /> : null }
					</td>
					{/* トピックス名 */}
					<td>
						<Link to={ "/component/TickerPageList/" + value.id }>
							{ value.name }
						</Link>
					</td>
					{/* 表示開始日 */}
					<td className="text-center">
						{ common.getReplaceDate( value.displayStartDate, "-", "YYYY/MM/DD" ) }
					</td>
					{/* 表示終了日 */}
					<td className="text-center">
						{ common.getReplaceDate( value.displayEndDate, "-", "YYYY/MM/DD" ) }
					</td>
					{/* タスク締切日 */}
					<td className="text-center">
						{ common.getReplaceDate( value.limitDate, "-", "YYYY/MM/DD" ) || "-" }
					</td>
					{/* コピーボタン */}
					<td className="text-center">
						<Button onClick={ async ( event ) => await copyTicker( event, value ) }>コピー</Button>
					</td>

				</tr>
			);
		} );

		//console.dir( result );

		return result;
	};

	// 情報モーダルを閉じるイベントハンドラ
	const handleCloseInfoModal = () => setShowInfoModal( false );

	// 情報モーダルを表示するイベントハンドラ
	const handleShowInfoModal = () => setShowInfoModal( true );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/TickerList" ) }>トピックス</Breadcrumb.Item>
					<Breadcrumb.Item active>一覧</Breadcrumb.Item>
				</Breadcrumb>

				{/* インフォメーションボタン */}
				<button onClick={ handleShowInfoModal } className="no_border_button info_link text-primary">
					<span className="material-icons-outlined info_icon">info</span>
				</button>

				{/* インフォメーションダイアログ */}
				<Modal show={ showInfoModal } onHide={ handleCloseInfoModal }>
					<Modal.Header className="text-white bg-primary">
						<Modal.Title>
							<span className="material-icons-outlined modal_info_icon">info</span>
							TIPS
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span>
							このページでは、トピックスの一覧が表示されます。<br />
							<span className="text-danger">トピックス名をクリック</span>すると該当のトピックスを編集することができます。<br />
							<span className="text-danger">「新規作成」ボタン</span>から新規のトピックスを作成することができます。
						</span>

						<div className="modal_action_btn_group">
							<Button variant="secondary" onClick={ handleCloseInfoModal }>閉じる</Button>
						</div>
					</Modal.Body>
				</Modal>

				<TopNav userData={ props.userData } />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<Row>
					{ /* 検索フォーム */ }
					<Col xs style={{ maxWidth: "500px" }}>
						<InputGroup>
							<Form.Control
								className="search_text"
								type="text"
								placeholder="トピックス名を入力"
								ref={ inputTextSearch }
								onKeyPress={ ( event ) => common.checkKey( event, "Enter", searchTicker ) }
							/>
							<span className="material-icons search_clear_button" onClick={ clearTextSearch }>close</span>
						</InputGroup>
					</Col>
					<Col xs="auto">
						<Button onClick={ searchTicker }>検索</Button>
					</Col>
				</Row>

				<Row className="mt-3">
					<Col className="mb-1 d-flex align-items-end">
						{ textRow } 件見つかりました。
					</Col>
					<Col className="mb-2 d-flex justify-content-end align-items-end action_btn_group">
						<Button onClick={ addTicker }>新規作成</Button>
						<Button onClick={ deleteTicker } disabled={ deleteDisabled }>削除</Button>
					</Col>
				</Row>

				<Table striped bordered hover>
					<thead>
						<tr>
							<th style={{ width: "40px" }}>
								{/* チェックボックス */}
							</th>
							<th className="text-center" style={{ width: "80px" }}>
								No.
							</th>
							<th className="text-center">
								トピックス名
							</th>
							<th className="text-center" style={{ width: "100px" }}>
								表示開始日
							</th>
							<th className="text-center" style={{ width: "100px" }}>
								表示終了日
							</th>
							<th className="text-center" style={{ width: "110px" }}>
								タスク締切日
							</th>
							<th style={{ width: "110px" }}>
								{/* コピーボタン列 */}
							</th>
						</tr>
					</thead>
					<tbody>
						{ tableList }
					</tbody>
				</Table>

			</Container>

		</>
	);
}

export default TickerList;
