import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
//import React, { useRef, useState, useEffect, createRef } from "react";

import * as common from "./common";

import {
	Nav
} from "react-bootstrap";


// Style Sheet =================================================================

import "./css/LeftMenu.css";


// Component ===================================================================

function LeftMenu ( props )
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "LeftMenu > LeftMenu () props : " + JSON.stringify( props ) );
	//console.log( "LeftMenu > LeftMenu () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "LeftMenu > LeftMenu () location : " + JSON.stringify( location ) );
	//console.log( "LeftMenu > LeftMenu () params : " + JSON.stringify( params ) );

	// サブドメイン取得
	const subDomain = common.getSubDomain();

	// ナビゲーションメニューのアクティブ判定
	const isActive = ( pattern, isExactMatch = false ) =>
	{
		if ( isExactMatch )
		{
			// 完全一致
			return location.pathname === pattern;
		}
		else
		{
			// 前方一致
			return location.pathname.indexOf( pattern ) === 0;
		}
	};

	// 各ページ遷移
	const handleLink = ( event, pathname ) =>
	{
		// ページ遷移(リロード)動作をキャンセル
		event.preventDefault();

		//console.log( "LeftMenu > handleLink () pathname : " + pathname );

		// 遷移
		history.push( pathname );
	};

	return (
		<div className="left_menu">

			<div className="left_header">
				<figure
					className="logo_figure"
					style={{ backgroundImage: "url(/logo_cynage.png)" }}
					onClick={ () => history.push( "/" ) }
				></figure>
			</div>

			<Nav className="flex-column main_nav" variant="pills">

				<Nav.Link
					active={
						isActive( "/", true )
						|| isActive( "/component/MemberCertification" )
						|| isActive( "/component/Information" )
					}
					href="/"
					onClick={ ( event ) => handleLink( event, "/" ) }
				>
					<span className="material-icons">home</span>
					TOP
				</Nav.Link>

				<Nav.Link
					active={
						isActive( "/component/Ticker" )
					}
					href="/component/TickerList"
					onClick={ ( event ) => handleLink( event, "/component/TickerList" ) }
				>
					<span className="material-icons">title</span>
					トピックス
				</Nav.Link>

				<Nav.Link
					active={
						isActive( "/component/TopicSort" )
					}
					href="/component/TopicSort"
					onClick={ ( event ) => handleLink( event, "/component/TopicSort" ) }
				>
					<span className="material-icons">format_list_numbered</span>
					表示順編集
				</Nav.Link>

				<Nav.Link
					active={
						isActive( "/component/Schedule" )
					}
					href="/component/Schedule"
					onClick={ ( event ) => handleLink( event, "/component/Schedule" ) }
				>
					<span className="material-icons">calendar_today</span>
					スケジュール
				</Nav.Link>

				<Nav.Link
					active={
						isActive( "/component/Link" )
					}
					href="/component/LinkList"
					onClick={ ( event ) => handleLink( event, "/component/LinkList" ) }
				>
					<span className="material-icons">bookmark_border</span>
					リンクリスト
				</Nav.Link>

				{ ( props.memberData.permission === "A" )
					?
						<Nav.Link
							active={
								isActive( "/component/MemberList" )
								|| isActive( "/component/MemberAbsence" )
								|| isActive( "/component/MemberDelete" )
								|| isActive( "/component/MemberEdit" )
							}
							href="/component/MemberList"
							onClick={ ( event ) => handleLink( event, "/component/MemberList" ) }
						>
							<span className="material-icons">people</span>
							メンバー
						</Nav.Link>
					:
						null
				}

				{ ( subDomain === "prod" || subDomain === "nprod" || subDomain === "admin" )
					?
						null
					:
						(
							<Nav.Link
								active={
									isActive( "/component/DebugTool" )
								}
								href="/component/DebugTool"
								onClick={ ( event ) => handleLink( event, "/component/DebugTool" ) }
							>
								<span className="material-icons">bug_report</span>
								デバッグツール
							</Nav.Link>
						)
				}

			</Nav>

		</div>
	);
}

export default LeftMenu;
