import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
	Link,
	useRouteMatch,
	useParams,
	useLocation,
//	useHistory
} from "react-router-dom";
import React, {
//	useRef,
//	useState,
//	useEffect,
//	createRef
} from "react";

// v1 テーブル
//import { createEtc3001CynageDomain, updateEtc3001CynageDomain, deleteEtc3001CynageDomain } from "../graphql/mutations";
//import { getEtc3001CynageDomain, listEtc3001CynageDomains } from "../graphql/queries";
//import { createEtc3001CynageTicker, updateEtc3001CynageTicker, deleteEtc3001CynageTicker } from "../graphql/mutations";
//import { getEtc3001CynageTicker, listEtc3001CynageTickers } from "../graphql/queries";
//import { createEtc3001CynageLinkList, updateEtc3001CynageLinkList, deleteEtc3001CynageLinkList } from "../graphql/mutations";
//import { getEtc3001CynageLinkList, listEtc3001CynageLinkLists } from "../graphql/queries";
// v1 削除テーブル
//import { createEtc3001CynageSchedule, updateEtc3001CynageSchedule, deleteEtc3001CynageSchedule } from "../graphql/mutations";
//import { getEtc3001CynageSchedule, listEtc3001CynageSchedules } from "../graphql/queries";
//import { createEtc3001CynageSignage, updateEtc3001CynageSignage, deleteEtc3001CynageSignage } from "../graphql/mutations";
//import { getEtc3001CynageSignage, listEtc3001CynageSignages } from "../graphql/queries";
//import { createEtc3001CynageSignageTicker, updateEtc3001CynageSignageTicker, deleteEtc3001CynageSignageTicker } from "../graphql/mutations";
//import { getEtc3001CynageSignageTicker, listEtc3001CynageSignageTickers } from "../graphql/queries";
// v2 追加テーブル
//import { createEtc3001CynageMember, updateEtc3001CynageMember, deleteEtc3001CynageMember } from "../graphql/mutations";
//import { getEtc3001CynageMember, listEtc3001CynageMembers } from "../graphql/queries";
//import { createEtc3001CynageMemberTransferHistory, updateEtc3001CynageMemberTransferHistory, deleteEtc3001CynageMemberTransferHistory } from "../graphql/mutations";
//import { getEtc3001CynageMemberTransferHistory, listEtc3001CynageMemberTransferHistorys } from "../graphql/queries";
//import { createEtc3001CynageInvite, updateEtc3001CynageInvite, deleteEtc3001CynageInvite } from "../graphql/mutations";
//import { getEtc3001CynageInvite, listEtc3001CynageInvites } from "../graphql/queries";
//import { getEtc3001CynageInformation, listEtc3001CynageInformations } from "../graphql/queries";
//import { createEtc3001CynageTopicOrder, updateEtc3001CynageTopicOrder, deleteEtc3001CynageTopicOrder } from "../graphql/mutations";
//import { getEtc3001CynageTopicOrder, listEtc3001CynageTopicOrders } from "../graphql/queries";
//import { createEtc3001CynageSequence, updateEtc3001CynageSequence, deleteEtc3001CynageSequence } from "../graphql/mutations";
//import { updateExEtc3001CynageSequence } from "../graphql/mutations";
//import { getEtc3001CynageSequence, listEtc3001CynageSequences } from "../graphql/queries";


// Component ===================================================================

function DebugTool ()
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	//const history = useHistory();

	console.log( "DebugTool > DebugTool () routeMatch : " + JSON.stringify( routeMatch ) );
	console.log( "DebugTool > DebugTool () location : " + JSON.stringify( location ) );
	console.log( "DebugTool > DebugTool () params : " + JSON.stringify( params ) );

	return (
		<div>

			<h2>
				デバッグツール
			</h2>

			<div>
				【デバッグ(ページ直リンク)】
			</div>
			<ul>
				<li>リンクリスト系</li>
				<ul>
					<li><Link to="/component/LinkListAddComplete">新規作成(完了)</Link></li>
					<li><Link to="/component/LinkListEditComplete">編集(完了)</Link></li>
					<li><Link to="/component/LinkListDeleteComplete">削除(完了)</Link></li>
					<li><Link to="/component/LinkListSortComplete">並び替え(完了)</Link></li>
				</ul>
				<li>トピックス系</li>
				<ul>
					<li><Link to="/component/TickerAddComplete">新規作成(完了)</Link></li>
					<li><Link to="/component/TickerDeleteComplete">削除(完了)</Link></li>
					<li><Link to="/component/TickerPageAddComplete/debug">ページ新規追加(完了)</Link></li>
					<li><Link to="/component/TickerPageDeleteComplete/debug">ページ削除(完了)</Link></li>
					<li><Link to="/component/TickerPageEditComplete/debug">ページ編集(完了)</Link></li>
					<li><Link to="/component/TopicSortComplete">表示順編集(完了)</Link></li>
				</ul>
				<li>メンバー系</li>
				<ul>
					<li><Link to="/component/MemberEditComplete">編集(完了)</Link></li>
					<li><Link to="/component/MemberDeleteComplete">削除(完了)</Link></li>
					<li><Link to="/component/MemberAbsenceComplete">休職処理(完了)</Link></li>
					<li><Link to="/component/MemberInviteComplete">招待(完了)</Link></li>
					<li><Link to="/component/MemberCertificationComplete">招待認証(完了)</Link></li>
				</ul>
			</ul>

		</div>
	);
}

/* 送信リンク処理テンプレート
	<form onSubmit={ handleForm }>
		<input type="submit" value="送信" />
		<input type="button" value="送信" onClick={ handleForm } />
	</form>
	<button onClick={ handleForm }>送信</button>
	<a href="" onClick={ handleLink }>リンク</a>
	<a href="" onClick={ ( event ) => handleLink( event, arg ) }>リンク</a>
*/

export default DebugTool;
