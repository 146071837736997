import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import { getEtc3001CynageInvite, listEtc3001CynageInvites } from "../graphql/queries";
import { getEtc3001CynageMember, listEtc3001CynageMembers } from "../graphql/queries";

import * as common from "./common";

import TopNav from "./TopNav";

import {
	Alert,
	Breadcrumb,
	Button,
	Col,
	Container,
	Form,
	Modal,
	Row,
	Table
} from "react-bootstrap";


// Style Sheet =================================================================

import "./css/common.css";


// Global Variable =============================================================

// 招待キューデータリスト(配列)
let inviteList = [];

// メンバーデータリスト(配列)
let memberList = [];


// Component ===================================================================

function MemberCertificationList ( props )
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "MemberCertificationList > MemberCertificationList () props : " + JSON.stringify( props ) );
	//console.log( "MemberCertificationList > MemberCertificationList () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "MemberCertificationList > MemberCertificationList () location : " + JSON.stringify( location ) );
	//console.log( "MemberCertificationList > MemberCertificationList () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const inputRadioCertification = [
		useRef( [] ),		// 承認
		useRef( [] ),		// 否認
		useRef( [] )		// 保留
	];
	const inputRadioPermission = [
		useRef( [] ),		// 一般
		useRef( [] ),		// 編集者
		useRef( [] )		// 管理者
	];
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ confirmDisabled, setConfirmDisabled ] = useState( true );
	const [ tableList, setTableList ] = useState( null );
	const [ messageDisplay, setMessageDisplay ] = useState( "none" );
	const [ showInfoModal, setShowInfoModal ] = useState( false );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [メンバー招待認証(一覧)]";

		( async function ()
		{
			// 組織データの取得に成功した場合
			if ( props.userData.domain )
			{
				// 招待キューデータリスト(配列)取得(非同期)
				// 「保留」状態の招待キューデータのみ取得
				// 認証状態:
				// 	承認:
				// 		isApproved = true;
				// 		isProcessed = false;
				// 	否認:
				// 		isApproved = false;
				// 		isProcessed = true;
				// 	保留:
				// 		isApproved = false;
				// 		isProcessed = false;
				inviteList = await common.getInviteArray(
					listEtc3001CynageInvites,
					props.userData.domain.id,
					false,		// isApproved
					false		// isProcessed
				);

				//console.log( "MemberCertificationList > useEffect () inviteList : " + JSON.stringify( inviteList ) );
				//console.dir( inviteList );

				// 招待キューデータリストが空の場合
				if ( inviteList.length <= 0 )
				{
					// 確認ボタンの無効化
					setConfirmDisabled( true );
					// データ無しメッセージ表示
					setMessageDisplay( "block" )
				}
				// 招待キューデータリストが空でない場合
				else
				{
					// 招待キューデータの取得に成功した場合
					if ( inviteList )
					{
						// メンバーデータリスト(配列)取得(非同期)
						memberList = await common.getMemberArray( listEtc3001CynageMembers, props.userData.domain.id );

						//console.log( "MemberCertificationList > useEffect () memberList : " + JSON.stringify( memberList ) );
						//console.dir( memberList );

						// メンバーデータの取得に成功した場合
						if ( memberList )
						{
							// リストの更新
							setTableList( buildTableList( inviteList ) );

							// 確認ボタンの有効化
							setConfirmDisabled( false );
							// データ無しメッセージ非表示
							setMessageDisplay( "none" )
						}
						// メンバーデータの取得に失敗した場合
						else
						{
							console.error( "MemberCertificationList > useEffect () member get list error." );
						}
					}
					// 招待キューデータの取得に失敗した場合
					else
					{
						console.error( "MemberCertificationList > useEffect () invite get list error." );
					}
				}
			}
			// 組織データの取得に失敗した場合
			else
			{
				alert( "not belong cognito:group error. : " + JSON.stringify( props.userData.user.attributes.email ) );
			}
		} )();
	}, [] );

	// 送信処理
	const handleForm = function ( event )
	{
		//console.dir( event );

		// ページ遷移(リロード)動作をキャンセル
		event.preventDefault();

		for ( let index = 0; index < inviteList.length; index++ )
		{
			// 認証(承認／否認／保留)データ変更
			// 承認
			if ( inputRadioCertification[ 0 ].current[ index ].current.checked )
			{
				inviteList[ index ].isApproved = true;
				inviteList[ index ].isProcessed = false;
			}
			// 否認
			else if ( inputRadioCertification[ 1 ].current[ index ].current.checked )
			{
				inviteList[ index ].isApproved = false;
				inviteList[ index ].isProcessed = true;
			}
			// 保留
			else
			{
				inviteList[ index ].isApproved = false;
				inviteList[ index ].isProcessed = false;
			}

			// 利用権限データ変更
			// 一般
			if ( inputRadioPermission[ 0 ].current[ index ].current.checked )
			{
				inviteList[ index ].permission = inputRadioPermission[ 0 ].current[ index ].current.value;
			}
			// 編集者
			else if ( inputRadioPermission[ 1 ].current[ index ].current.checked )
			{
				inviteList[ index ].permission = inputRadioPermission[ 1 ].current[ index ].current.value;
			}
			// 管理者
			else
			{
				inviteList[ index ].permission = inputRadioPermission[ 2 ].current[ index ].current.value;
			}

			//console.log( "MemberCertificationList > handleForm () " + index + " : " + inviteList[ index ].mailAddress + " : " + inviteList[ index ].isApproved + " : " + inviteList[ index ].isProcessed + " : " + inviteList[ index ].permission );
		}

		//console.log( "MemberCertificationList > handleForm () inviteList : " + JSON.stringify( inviteList ) );

		// クエリパラメータ作成
		const param = {
			pathname: "/component/MemberCertificationConfirm",
			//search: "",
			//hash: "",
			state: {
				inviteList: inviteList
			}
		};
		// 遷移
		history.push( param );
	};

	// リスト生成処理
	const buildTableList = function ( list )
	{
		// 更新不要な DOM のフックの初期化
		inputRadioCertification[ 0 ].current = [];
		inputRadioCertification[ 1 ].current = [];
		inputRadioCertification[ 2 ].current = [];
		inputRadioPermission[ 0 ].current = [];
		inputRadioPermission[ 1 ].current = [];
		inputRadioPermission[ 2 ].current = [];

		// 表組み行生成
		const result = list.map( function ( value1, index )
		{
			//console.log( "MemberCertificationList > buildTableList () list[ " + index + " ] : " + JSON.stringify( value1 ) );

			// 更新不要な DOM のフックを作成
			inputRadioCertification[ 0 ].current[ index ] = createRef();		// 承認
			inputRadioCertification[ 1 ].current[ index ] = createRef();		// 否認
			inputRadioCertification[ 2 ].current[ index ] = createRef();		// 保留
			inputRadioPermission[ 0 ].current[ index ] = createRef();		// 一般
			inputRadioPermission[ 1 ].current[ index ] = createRef();		// 編集者
			inputRadioPermission[ 2 ].current[ index ] = createRef();		// 管理者

			// 招待された日時(UTC)をJSTに変換
			const invitedDate = common.getJSTDateTime( value1.invitedDate, "YYYY/MM/DD" );

			// 招待者のメンバーデータ検索取得
			const member = memberList.find( function ( value2 )
			{
				return value1.inviter === value2.id;
			} );

			//console.log( "MemberCertificationList > buildTableList () member : " + JSON.stringify( member ) );

			// 招待者のフルネーム取得
			// 招待キューデータリストに含めてしまう
			value1.inviterName = common.getFullName(
				( member ) ? member.familyName : null,
				( member ) ? member.firstName : null
			);

			return (
				<tr
					key={ index }
				>

					{/* 招待日 */}
					<td>
						{ invitedDate }
					</td>

					{/* メールアドレス */}
					<td>
						{ value1.mailAddress }
					</td>

					{/* 認証(承認／否認／保留) */}
					<td>
						<Form.Label htmlFor={ "certification_" + index + "a" }>
							<Form.Check
								type="radio"
								name={ "certification_" + index }
								id={ "certification_" + index + "a" }
								ref={ inputRadioCertification[ 0 ].current[ index ] }
								value="approve"
								label="承認"
								inline
								defaultChecked={ ( ( ( value1.isApproved ) || ( value1.isApproved === "true" ) ) && ( ( !value1.isProcessed ) || ( value1.isProcessed === "false" ) ) ) }
							/>
						</Form.Label>
						<Form.Label htmlFor={ "certification_" + index + "r" }>
							<Form.Check
								type="radio"
								name={ "certification_" + index }
								id={ "certification_" + index + "r" }
								ref={ inputRadioCertification[ 1 ].current[ index ] }
								value="reject"
								label="否認"
								inline
								defaultChecked={ ( ( ( !value1.isApproved ) || ( value1.isApproved === "false" ) ) && ( ( value1.isProcessed ) || ( value1.isProcessed === "true" ) ) ) }
							/>
						</Form.Label>
						<Form.Label htmlFor={ "certification_" + index + "p" }>
							<Form.Check
								type="radio"
								name={ "certification_" + index }
								id={ "certification_" + index + "p" }
								ref={ inputRadioCertification[ 2 ].current[ index ] }
								value="pend"
								label="保留"
								inline
								defaultChecked={ ( ( ( !value1.isApproved ) || ( value1.isApproved === "false" ) ) && ( ( !value1.isProcessed ) || ( value1.isProcessed === "false" ) ) ) }
							/>
						</Form.Label>
					</td>

					{/* 利用権限 */}
					<td>
						<Form.Label htmlFor={ "permission_" + index + "n" }>
							<Form.Check
								type="radio"
								name={ "permission_" + index }
								id={ "permission_" + index + "n" }
								ref={ inputRadioPermission[ 0 ].current[ index ] }
								value="N"
								label="一般"
								inline
								defaultChecked={ ( ( !value1.permission ) || ( value1.permission === "N" ) ) }
							/>
						</Form.Label>
						<Form.Label htmlFor={ "permission_" + index + "d" }>
							<Form.Check
								type="radio"
								name={ "permission_" + index }
								id={ "permission_" + index + "d" }
								ref={ inputRadioPermission[ 1 ].current[ index ] }
								value="D"
								label="編集者"
								inline
								defaultChecked={ ( value1.permission === "D" ) }
							/>
						</Form.Label>
						<Form.Label htmlFor={ "permission_" + index + "a" }>
							<Form.Check
								type="radio"
								name={ "permission_" + index }
								id={ "permission_" + index + "a" }
								ref={ inputRadioPermission[ 2 ].current[ index ] }
								value="A"
								label="管理者"
								inline
								defaultChecked={ ( value1.permission === "A" ) }
							/>
						</Form.Label>
					</td>

					{/* 招待者 */}
					<td>
						{ value1.inviterName }
					</td>

				</tr>
			);
		} );

		//console.dir( result );

		return result;
	};

	// 情報モーダルを閉じるイベントハンドラ
	const handleCloseInfoModal = () => setShowInfoModal( false );

	// 情報モーダルを表示するイベントハンドラ
	const handleShowInfoModal = () => setShowInfoModal( true );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/" ) }>TOP</Breadcrumb.Item>
					<Breadcrumb.Item active>招待認証</Breadcrumb.Item>
				</Breadcrumb>

				{/* インフォメーションボタン */}
				<button onClick={ handleShowInfoModal } className="no_border_button info_link text-primary">
					<span className="material-icons-outlined info_icon">info</span>
				</button>

				{/* インフォメーションダイアログ */}
				<Modal show={ showInfoModal } onHide={ handleCloseInfoModal }>
					<Modal.Header className="text-white bg-primary">
						<Modal.Title>
							<span className="material-icons-outlined modal_info_icon">info</span>
							TIPS
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span>
							このページでは、メンバー招待の認証一覧が表示されます。
						</span>

						<div className="modal_action_btn_group">
							<Button variant="secondary" onClick={ handleCloseInfoModal }>閉じる</Button>
						</div>
					</Modal.Body>
				</Modal>

				<TopNav userData={ props.userData } />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<h4 className="mb-4">
					招待者の承認 ／ 否認 ／ 保留
				</h4>

				{/* プログレストラッカー */}
				<Alert className="progress_tracker mb-4">
					<div className="active">招待認証</div>
				</Alert>

				<Form noValidate onSubmit={ handleForm }>

					<Row className="mt-3">
						<Col lg="10" className="mb-1 d-flex align-items-end">
							招待の承認または否認をしてください。<br />
							また、必要に応じて管理者、投稿編集の権限を付与をしてください。<br />
							(管理者は投稿編集権限を持つため、管理者にチェックを入れると自動的に投稿編集にもチェックが入ります)
						</Col>
						{/* 認証確認ボタン */}
						<Col lg="2" className="mb-2 d-flex justify-content-end align-items-end action_btn_group">
							<Button type="submit" disabled={ confirmDisabled }>確認</Button>
						</Col>
					</Row>

					<Table striped bordered hover>
						<thead>
							<tr>
								<th className="text-center" style={{ width: "100px" }}>
									招待日
								</th>
								<th className="text-center">
									メールアドレス
								</th>
								<th className="text-center" style={{ width: "220px" }}>
									承認／否認／保留
								</th>
								<th className="text-center" style={{ width: "250px" }}>
									権限
								</th>
								<th className="text-center" style={{ width: "150px" }}>
									招待者
								</th>
							</tr>
						</thead>
						<tbody>
							{ tableList }
						</tbody>
					</Table>

					{/* データ無しメッセージ */}
					<div style={{ textAlign: "center", display: messageDisplay }}>
						認証待ちデータはありません。
					</div>

				</Form>

			</Container>

		</>
	);
}

export default MemberCertificationList;
