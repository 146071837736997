import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import { getEtc3001CynageTicker, listEtc3001CynageTickers } from "../graphql/queries";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import allLocales from "@fullcalendar/core/locales-all";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";

import * as common from "./common";

import TopNav from "./TopNav";

import {
	Breadcrumb,
	Button,
	Container,
	Modal
} from "react-bootstrap";


// Style Sheet =================================================================

import "./css/Schedule.css";

// Global Variable =============================================================

// カレンダーで表示する色リスト
const fullCalendarColorList = [
	"#4a9ef1",
	"#8396e5",
	"#a07aea",
	"#d772af",
	"#d9665c",
	"#d39a59",
	"#6ba26c",
];

// Component ===================================================================

function Schedule ( props )
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "Schedule > Schedule () props : " + JSON.stringify( props ) );
	//console.log( "Schedule > Schedule () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "Schedule > Schedule () location : " + JSON.stringify( location ) );
	//console.log( "Schedule > Schedule () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	//const calendar = useRef();
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ scheduleList, setScheduleList ] = useState( false );
	const [ showInfoModal, setShowInfoModal ] = useState( false );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [スケジュール]";

		( async function ()
		{
			// 組織データの取得に成功した場合
			if ( props.userData.domain )
			{
				// ティッカーデータリスト(配列)取得(非同期)
				const tickerList = await common.getTickerArray( listEtc3001CynageTickers, props.userData.domain.id );

				//console.log( "Schedule > useEffect () tickerList : " + JSON.stringify( tickerList ) );
				//console.dir( tickerList );

				// ティッカーデータの取得に成功した場合
				if ( tickerList )
				{
					// FullCalendar のスケジュール形式データ作成
					const eventDataList = tickerList.map( function ( value, index )
					{
						//console.log( "Schedule > useEffect () tickerList[ " + index + " ] : " + JSON.stringify( value ) );

						// 次の日の日付を取得
						const endNextDate = common.getShiftDateTime( new Date( value.displayEndDate ), 1, "YYYY-MM-DD" );

						//console.log( "Schedule > useEffect () endNextDate : " + JSON.stringify( endNextDate ) );

						// 設定する色を選択
						const color = fullCalendarColorList[ new Date( value.displayStartDate ).getDay() ];

						return {
							title: value.name,
							start: value.displayStartDate,
							end: endNextDate,
							url: "/component/TickerPageList/" + value.id,
							color: color
						};
					} )

					//console.log( "Schedule > useEffect () eventDataList : " + JSON.stringify( eventDataList ) );

					// スケジュールリスト(配列)更新
					setScheduleList( eventDataList );
				}
				// ティッカーデータの取得に失敗した場合
				else
				{
					console.error( "Schedule > useEffect () ticker get list error." );
				}
			}
			// 組織データの取得に失敗した場合
			else
			{
				alert( "not belong cognito:group error. : " + JSON.stringify( props.userData.user.attributes.email ) );
			}
		} )();
	}, [] );

	// 情報モーダルを閉じるイベントハンドラ
	const handleCloseInfoModal = () => setShowInfoModal( false );

	// 情報モーダルを表示するイベントハンドラ
	const handleShowInfoModal = () => setShowInfoModal( true );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/component/Schedule" ) }>スケジュール</Breadcrumb.Item>
				</Breadcrumb>

				{/* インフォメーションボタン */}
				<button onClick={ handleShowInfoModal } className="no_border_button info_link text-primary">
					<span className="material-icons-outlined info_icon">info</span>
				</button>

				{/* インフォメーションダイアログ */}
				<Modal show={ showInfoModal } onHide={ handleCloseInfoModal }>
					<Modal.Header className="text-white bg-primary">
						<Modal.Title>
							<span className="material-icons-outlined modal_info_icon">info</span>
							TIPS
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span>
							このページでは、トピックス表示期間のスケジュールを確認することができます。
						</span>

						<div className="modal_action_btn_group">
							<Button variant="secondary" onClick={ handleCloseInfoModal }>閉じる</Button>
						</div>
					</Modal.Body>
				</Modal>

				<TopNav />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container mb-4">

				<FullCalendar
					//ref={ calendar }
					plugins={[ dayGridPlugin, bootstrap5Plugin ]}
					themeSystem="bootstrap5"
					initialView="dayGridMonth"
					locales={ allLocales }		// "today" の日本語化
					locale="ja"		// 日本語化
					timeZone="Asia/Tokyo"	// タイムゾーン
					fixedWeekCount={ false }		// 週数(6週)の非固定化
					dayCellContent={ ( event ) =>
						( event.dayNumberText = event.dayNumberText.replace( "日", "" ) )		// 余計な「日」の削除
					}
					//buttonText={{
					//	today: "今月"		// 「今日」ボタンテキストを「今月」に変更
					//}}
					headerToolbar={{		// ヘッダのタイトルやボタンの配置
						left: "today",
						center: "title",
						right: "prev,next"
					}}
					contentHeight="auto"		// 縦幅 (縦スクロールバーに影響)
					footerToolbar={{		// フッタのタイトルやボタンの配置
						center: "prev,today,next"
					}}
					//dayMaxEvents={ 2 }		// 表示イベント数制限
					events={ scheduleList }
				/>

			</Container>

		</>
	);
}

export default Schedule;
