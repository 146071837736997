import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
// 	Link,
// 	useRouteMatch,
// 	useParams,
// 	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import {
//	AmplifyAuthenticator,
//	AmplifySignIn,
//	AmplifyForgotPassword,
//	AmplifySignUp,
//	AmplifySignOut,
	withAuthenticator
} from "@aws-amplify/ui-react";

import * as common from "./common";

import {
	Button,
	Container
} from "react-bootstrap";


// Style Sheet =================================================================

import "./css/Error.css";


// Component ===================================================================

function Error ( props )
{
	const history = useHistory();

	//console.log( "Error > Error () props : " + JSON.stringify( props ) );

	let message = "エラーが発生しました。";
	// メッセージ(日本語)が指定されている場合
	if ( props.message )
	{
		message = props.message;
	}

	// 文字列の JSX 出力形式変換
	message = common.stringToJSX( message );

	return (
		<>

			{/* メイン部 */}
			<Container fluid className="error_container d-flex justify-content-center align-items-center">

				<div className="text-center">

					<div className="error_logo pb-5">
						<figure
							className="error_logo_figure"
							style={{ backgroundImage: "url(/logo_cynage.png)" }}
						></figure>
					</div>

					<p className="mt-5">
						{ message }
					</p>

					<div className="mt-5">
						<Button variant="primary" onClick={ async () => await common.signOut( history ) }>ログアウト</Button>
					</div>

				</div>

			</Container>

		</>
	);
}

export default withAuthenticator( Error );
