import {
	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
//	useRouteMatch,
//	useParams,
//	useLocation,
//	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import * as common from "./component/common";
import Login from "./component/Login";
import Maintenance from "./component/Maintenance";
import * as env from "./env";


// Style Sheet =================================================================

import "./App.css";


// define const ================================================================

// 管理ツール動作モードデータ URL
const PROCESS_MODE_DATA_URL = "https://" + env.CDN_DOMAIN + "/data/admin_process_mode.json";

// スーパーユーザー許容IPアドレスリストデータ URL
const SUPERUSER_IP_LIST_DATA_URL = "https://" + env.CDN_DOMAIN + "/data/superuser_ip_list.json";

// クライアントIPアドレス取得 (Lambda + API Gateway) API URL
const CLIENT_IP_ADDRESS_API_URL = "https://sezig3duf0.execute-api.ap-northeast-1.amazonaws.com/default/0019ClientIPAddress";


// Component ===================================================================

function App ()
{
	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ mode, setMode ] = useState();
	const [ message, setMessage ] = useState( null );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		( async function ()
		{
			// 管理ツール動作モードデータ取得
			const process = await common.getExternalJSON( PROCESS_MODE_DATA_URL );

			//console.log( "App > useEffect () process : " + JSON.stringify( process ) );

			// データ取得に成功した場合
			if ( process )
			{
				// メンテナンスモードの場合
				if ( ( process.mode ) && ( process.mode === "maintenance" ) )
				{
					// ローカルストレージ (window.localStorage) のユーザーフラグの取得
					const user = localStorage.getItem( "user" );

					//console.log( "App > useEffect () user : " + JSON.stringify( user ) );

					// スーパーユーザー許容IPアドレスリストデータ取得
					const superuserIP = await common.getExternalJSON( SUPERUSER_IP_LIST_DATA_URL );

					//console.log( "App > useEffect () superuserIP : " + JSON.stringify( superuserIP ) );

					// IP アドレスの取得
					const IPAddress = await common.getExternalText( CLIENT_IP_ADDRESS_API_URL );

					//console.log( "App > useEffect () IPAddress : " + JSON.stringify( IPAddress ) );

					// 対象IPがスーパーユーザー許容IPアドレスリスト内のサブネットIPにマッチするか判定
					const isMatch = common.isIPv4Match( superuserIP.list, IPAddress );

					//console.log( "App > useEffect () isMatch : " + JSON.stringify( isMatch ) );

					// ローカルストレージのユーザーフラグが "super" の場合
					if ( ( ( user ) && ( user === "super" ) )
						// 対象IPがスーパーユーザー許容IPアドレスリスト内のサブネットIPにマッチしている場合
						|| ( isMatch ) )
					{
						// その他画面表示
						setMode( "execution" );

						console.log( "App > useEffect () スーパーユーザーモード発動中！" );
					}
					// その他のユーザーの場合
					else
					{
						// メンテナンス画面表示
						setMode( "maintenance" );
						setMessage( process.message );
					}
				}
				// 稼働モードの場合
				else
				{
					// その他画面表示
					setMode( "execution" );
				}
			}
			// データ取得に失敗した場合
			else
			{
				// メンテナンス画面表示
				setMode( "maintenance" );
			}
		} )();
	}, [] );

	//console.log( "App > App () mode : " + JSON.stringify( mode ) );
	//console.log( "App > App () message : " + JSON.stringify( message ) );

	// メンテナンスとする場合
	if ( mode === "maintenance" )
	{
		return (
			<div className="App">
				<Maintenance message={ message } />
			</div>
		);
	}
	// 稼働の場合
	else if ( mode === "execution" )
	{
		return (
			<div className="App">
				<Router>
					<Login />
				</Router>
			</div>
		);
	}

	// 管理ツール動作モードデータ取得中 (その他) の場合
	// ※ 外部データ取得は遅い為、画面表示タイミングの方が早く来てしまう
	// ※ useLayoutEffect() でも待ちきれない
	// ※ 若干白画面の表示時間が長めになるが仕方がない
	return null;
}

export default App;
