import {
//	BrowserRouter as Router,
//	Switch,
//	Route,
//	Link,
	useRouteMatch,
	useParams,
	useLocation,
	useHistory
} from "react-router-dom";
import React, { useRef, useState, useEffect, createRef } from "react";

import * as common from "./common";

import TopNav from "./TopNav";

import {
	Breadcrumb,
	Button,
	Container,
	Modal
} from "react-bootstrap";


// Component ===================================================================

function InformationDetail ()
{
	const routeMatch = useRouteMatch();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	//console.log( "InformationDetail > InformationDetail () routeMatch : " + JSON.stringify( routeMatch ) );
	//console.log( "InformationDetail > InformationDetail () location : " + JSON.stringify( location ) );
	//console.log( "InformationDetail > InformationDetail () params : " + JSON.stringify( params ) );

	// 更新不要な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	// 更新可能な DOM のフックを取得 (※ グローバルなスコープ場所では定義不可)
	const [ showInfoModal, setShowInfoModal ] = useState( false );

	// 遅延実行処理
	// ※ 非同期処理(awaitの記述不要)はここで実行すること … ここで実行しないと何度も実行されてしまう
	useEffect( function ()
	{
		// ページタイトル設定
		document.title = common.PAGE_TITLE + " [お知らせ(詳細)]";
	}, [] );

	// 事務局からのお知らせ一覧へ戻る処理
	const goBackPrevious = function ( event )
	{
		// 遷移
		history.push( "/component/InformationList" );
	};

	// 情報モーダルを閉じるイベントハンドラ
	const handleCloseInfoModal = () => setShowInfoModal( false );

	// 情報モーダルを表示するイベントハンドラ
	const handleShowInfoModal = () => setShowInfoModal( true );

	return (
		<>

			{/* ヘッダー部 */}
			<div className="right_header bg-light">

				{/* パンくずリスト */}
				<Breadcrumb className="breadcrumb_list">
					<Breadcrumb.Item onClick={ () => history.push( "/" ) }>TOP</Breadcrumb.Item>
					<Breadcrumb.Item active>お知らせ</Breadcrumb.Item>
				</Breadcrumb>

				{/* インフォメーションボタン */}
				<button onClick={ handleShowInfoModal } className="no_border_button info_link text-primary">
					<span className="material-icons-outlined info_icon">info</span>
				</button>

				{/* インフォメーションダイアログ */}
				<Modal show={ showInfoModal } onHide={ handleCloseInfoModal }>
					<Modal.Header className="text-white bg-primary">
						<Modal.Title>
							<span className="material-icons-outlined modal_info_icon">info</span>
							TIPS
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span>
							このページでは、CYnAGE 事務局からのお知らせ詳細が表示されます。
						</span>

						<div className="modal_action_btn_group">
							<Button variant="secondary" onClick={ handleCloseInfoModal }>閉じる</Button>
						</div>
					</Modal.Body>
				</Modal>

				<TopNav />

			</div>

			{/* メイン部 */}
			<Container fluid className="main_container">

				<div className="d-flex justify-content-start align-items-end">
					{/* お知らせ件名 */}
					<h4 className="mb-0">{ location.state.subject }</h4>
					{/* お知らせ日 */}
					<p className="ms-4 mb-0">{ common.getJSTDateTime( location.state.infoDate, "YYYY/MM/DD HH:mm" ) }</p>
				</div>

				{/* お知らせ本文 */}
				<p className="mt-4">
					{ common.stringToJSX( location.state.body ) }
				</p>

				{/* 戻るボタン */}
				<div className="mt-5 mb-2 d-flex justify-content-center action_btn_group">
					<Button variant="secondary" onClick={ goBackPrevious }>戻る</Button>
				</div>

			</Container>

		</>
	);
}

export default InformationDetail;
